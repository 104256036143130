import React, { useContext, useEffect, useState } from "react";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import axios from "axios";
import { VARIABLES } from "../../../Constants";
import { useLocation } from "react-router-dom";
import RejectCandidate from "../../CandidatePoolResumes/RejectCandidate";

const HRInterviewReview = ({ formId }) => {
  const { getHRInterviewDetails, isHRDetailsLoading, HRInterviewDetails } =
    useContext(InterviewQuestionContext);

  const HRData = HRInterviewDetails

  console.log("HRData: ", HRData);

  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data
  const [SubmissionData, setSubmissionData] = useState(null);

  // localstorage get
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");

  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;

  const interviewerIds = HRData?.interview_details?.interviewer_name?.map(interviewer => interviewer.id);
  const feedbackInterviewerIds = HRData?.interview_details?.interview_feedback?.map(feedback => feedback.interviewer.id);

  const shouldRenderForm = interviewerIds?.every(id => feedbackInterviewerIds?.includes(id));


  const handleFormSubmission = (data) => {
    if (data?.is_selected === "Reject") {
      setSubmissionData(data);
      document.getElementById("reject-btn").click();
    } else {
      onSubmit(data);
    }
  };

  //form submission
  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      candidate_interview_id: HRData?.interview_details?.id,
      candidate_job_id: candidate_id,
      requisition_id: requisition_id,
      candidate_status:
        data?.is_selected === "Draft"
          ? 0
          : data?.is_selected === "Accept"
            ? 1
            : data?.is_selected === "Reject"
              ? 2
              : null,
      comments: data.comments,
      updated_by_id: userID,
      workflow_session: {
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
      },
    });

    console.log(user);

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/hrinterview/${HRData?.interview_details?.id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        nextLevel();
        getWorkflowSession(candidate_id,requisition_id);
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Interview reviewed successfully",
        });
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Review failed",
        });
      });
  };


  useEffect(() => {
    var defaultValues = {};
    if (HRData?.interview_details) {
      defaultValues.comments = HRData?.interview_details.comments;
    }
    reset(defaultValues);

    setValue(
      "is_selected",
      HRData?.interview_details?.candidate_accept_reject === 0
        ? "Draft"
        : HRData?.interview_details?.candidate_accept_reject === 1
          ? "Accept"
          : HRData?.interview_details?.candidate_accept_reject === 2
            ? "Reject"
            : null
    );
  }, []);

  useEffect(() => {
    getHRInterviewDetails(candidate_id, requisition_id);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">HR Interview Review</h4>
            {isHRDetailsLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isHRDetailsLoading ? (
        <div></div>
      ) : (
        <div className="row">
          {HRData?.length !== 0 &&
            HRData?.interview_feedback?.length !== 0 ? (
            <>
              <div className="table-responsive mt-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Interviewer Name</th>
                      <th>Category</th>
                      <th>Score</th>
                      <th>Total Marks</th>
                      <th>Comments</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {HRData?.interview_details?.interview_feedback?.length > 0 ?
                    <tbody>
                      {HRData?.interview_details?.interview_feedback?.map((feedbackItem) => (
                        feedbackItem.categories_data.map((category, index) => (
                          <tr>
                            {index === 0 && (
                              <td rowSpan={feedbackItem.categories_data.length}>
                                {feedbackItem.interviewer.first_name + " " + feedbackItem.interviewer.last_name}
                              </td>
                            )}
                            <td>{category.category || '-'}</td>
                            <td>{category.score}</td>
                            {index === 0 && (
                              <>
                                <td rowSpan={feedbackItem.categories_data.length}>
                                  {feedbackItem.total_score}
                                </td>
                                <td rowSpan={feedbackItem.categories_data.length}>
                                  {feedbackItem.comments}
                                </td>
                                <td rowSpan={feedbackItem.categories_data.length}>
                                  {feedbackItem?.candidate_accept_reject === 0
                                    ? <span className="badge bg-warning">
                                      Draft
                                    </span>
                                    : feedbackItem?.candidate_accept_reject === 1
                                      ? <span className="badge bg-success">
                                        Accepted
                                      </span>
                                      : feedbackItem?.candidate_accept_reject === 2
                                        ? <span className="badge bg-danger">
                                          Rejected
                                        </span>
                                        : '-'}
                                </td>
                              </>
                            )}
                          </tr>
                        ))
                      ))}
                    </tbody>
                    :
                    <tbody>
                      <tr>
                        <td colSpan={6} className="text-danger">Interview is not completed yet</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              <div className="table-responsive mt-5">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Interviewer Name</th>
                      <th>Question</th>
                      <th >
                        <span>Score</span>
                        <br />
                        <span>(Out of 5)</span>
                      </th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  {HRData?.interview_details?.interview_feedback?.length > 0 ?
                    <tbody>
                      {HRData?.interview_details?.interview_feedback?.map((feedbackItem, index) => (
                        feedbackItem.questions_data.map((question, qIndex) => (
                          <tr key={`${index}-${qIndex}`}>
                            {qIndex === 0 && (
                              <td rowSpan={feedbackItem.questions_data.length}>
                                {`${feedbackItem.interviewer.first_name} ${feedbackItem.interviewer.last_name}`}
                              </td>
                            )}
                            <td style={{ minWidth: "500px" }}>{question.question}</td>
                            <td>{question.score}</td>
                            <td>{question.comments}</td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                    :
                    <tbody>
                      <tr>
                        <td colSpan={4} className="text-danger">Interview is not completed yet</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              {shouldRenderForm ?
                <>
                  {privileges.includes('HR Interview Update') &&
                    <form
                      onSubmit={handleSubmit(handleFormSubmission)}
                      autoComplete="off"
                    >
                      <div className="row">
                        <div className="col-lg-6 mt-4 mb-4">
                          <div className="form-group">
                            <label className="form-label">Comments</label>
                            <textarea
                              className="form-control"
                              {...register("comments")}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6"></div>

                        <div className="col-lg-6 mt-2">
                          <ul className="row">
                            {interviewActionDropdownData.map((action, index) => (
                              <li className="col-sm-4" key={index}>
                                <div className="custom-control custom-radio me-3">
                                  <input
                                    type="radio"
                                    id={`checkbox-form-is-selected-technicalreview-${action.value.toLowerCase()}`}
                                    className="custom-control-input"
                                    value={action.value}
                                    {...register("is_selected", { required: true })}
                                  />
                                  <label
                                    className="custom-control-label"
                                    for={`checkbox-form-is-selected-technicalreview-${action.value.toLowerCase()}`}
                                  >
                                    {action.name}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {errors.is_selected && (
                            <span className="fs-8 text-danger">
                              Please select any one
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12 d-flex mt-5">
                        <div className="form-group">
                          {level !== 0 && (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary me-3"
                              id="button-button-prevlevel"
                              onClick={() => prevLevel()}
                            >
                              Prev
                            </button>
                          )}
                        </div>
                        <div className="form-group">
                          {submitLoading ? (
                            <button className="btn text-dark" disabled>
                              Loading...
                            </button>
                          ) : (
                            <>
                              {sequenceList.length - 2 > level ? (
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary"
                                  id="submit-button-techinterview"
                                >
                                  Next
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary"
                                  id="submit-button-techinterview"
                                >
                                  Submit
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  }
                </>
                :
                <>
                  <span className="fs-6 text-danger fw-medium mt-5">
                    The interview process is still ongoing. Feedback from all interviewers is required before you can proceed.
                  </span>
                  <div className="col-12 d-flex mt-5">
                    <div className="form-group">
                      {level !== 0 && (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary me-3"
                          id="button-button-prevlevel"
                          onClick={() => prevLevel()}
                        >
                          Prev
                        </button>
                      )}
                    </div>
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        id="button-button-nextlevel"
                        onClick={() => nextLevel()}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </>
              }
            </>
          ) : (
            <div>No HR interview details found</div>
          )}
        </div>
      )}
      <button
        type="button"
        id="reject-btn"
        hidden
        data-bs-toggle="modal"
        data-bs-target="#rejectcandidate"
      ></button>
      <RejectCandidate
        status={"TI_HR"}
        setSubmitMessage={setSubmitMessage}
        editdata={{
          id: candidate_id,
        }}
        reqID={requisition_id}
        shortlisting_type={"RC"}
        onSubmit={onSubmit}
        SubmissionData={SubmissionData}
      />
    </>
  );
};

export default HRInterviewReview;
