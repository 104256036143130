import React, { useContext, useEffect } from "react";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import Snackbar from "../../../Base/Snackbar";
import { useLocation } from "react-router-dom";

const MettleTestUpload = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;

  // Candidate Exam / Interview data of particular candidates stores in "candidateExam"
  const { isCandidateExamLoading, getCandidateExamData } =
    useContext(MDApprovalContext);

  useEffect(() => {
    getCandidateExamData(candidate_id, requisition_id);
  }, []);


  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Psychometric Test </h4>
            {isCandidateExamLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <h6>You don't have access to this form.</h6>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default MettleTestUpload;
