import React from 'react'
import Content from './Content'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

const Base = (props) => {
  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- sidebar @s --> */}
          <Sidebar />
          {/* <!-- sidebar @e --> */}
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap ">
            {/* <!-- main header @s --> */}
            <Header logoutHandler={props.logoutHandler} />
            {/* <!-- main header @e --> */}
            {/* <!-- content @s --> */}
            <Content />
            {/* <!-- content @e --> */}
            {/* <!-- footer @s --> */}
            <Footer />
            {/* <!-- footer @e --> */}
          </div>
          {/* <!-- wrap @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
      {/* <!-- app-root @e --> */}
    </>
  )
}

export default Base