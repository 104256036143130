import axios from "axios";
import React, { useState, useContext, createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const ActivityLogsContext = createContext(null);

function ActivityLogsProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);

  // To store Candidate Resume Data for Activity Logs.
  const [isResumePerReqData, setIsResumePerReqData] = useState(false);
  const [rawCandidateResumePerReq, setRawCandidateResumePerReq] = useState([]);
  const [candidateResumePerReqEntries, setCandidateResumePerReqEntries] =
    useState(5);
  const [candidateResumePerReqCount, setCandidateResumePerReqCount] =
    useState(null);
  const [candidateResumePerReqPageCount, setCandidateResumePerReqPageCount] =
    useState(0);
  const [isResumePerReqLoading, setIsResumePerReqLoading] = useState(false);

  // To store Candidate Logs .
  const [rawCandidateTimeline, setRawCandidateTimeline] = useState([]);
  const [isCandidateTimelineLoading, setIsCandidateTimelineLoading] =
    useState(false);
  const [isCandidateTimelineData, setIsCandidateTimelineData] = useState(false);
  const [candidateTimelineEntries, setCandidateTimelineEntries] = useState(5);
  const [candidateTimelineCount, setCandidateTimelineCount] = useState(null);
  const [candidateTimelinePageCount, setCandidateTimelinePageCount] =
    useState(0);

  // To store Requisition Logs .
  const [rawRequisitionTimeline, setRawRequisitionTimeline] = useState([]);
  const [isRequisitionTimelineLoading, setIsRequisitionTimelineLoading] =
    useState(false);
  const [isRequisitionTimelineData, setIsRequisitionTimelineData] =
    useState(false);
  const [requisitionTimelineEntries, setRequisitionTimelineEntries] =
    useState(5);
  const [requisitionTimelineCount, setRequisitionTimelineCount] =
    useState(null);
  const [requisitionTimelinePageCount, setRequisitionTimelinePageCount] =
    useState(0);

  // To store Pending On You data .
  const [rawPendingOnYou, setRawPendingOnYou] = useState([]);
  const [isPendingOnYouLoading, setIsPendingOnYouLoading] = useState(false);
  const [isPendingOnYouData, setIsPendingOnYouData] = useState(false);
  const [PendingOnYouEntries, setPendingOnYouEntries] = useState(5);
  const [PendingOnYouCount, setPendingOnYouCount] = useState(null);
  const [PendingOnYouPageCount, setPendingOnYouPageCount] = useState(0);

  const [rawMenu, setRawMenu] = useState([]);
  const [isMenuLoading, setIsMenuLoading] = useState(false);

  // "getCandidateResumePerReq" API call to get resume data Activity Logs
  const getCandidateResumePerReq = async (
    page,
    reqId,
    searchResume,
    access
  ) => {
    setIsResumePerReqLoading(true);
    console.log(searchResume);
    let url;
    if (searchResume) {
      url =
        VARIABLES.url +
        `/api/candidateresume/?entries=${candidateResumePerReqEntries}&page=${page}&event=${searchResume}&requisition_id=${reqId}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateresume/?entries=${candidateResumePerReqEntries}&page=${page}&requisition_id=${reqId}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsResumePerReqLoading(false);
      if (response.length !== 0) {
        setIsResumePerReqData(false);
        setRawCandidateResumePerReq(res.data.data);
      } else {
        setRawCandidateResumePerReq(res.data.data);
        setIsResumePerReqData(true);
      }
      const total = res.data.count;
      setCandidateResumePerReqCount(total);
      setCandidateResumePerReqPageCount(
        Math.ceil(total / candidateResumePerReqEntries)
      );
    } catch (error) {
      console.log(error);
      setIsResumePerReqLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsResumePerReqData(false);
      } else {
        setIsResumePerReqData(true);
      }
    }
  };

  const handleCandidateResumePerReqClick = async (
    data,
    reqId,
    searchResume
  ) => {
    let access = secureLocalStorage.getItem("access");
    console.log(access);
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateResumePerReq(
      currentPage,
      reqId,
      searchResume,
      access
    );
  };

  const getRequisitionTimelineData = async (
    page,
    reqId,
    searchStage,
    access
  ) => {
    setIsRequisitionTimelineLoading(true);
    let url;
    if (searchStage) {
      url =
        VARIABLES.url +
        `/api/requisition-activity-log/?requisition_id=${reqId}&entries=${requisitionTimelineEntries}&page=${page}&event=${searchStage}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requisition-activity-log/?requisition_id=${reqId}&entries=${requisitionTimelineEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsRequisitionTimelineLoading(false);
      if (response.length !== 0) {
        setIsRequisitionTimelineData(false);
        setRawRequisitionTimeline(res.data.data);
      } else {
        setRawRequisitionTimeline(res.data.data);
        setIsRequisitionTimelineData(true);
      }
      const total = res.data.count;
      setRequisitionTimelineCount(total);
      setRequisitionTimelinePageCount(
        Math.ceil(total / requisitionTimelineEntries)
      );
    } catch (error) {
      console.log(error);
      setIsRequisitionTimelineLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsRequisitionTimelineData(false);
      } else {
        setIsRequisitionTimelineData(true);
      }
    }
  };

  const handleRequisitionTimelineClick = async (data, reqId, searchStage) => {
    let access = secureLocalStorage.getItem("access");
    console.log(access);
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRequisitionTimelineData(
      currentPage,
      reqId,
      searchStage,
      access
    );
  };

  const getCandidateTimelineData = async (
    page,
    ResumeID,
    searchStage,
    access
  ) => {
    setIsCandidateTimelineLoading(true);
    let url;
    if (searchStage) {
      url =
        VARIABLES.url +
        `/api/candidatestatus/?resume_master_id=${ResumeID}&entries=${candidateTimelineEntries}&page=${page}&event=${searchStage}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidatestatus/?resume_master_id=${ResumeID}&entries=${candidateTimelineEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsCandidateTimelineLoading(false);
      if (response.length !== 0) {
        setIsCandidateTimelineData(false);
        setRawCandidateTimeline(res.data.data);
      } else {
        setRawCandidateTimeline(res.data.data);
        setIsCandidateTimelineData(true);
      }
      const total = res.data.count;
      setCandidateTimelineCount(total);
      setCandidateTimelinePageCount(
        Math.ceil(total / candidateTimelineEntries)
      );
    } catch (error) {
      console.log(error);
      setIsCandidateTimelineLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateTimelineData(false);
      } else {
        setIsCandidateTimelineData(true);
      }
    }
  };

  const handleCandidateTimelineClick = async (data, ResumeID, searchStage) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateTimelineData(
      currentPage,
      ResumeID,
      searchStage
    );
  };

  // const getPendingOnYouData = async (userID, page, searchStage, access) => {
  //   setIsPendingOnYouLoading(true);
  //   let url;
  //   if (searchStage) {
  //     url =
  //       VARIABLES.url +
  //       `/api/statistics/?entries=${PendingOnYouEntries}&page=${page}&user_id=${userID}&event=${searchStage}`;
  //   } else {
  //     url =
  //       VARIABLES.url +
  //       `/api/statistics/?entries=${PendingOnYouEntries}&page=${page}&user_id=${userID}`;
  //   }
  //   console.log(url);

  //   let config = {
  //     method: 'get',
  //     maxBodyLength: Infinity,
  //     url: url,
  //     headers: {
  //       "Authorization": `Bearer ${access}`
  //     }
  //   };

  //   try {
  //     const res = await axios(config);
  //     let response = res.data.data;
  //     setIsPendingOnYouLoading(false);
  //     if (response.length !== 0) {
  //       setIsPendingOnYouData(false);
  //       setRawPendingOnYou(res.data.data);
  //     } else {
  //       setRawPendingOnYou(res.data.data);
  //       setIsPendingOnYouData(true);
  //     }
  //     const total = res.data.count;
  //     setPendingOnYouCount(total);
  //     setPendingOnYouPageCount(
  //       Math.ceil(total / requisitionTimelineEntries)
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     setIsPendingOnYouLoading(false);
  //     if (error?.response?.status === 403) {
  //       RefreshToken()
  //       setIsPendingOnYouData(false);
  //     } else {
  //       setIsPendingOnYouData(true);
  //     }
  //   }
  // };
  const getPendingOnYouData = async (userID) => {
    setIsPendingOnYouLoading(true);

    let url = VARIABLES.url + `/api/statistics/?user_id=${userID}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsPendingOnYouLoading(false);
      if (response.length !== 0) {
        setIsPendingOnYouData(false);
        setRawPendingOnYou(res.data.data);
      } else {
        setRawPendingOnYou(res.data.data);
        setIsPendingOnYouData(true);
      }
    } catch (error) {
      console.log(error);
      setIsPendingOnYouLoading(false);
    }
  };

  const handlemenu = async () => {
    setIsMenuLoading(true);

    let url = VARIABLES.url + `/api/apps/`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsMenuLoading(false);
      if (response.length !== 0) {
        setIsMenuLoading(false);
        setRawMenu(res.data.data);
      } else {
        setRawMenu(res.data.data);
        setIsMenuLoading(true);
      }
    } catch (error) {
      console.log(error);
      setIsMenuLoading(false);
    }
  };

  const handlePendingOnYouClick = async (data, userID, searchStage) => {
    let access = secureLocalStorage.getItem("access");
    console.log(access);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getPendingOnYouData(
      userID,
      currentPage,
      searchStage,
      access
    );
  };

  return (
    <div>
      <ActivityLogsContext.Provider
        value={{
          rawCandidateResumePerReq,
          getCandidateResumePerReq,
          candidateResumePerReqEntries,
          candidateResumePerReqPageCount,
          setCandidateResumePerReqEntries,
          candidateResumePerReqCount,
          handleCandidateResumePerReqClick,
          isResumePerReqData,
          isResumePerReqLoading,

          rawCandidateTimeline,
          isCandidateTimelineLoading,
          isCandidateTimelineData,
          candidateTimelineEntries,
          candidateTimelineCount,
          candidateTimelinePageCount,
          getCandidateTimelineData,
          setCandidateTimelineEntries,
          handleCandidateTimelineClick,

          rawRequisitionTimeline,
          isRequisitionTimelineData,
          isRequisitionTimelineLoading,
          requisitionTimelineEntries,
          requisitionTimelineCount,
          requisitionTimelinePageCount,
          getRequisitionTimelineData,
          setRequisitionTimelineEntries,
          handleRequisitionTimelineClick,

          rawRequisitionTimeline,
          isRequisitionTimelineData,
          isRequisitionTimelineLoading,
          requisitionTimelineEntries,
          requisitionTimelineCount,
          requisitionTimelinePageCount,
          getRequisitionTimelineData,
          setRequisitionTimelineEntries,
          handleRequisitionTimelineClick,

          rawPendingOnYou,
          isPendingOnYouData,
          isPendingOnYouLoading,
          PendingOnYouEntries,
          PendingOnYouCount,
          PendingOnYouPageCount,
          getPendingOnYouData,
          setPendingOnYouEntries,
          handlePendingOnYouClick,

          handlemenu,
          rawMenu,
          isMenuLoading,
        }}
      >
        {children}
      </ActivityLogsContext.Provider>
    </div>
  );
}

export default ActivityLogsProvider;
