import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { useLocation } from "react-router-dom";

const MettleTest = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;
  const job_opening_id = location?.state?.job_opening_id;

  console.log(formId); //data of the tab in which this form is present

  // Secure Local Storage
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const privileges = secureLocalStorage.getItem("privileges");

  // const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  // console.log(candidate_job_id);
  // const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  // Candidate Exam / Interview data of particular candidates stores in "candidateExam"
  const { candidateExam, isCandidateExamLoading, getCandidateExamData } =
    useContext(MDApprovalContext);
  console.log(candidateExam);

  //message after submitting data
  const { setSubmitMessage } = useContext(SnackbarContext);
  const [submitLoading, setSubmitLoading] = useState(false); //loading state while submitting data

  const [isEditable, setIsEditable] = useState(candidateExam?.length === 0);

  // Stepper (Prev/Next)
  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);

  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm();

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleFormSubmit = (data) => {
    if (isDirty) {
      handleSubmit(onSubmit)(data);
    } else {
      nextLevel();
    }
  };

  //Submission of Mettle Test form
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let workflow_session = {
      tab_id: formId?.id,
      workflow_id: formId?.WF,
      user_id: userID,
    };
    let user = new FormData();

    user.append("candidate_job_id", candidate_id);
    user.append("requisition_id", requisition_id);
    user.append("test_link", data.test_link);
    user.append("test_name", data.test_name);
    user.append("test_conducted_user_id", userID);
    user.append("created_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidateexam/",
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    console.log(user);
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          setSubmitLoading(false);
          getCandidateExamData(candidate_id, requisition_id);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Test assigned successful",
          });
          getWorkflowSession(candidate_id, requisition_id);
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message:
            error?.response?.data?.message || "Failed to assign mettle test",
        });
      });
  };

  //Pre-populating data
  useEffect(() => {
    const editedData = candidateExam[0];
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      defaultValues.test_name = editedData.test_name;
      defaultValues.test_link = editedData.test_link;

      reset(defaultValues);
    }
  }, [candidateExam]);

  useEffect(() => {
    getCandidateExamData(candidate_id, requisition_id);
  }, []);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Psychometric Test </h4>
            {isCandidateExamLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("rc_update_provision") && (
            <div className="nk-block-head-content pe-2">
              {candidateExam?.length !== 0 && (
                <>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={toggleEdit}
                    >
                      {isEditable ? "Cancel" : "Edit"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Test Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-test_name"
                      placeholder="Enter test name"
                      {...register("test_name", {
                        required: true,
                      })}
                      disabled={isFormFilled}
                    />
                    {errors.test_name && (
                      <span className="fs-8 text-danger">
                        Test name is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Test Link <span className="text-danger">*</span>
                    </label>
                    <input
                      type="url"
                      className="form-control"
                      id="text-forms-test_link"
                      placeholder="Enter test link"
                      {...register("test_link", {
                        required: true,
                      })}
                      disabled={isFormFilled}
                    />
                    {errors.test_link && (
                      <span className="fs-8 text-danger">
                        Test link is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          workflowId?.is_complete === 1 ? (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                              onClick={() => nextLevel()}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                            >
                              Next
                            </button>
                          )
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default MettleTest;
