import React, { useContext, useEffect } from "react";
import BackgroundVerificationReview from "./BackgroundVerificationReview";
import secureLocalStorage from "react-secure-storage";
import { BackgroundVerificationContext } from "../../../Context/BackgroundVerificationContext";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation } from "react-router-dom";

const BGV2Review = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;

  const { isAccess } = useContext(RefreshTokenContext);
  const { getBGVerificationData } = useContext(BackgroundVerificationContext);

  // const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  // console.log(candidate_job_id);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getBGVerificationData(candidate_id, "bgv_2", access);
    } else {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getBGVerificationData(candidate_id, "bgv_2", access);
    }
  }, [isAccess]);

  return (
    <>
      <BackgroundVerificationReview bgv_type={"bgv_2"} formId={formId} />
    </>
  );
};

export default BGV2Review;
