import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import { WorkflowContext } from "../../Context/WorkflowContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const WorkFlowEditModal = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  let location = useLocation();
  console.log(props);
  console.log(props.wk_name);
  console.log(props.rawFormWorkflowData);
  console.log(props.formworkflowData);

  let parentID;

  if (
    props.formworkflowData?.parent_id &&
    typeof props.formworkflowData.parent_id === "object"
  ) {
    parentID = props.formworkflowData.parent_id.id;
    console.log("Parent id :", parentID);
  } else {
    parentID = props.formworkflowData?.parent_id;
    console.log("Parent id :", parentID);
  }
  console.log("Parent id :", parentID);
  console.log("Current sequence number :", props.formworkflowData?.sequence);
  console.log("Current ID :", props.formworkflowData?.id);
  console.log("Level :", props.formworkflowData?.level);
  const level = props.formworkflowData?.level;
  const sequenceNumber = props.formworkflowData?.sequence;

  const {
    formWorkflowOptions,
    getFormWorkflowData,
    getFormWorkflowDropdownData,
  } = useContext(WorkflowContext);

  console.log(formWorkflowOptions);

  const { isGenricDropdownDataLoading, formsDropdownData } = useContext(
    GenericDropdownContext
  );

  const defaultParentIdWorkflow = [
    { value: 0, label: "No Parent Id", sequence: 0 },
  ];
  const defaultDependOnWorkflow = [
    { value: 0, label: "No Depend On", sequence: 0 },
  ];
  const combineParentIdFormWorkflow = [
    ...defaultParentIdWorkflow,
    ...formWorkflowOptions,
  ];
  const combineDependOnFormWorkflow = [
    ...defaultDependOnWorkflow,
    ...formWorkflowOptions,
  ];

  const [filteredDependOnData, setFilteredDependOnData] = useState([]);
  console.log(filteredDependOnData);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { isDirty, isValid } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    let dependOn = data.depends_on.map((api) => api.value);
    console.log(dependOn);
    console.log(data);
    var workflow = JSON.stringify({
      tab_name: data.tab_name,
      parent_id: parentID,
      sequence: sequenceNumber,
      depends_on: dependOn,
      form_id: data.form_id.value,
      workflow_id: props.wk_name.id,
      is_iterable: data.is_iterable ? true : false,
      is_subtab: 1,
      current_id: props.formworkflowData?.id,
      level: level,
      created_by_id: userID,
      updated_by_id: userID,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/formworkflow/",
      headers: {
        "Content-Type": "application/json",
      },
      data: workflow,
    };

    console.log(workflow);
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        getFormWorkflowData(1, props.wk_name.id);
        getFormWorkflowDropdownData(props.wk_name.id);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Worflow updated successfully",
        });
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to update workflow",
        });
      });
  };

  useEffect(() => {
    console.log(props.formworkflowData?.sequence);
    const filteredData = combineDependOnFormWorkflow.filter(
      (seq) => seq.sequence <= props.formworkflowData?.sequence
    );
    setFilteredDependOnData(filteredData);
  }, [props.formworkflowData?.sequence]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="workflowmodaledit"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create sub-tab of {props.formworkflowData?.tab_name}
              </h5>
              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Tab Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="text-forms-tab_name"
                        placeholder="Enter Tab Name"
                        {...register("tab_name", { required: true })}
                      />
                      {errors.tab_name && (
                        <p className="fs-8 text-danger">Tab Name is required</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Depends On <span className="text-danger">*</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            isMulti
                            options={filteredDependOnData}
                            isClearable={true}
                            id="select-forms-depends_on"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="depends_on"
                        control={control}
                      />
                      {errors.depends_on && (
                        <p className="fs-8 text-danger">
                          Depends On is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Assessment</label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={formsDropdownData?.map((info) => ({
                              value: info.id,
                              label: info.form_name,
                            }))}
                            isClearable={true}
                            isLoading={isGenricDropdownDataLoading}
                            id="select-forms-form_id"
                          />
                        )}
                        defaultValue=""
                        name="form_id"
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12"></div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <input
                      id="checkbox-forms-iterable"
                      {...register("is_iterable")}
                      type="checkbox"
                    />
                    <label className="form-label ms-2">Iterable</label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {submitLoading ? (
                  <button
                    id="button-loading"
                    className="btn text-dark"
                    disabled
                  >
                    Loading...
                  </button>
                ) : isValid === true ? (
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary"
                    id="submit-button-editsubFormWorkFlow"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary"
                    id="submit-button-editsubFormWorkFlow"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WorkFlowEditModal;
