import axios from "axios";
import React, { useState, useContext, createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const CandidateResumeContext = createContext(null);

function CandidateResumeProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);

  // To store Candidate Resume Data.
  const [isResumeData, setIsResumeData] = useState(false);
  const [rawCandidateResume, setRawCandidateResume] = useState([]);
  console.log(rawCandidateResume);
  const [candidateResumeEntries, setCandidateResumeEntries] = useState(5);
  const [candidateResumeCount, setCandidateResumeCount] = useState(null);
  const [candidateResumePageCount, setCandidateResumePageCount] = useState(0);
  const [isResumeLoading, setIsResumeLoading] = useState(false);

  // To store Pending candidate Resume Data.
  const [isPendingResumeData, setIsPendingResumeData] = useState(false);
  const [rawPendingCandidateResume, setRawPendingCandidateResume] = useState(
    []
  );
  const [pendingCandidateResumeEntries, setPendingCandidateResumeEntries] =
    useState(5);
  const [pendingCandidateResumeCount, setPendingCandidateResumeCount] =
    useState(null);
  const [pendingCandidateResumePageCount, setPendingCandidateResumePageCount] =
    useState(0);
  const [isPendingResumeLoading, setIsPendingResumeLoading] = useState(false);

  // candidate selected By RC are stored in "rawAcceptedCandidateDetails"
  const [isCandidateData, setIsCandidateData] = useState(false);
  const [rawAcceptedCandidateDetails, setRawAcceptedCandidateDetails] =
    useState([]);
  console.log(rawAcceptedCandidateDetails);
  const [candidateEntries, setCandidateEntries] = useState(5);
  const [candidateCount, setCandidateCount] = useState(null);
  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);

  // "getCandidateResume" API call to get all resume data
  const getCandidateResume = async (page, searchResume, data, access) => {
    setIsResumeLoading(true);
    console.log(searchResume);
    let url;
    if (data === "referral") {
      if (searchResume) {
        url =
          VARIABLES.url +
          `/api/candidateresume/?entries=${candidateResumeEntries}&page=${page}&event=${searchResume}&is_referral=True`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?entries=${candidateResumeEntries}&page=${page}&is_referral=True`;
      }
    } else {
      if (searchResume) {
        url =
          VARIABLES.url +
          `/api/candidateresume/?entries=${candidateResumeEntries}&page=${page}&event=${searchResume}`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?entries=${candidateResumeEntries}&page=${page}`;
      }
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsResumeLoading(false);
      if (response.length !== 0) {
        setIsResumeData(false);
        setRawCandidateResume(res.data.data);
      } else {
        setRawCandidateResume(res.data.data);
        setIsResumeData(true);
      }
      const total = res.data.count;
      setCandidateResumeCount(total);
      setCandidateResumePageCount(Math.ceil(total / candidateResumeEntries));
    } catch (error) {
      console.log(error);
      setIsResumeLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsResumeData(false);
      } else {
        setIsResumeData(true);
      }
    }
  };

  const handleCandidateResumeClick = async (data, searchResume) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateResume(
      currentPage,
      searchResume,
      access
    );
  };

  // "getPendingCandidateResume" API call to get resume data, that are not shortlisted by RC. "These candidate details goes to RC pool"
  const getPendingCandidateResume = async (
    page,
    reqId,
    searchResume,
    sortingDate,
    access
  ) => {
    setIsPendingResumeLoading(true);
    let url;
    if (searchResume) {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=not_shortlisted&entries=${pendingCandidateResumeEntries}&page=${page}&event=${searchResume}&order_by=-created_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=not_shortlisted&entries=${pendingCandidateResumeEntries}&page=${page}&event=${searchResume}&order_by=created_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=not_shortlisted&entries=${pendingCandidateResumeEntries}&page=${page}&event=${searchResume}`;
      }
    } else {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=not_shortlisted&entries=${pendingCandidateResumeEntries}&page=${page}&order_by=-created_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=not_shortlisted&entries=${pendingCandidateResumeEntries}&page=${page}&order_by=created_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=not_shortlisted&entries=${pendingCandidateResumeEntries}&page=${page}`;
      }
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsPendingResumeLoading(false);
      if (response.length !== 0) {
        setIsPendingResumeData(false);
        setRawPendingCandidateResume(res.data.data);
      } else {
        setIsPendingResumeData(true);
        setRawPendingCandidateResume(res.data.data);
      }
      const total = res.data.count;
      setPendingCandidateResumeCount(total);
      setPendingCandidateResumePageCount(
        Math.ceil(total / pendingCandidateResumeEntries)
      );
    } catch (error) {
      console.log(error);
      setIsPendingResumeLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsPendingResumeData(false);
      } else {
        setIsPendingResumeData(true);
      }
    }
  };

  const handlePendingCandidateResumeClick = async (
    data,
    reqId,
    searchResume
  ) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getPendingCandidateResume(
      currentPage,
      reqId,
      searchResume
    );
  };

  // "getAcceptedCandidateDetails" API call stores the resume that are selected by "RC".
  const getAcceptedCandidateDetails = async (
    page,
    reqId,
    searchCandidate,
    sortingDate,
    access
  ) => {
    setIsCandidateLoading(true);
    console.log(searchCandidate);
    let url;
    if (searchCandidate) {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=sme_approval&entries=${pendingCandidateResumeEntries}&page=${page}&event=${searchCandidate}&order_by=-updated_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=sme_approval&entries=${pendingCandidateResumeEntries}&page=${page}&event=${searchCandidate}&order_by=updated_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?shortlisted_type=sme_approval&requisition_id=${reqId}&entries=${candidateEntries}&page=${page}&event=${searchCandidate}`;
      }
    } else {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=sme_approval&entries=${pendingCandidateResumeEntries}&page=${page}&order_by=-updated_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=sme_approval&entries=${pendingCandidateResumeEntries}&page=${page}&order_by=updated_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?shortlisted_type=sme_approval&requisition_id=${reqId}&entries=${candidateEntries}&page=${page}`;
      }
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsCandidateLoading(false);
      if (response.length !== 0) {
        setIsCandidateData(false);
        setRawAcceptedCandidateDetails(res.data.data);
      } else {
        setIsCandidateData(true);
        setRawAcceptedCandidateDetails(res.data.data);
      }
      const total = res.data.count;
      setCandidateCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
    } catch (error) {
      console.log(error);
      setIsCandidateLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateData(false);
      } else {
        setIsCandidateData(true);
      }
    }
  };

  const handleAcceptCandidateClick = async (data, reqId, searchCandidate) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAcceptedCandidateDetails(
      currentPage,
      reqId,
      searchCandidate
    );
  };

  return (
    <div>
      <CandidateResumeContext.Provider
        value={{
          rawCandidateResume,
          getCandidateResume,
          candidateResumeEntries,
          candidateResumePageCount,
          setCandidateResumeEntries,
          candidateResumeCount,
          handleCandidateResumeClick,
          isResumeData,
          isResumeLoading,

          rawPendingCandidateResume,
          getPendingCandidateResume,
          pendingCandidateResumeEntries,
          pendingCandidateResumePageCount,
          setPendingCandidateResumeEntries,
          pendingCandidateResumeCount,
          handlePendingCandidateResumeClick,
          isPendingResumeData,
          isPendingResumeLoading,

          rawAcceptedCandidateDetails,
          getAcceptedCandidateDetails,
          candidateEntries,
          candidatePageCount,
          setCandidateEntries,
          candidateCount,
          handleAcceptCandidateClick,
          isCandidateData,
          isCandidateLoading,
        }}
      >
        {children}
      </CandidateResumeContext.Provider>
    </div>
  );
}

export default CandidateResumeProvider;
