import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { WorkflowContext } from "../../Context/WorkflowContext";
import WorkFlowCreateModal from "./WorkflowCreateModal";
import WorkFlowEditModal from "./WorkflowEditModal";

const WorkFlowDemo = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const navigate = useNavigate();
  let location = useLocation();
  const [searchWkflow, setSearchWkflow] = useState("");
  console.log(location.state.editpost);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawFormWorkflowData,
    getFormWorkflowData,
    formWorkflowEntries,
    formWorkflowPageCount,
    setFormWorkflowEntries,
    formWorkflowCount,
    handleFormWorkflowClick,
    isFormWorkflowData,
    isFormWorkflowLoading,
    getFormWorkflowDropdownData,
  } = useContext(WorkflowContext);

  console.log("rawFormWorkflowData: ", rawFormWorkflowData);

  const [formworkflowData, setFormworkflowData] = useState(null);

  const handleEntries = (event) => {
    setFormWorkflowEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchWkflow(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getFormWorkflowData(1, location.state.editpost.id, searchWkflow);
    } else {
      getFormWorkflowData(1, location.state.editpost.id);
      setSearchWkflow("");
    }
  };

  useEffect(() => {
    getFormWorkflowDropdownData(location.state.editpost.id);
  }, [location]);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getFormWorkflowData(1, location.state.editpost.id, "", access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getFormWorkflowData(1, location.state.editpost.id, "", access);
    }
  }, [location, formWorkflowEntries, isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Configure Workflow ({location.state.editpost.workflow_name})
            </h3>
            {isFormWorkflowLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("workflow_configure_first_tab_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/createfirsttab"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-createfirsttab"
                state={{ editpost: location.state.editpost }}
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/createfirsttab"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-createfirsttab"
                state={{ editpost: location.state.editpost }}
              >
                <em className="icon ni ni-plus"></em>
                <span>Create First Tab</span>
              </NavLink>

              <a
                className="ms-3 btn btn-light d-none d-sm-inline-flex"
                id="button-back"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                id="button-back"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchWkflow}
                      className="form-control"
                      placeholder="Search by tab name and form name"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Tab Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Assessment</span>
                      </div>
                      {privileges.includes("workflow_configure_tab_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit Form</span>
                        </div>
                      ) : (
                        " "
                      )}

                      {[
                        "workflow_configure_sibling_tab_create",
                        "workflow_configure_sub_tab_update",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <div className="nk-tb-col">
                          <span>Action</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawFormWorkflowData.length !== 0 ? (
                      rawFormWorkflowData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {Array(post.level).fill("- ")}
                                {post.tab_name}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post.form?.form_name || "-"}
                            </span>
                          </div>
                          {privileges.includes(
                            "workflow_configure_tab_update"
                          ) ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/workflowedit"}
                                state={{
                                  editpost: post,
                                  editpostData: location.state.editpost,
                                }}
                                className="btn btn-sm btn-primary"
                                id="button-button-edit_form_workflow"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {[
                            "workflow_configure_sibling_tab_create",
                            "workflow_configure_sub_tab_update",
                          ].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <div>
                              <button
                                className="btn btn-white btn-dim btn-outline-success me-3"
                                data-bs-toggle="modal"
                                data-bs-target="#workflowmodal"
                                id="button-modal-sibling_tab_create"
                                onClick={() => setFormworkflowData(post)}
                              >
                                <em className="icon ni ni-plus"></em>
                              </button>
                              <button
                                className="btn btn-white btn-dim btn-outline-success"
                                data-bs-toggle="modal"
                                data-bs-target="#workflowmodaledit"
                                id="button-modal-sub_tab_update"
                                onClick={() => setFormworkflowData(post)}
                              >
                                <em className="icon ni ni-grid-add-fill-c"></em>
                              </button>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isFormWorkflowData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={formWorkflowPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={formWorkflowPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleFormWorkflowClick(
                        data,
                        location.state.editpost.id,
                        searchWkflow
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {formWorkflowCount === null ? 0 : formWorkflowCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={formWorkflowEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WorkFlowCreateModal
        wk_name={location.state.editpost}
        rawFormWorkflowData={rawFormWorkflowData}
        formworkflowData={formworkflowData}
      />
      <WorkFlowEditModal
        wk_name={location.state.editpost}
        rawFormWorkflowData={rawFormWorkflowData}
        formworkflowData={formworkflowData}
      />
    </>
  );
};

export default WorkFlowDemo;
