import axios from "axios";
import React, { useState, createContext } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

export const RefreshTokenContext = createContext(null);

function RefreshTokenProvider({ children }) {
  // For APP JS to login and Logout Handling
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  console.log(isLoggedIn);

  // For Refresh Token
  const [isAccess, setIsAccess] = useState(false);
  const refresh = secureLocalStorage.getItem("refresh");
  const navigate = useNavigate();

  const RefreshToken = () => {
    console.log("Called");
    var refresh_token = JSON.stringify({
      refresh: refresh,
    });
    console.log(refresh_token);

    const url = "http://192.168.1.61:8000/api/token/refresh/";
    console.log(url);
    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: refresh_token,
    };
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const res = response.data.access;
        secureLocalStorage.setItem("access", res);
        setIsAccess(true);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Refresh Token Expires. Login Once Again",
          }).then((result) => {
            if (result.isConfirmed) {
              secureLocalStorage.removeItem("RWFcandidate_job_id");
              secureLocalStorage.removeItem("isLoggedIn", "USER_LOGGED_OUT");
              secureLocalStorage.removeItem("loggedId");
              secureLocalStorage.removeItem("userID");
              secureLocalStorage.removeItem("username");
              secureLocalStorage.removeItem("firstName");
              secureLocalStorage.removeItem("lastName");
              secureLocalStorage.removeItem("email");
              secureLocalStorage.removeItem("login_type");
              secureLocalStorage.removeItem("privileges");
              secureLocalStorage.removeItem("workflowid");
              secureLocalStorage.removeItem("jobOpeningid");
              secureLocalStorage.removeItem("role");
              secureLocalStorage.removeItem("access");
              secureLocalStorage.removeItem("refresh");

              setIsLoggedIn(false);
              navigate("/");
            }
          });
        }
      });
  };

  const value = {
    RefreshToken,
    isAccess,

    isLoggedIn,
    setIsLoggedIn,
  };
  return (
    <RefreshTokenContext.Provider value={value}>
      {children}
    </RefreshTokenContext.Provider>
  );
}

export default RefreshTokenProvider;
