import axios from "axios";
import React, { useState, useContext } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";

export const CandidateEntriesContext = createContext(null);

function CandidateEntriesProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);

  // userdetails stored in "rawUserDetails"
  const [isCandidateData, setIsCandidateData] = useState(false);
  const [rawCandidateDetails, setRawCandidateDetails] = useState([]);
  const [candidateEntries, setCandidateEntries] = useState(5);
  const [candidateCount, setCandidateCount] = useState(null);
  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);

  const [candidateOtherDetails, setCandidateOtherDetails] = useState([]);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [isDetailsData, setIsDetailsData] = useState(false);


  // userdetails stored in "rawUserDetails"
  const [isCandidateWithOfferData, setIsCandidateWithOfferData] = useState(false);
  const [rawCandidateWithOfferDetails, setRawCandidateWithOfferDetails] = useState([]);
  const [candidateWithOfferEntries, setCandidateWithOfferEntries] = useState(5);
  const [candidateWithOfferCount, setCandidateWithOfferCount] = useState(null);
  const [candidateWithOfferPageCount, setCandidateWithOfferPageCount] = useState(0);
  const [isCandidateWithOfferLoading, setIsCandidateWithOfferLoading] = useState(true);

  //Candidate entries data
  const getCandidateEntriesData = async (
    page,
    searchCandidate,
    sortingDate,
    access
  ) => {
    setIsCandidateLoading(true);
    console.log(searchCandidate);
    let url;
    if (searchCandidate) {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidatejob/?entries=${candidateEntries}&page=${page}&search_by_candidate_job_title=${searchCandidate}&order_by=-created_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidatejob/?entries=${candidateEntries}&page=${page}&search_by_candidate_job_title=${searchCandidate}&order_by=created_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidatejob/?entries=${candidateEntries}&page=${page}&search_by_candidate_job_title=${searchCandidate}`;
      }
    } else {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidatejob/?entries=${candidateEntries}&page=${page}&is_draft=False&order_by=-created_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidatejob/?entries=${candidateEntries}&page=${page}&is_draft=False&order_by=created_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidatejob/?entries=${candidateEntries}&page=${page}&is_draft=False`;
      }
    }
    // if (searchCandidate) {
    //   url =
    //   `http://192.168.29.226:8000/api/candidatejob/?entries=${candidateEntries}&page=${page}&search_by_candidate_job_title=${searchCandidate}`;
    // } else {
    //   url =
    //   `http://192.168.29.226:8000/api/candidatejob/?entries=${candidateEntries}&page=${page}&is_draft=False`;
    // }

    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsCandidateLoading(false);
      if (response.length !== 0) {
        setIsCandidateData(false);
        setRawCandidateDetails(res.data.data);
      } else {
        setIsCandidateData(true);
        setRawCandidateDetails(res.data.data);
      }
      const total = res.data.count;
      setCandidateCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
    } catch (error) {
      console.log(error);
      setIsCandidateLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateData(false);
      } else {
        setIsCandidateData(true);
      }
    }
  };

  const handlePageClick = async (data, searchCandidate, sortingDate) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateEntriesData(
      currentPage,
      searchCandidate,
      sortingDate
    );
  };

  //Candidate details
  const getCandidateOtherData = async (cand_id, req_id, access) => {
    setIsDetailsLoading(true);
    let url =
      VARIABLES.url + `/all-candidate-details/?send_from=rms&candidate_job_id=${cand_id}&requisition_id=${req_id}`;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data;
      console.log(response);
      setIsDetailsLoading(false);
      if (response) {
        setIsDetailsData(false);
        setCandidateOtherDetails(res.data);
      } else {
        setIsDetailsData(true);
        setCandidateOtherDetails(res.data);
      }
    } catch (error) {
      console.log(error);
      setIsDetailsLoading(false);
      setIsDetailsData(true);
      // if (error?.response?.status === 403) {
      //   RefreshToken()
      //   setIsDetailsLoading(false);
      // } else {
      //   setIsCandidateData(true);
      // }
    }
  };


  //CandidateWithOffer entries data
  const getCandidateWithOfferEntriesData = async (requisition_id,
    page,
    searchCandidate,
    access) => {
    setIsCandidateWithOfferLoading(true);
    console.log(searchCandidate);
    let url;
    if (searchCandidate) {
      url =
        VARIABLES.url + `/api/candidate-with-offer/?requisition_id=${requisition_id}&entries=${candidateWithOfferEntries}&page=${page}&event=${searchCandidate}`;
    } else {
      url =
        VARIABLES.url + `/api/candidate-with-offer/?requisition_id=${requisition_id}&entries=${candidateWithOfferEntries}&page=${page}`;
    }

    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsCandidateWithOfferLoading(false);
      if (response.length !== 0) {
        setIsCandidateWithOfferData(false);
        setRawCandidateWithOfferDetails(res.data.data);
      } else {
        setIsCandidateWithOfferData(true);
        setRawCandidateWithOfferDetails(res.data.data);
      }
      const total = res.data.count;
      setCandidateWithOfferCount(total);
      setCandidateWithOfferPageCount(Math.ceil(total / candidateWithOfferEntries));
    } catch (error) {
      console.log(error);
      setIsCandidateWithOfferLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateWithOfferData(false);
      } else {
        setIsCandidateWithOfferData(true);
      }
    }
  };

  const handleCandidateWithOfferPageClick = async (requisition_id, data, searchCandidate) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateWithOfferEntriesData(
      requisition_id,
      currentPage,
      searchCandidate,
      access
    );
  };

  return (
    <div>
      <CandidateEntriesContext.Provider
        value={{
          rawCandidateDetails,
          getCandidateEntriesData,
          candidateEntries,
          candidatePageCount,
          setCandidateEntries,
          candidateCount,
          handlePageClick,
          isCandidateData,
          isCandidateLoading,

          getCandidateOtherData,
          candidateOtherDetails,
          isDetailsLoading,
          isDetailsData,

          rawCandidateWithOfferDetails,
          getCandidateWithOfferEntriesData,
          candidateWithOfferEntries,
          candidateWithOfferPageCount,
          setCandidateWithOfferEntries,
          candidateWithOfferCount,
          handleCandidateWithOfferPageClick,
          isCandidateWithOfferData,
          isCandidateWithOfferLoading
        }}
      >
        {children}
      </CandidateEntriesContext.Provider>
    </div>
  );
}

export default CandidateEntriesProvider;
