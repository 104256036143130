import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { MasterTableContext } from "../../Context/MasterTableContext";
import Swal from "sweetalert2";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const EditSeniority = () => {
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");

  const { getSeniorityData } = useContext(MasterTableContext);
  const { getGenericDropdownData } = useContext(GenericDropdownContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let location = useLocation();
  let navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);

  const onSubmit = (data) => {
    var form_data = JSON.stringify({
      seniority_name: data.seniority_name,
      seniority_display_name: data.seniority_display_name,
      updated_by_id: userID,
    });

    const url = VARIABLES.url + `/api/seniority/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getGenericDropdownData();
        getSeniorityData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Seniority updated successfully",
        });
        setTimeout(() => {
          navigate("/seniority");
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to update seniority",
        });
      });
  };

  const deleteSeniority = () => {
    const url =
      VARIABLES.url +
      `/api/seniority/${location.state.editpost.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {},
    };
    axios(config)
      .then((response) => {
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the seniority",
        });
        console.log(response.data);
        getGenericDropdownData();
        getSeniorityData();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setActiveStatus(1);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to delete the seniority",
        });
        console.log(error);
      });
  };

  const deleteSeniorityAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSeniority();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedSeniority = location.state.editpost;
    if (editedSeniority) {
      var defaultValues = {};
      defaultValues.seniority_name = editedSeniority.seniority_name;
      defaultValues.seniority_display_name =
        editedSeniority.seniority_display_name;
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Seniority </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          {privileges.includes("seniority_delete") ? (
            <div className="card-inner d-flex justify-content-end pt-3 pb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-deleteseniority"
                  checked={activeStatus}
                  onChange={(e) => {
                    deleteSeniorityAlert(location.state?.editpost?.id);
                  }}
                />
                <label
                  className="custom-control-label"
                  id="button-button-status"
                  htmlFor="submit-button-deleteseniority"
                ></label>
              </div>
            </div>
          ) : (
            ""
          )}
          <div class="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Seniority name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter seniority name"
                    id="text-form-seniority_name"
                    {...register("seniority_name", { required: true })}
                  />
                  {errors.seniority_name && (
                    <span className="fs-8 text-danger">
                      Seniority name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Seniority display name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter seniority display name"
                    id="text-form-seniority_display_name"
                    {...register("seniority_display_name", { required: true })}
                  />
                  {errors.seniority_display_name && (
                    <span className="fs-8 text-danger">
                      Seniority display name is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button_loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editseniority"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditSeniority;
