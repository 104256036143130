import React, { useContext, useEffect, useState } from "react";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";
import { MasterTableContext } from "../../../Context/MasterTableContext";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { JobOpeningContext } from "../../../Context/JobOpeningContext";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { VARIABLES } from "../../../Constants";

const CreatePositionModal = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const { getDepartmentData } = useContext(MasterTableContext);

  const {
    isGenricDropdownDataLoading,
    departmentDropdownData,
    teamDropdownData,
    getGenericDropdownData,
  } = useContext(GenericDropdownContext);

  console.log(departmentDropdownData);
  console.log("teamDropdownData: ", teamDropdownData);

  const { getJobDescriptionData } = useContext(JobOpeningContext);

  const [filteredTeamData, setFilteredTeamData] = useState([]);
  console.log(filteredTeamData);
  const [departmentEntry, setDepartmentEntry] = useState(null);
  console.log(departmentEntry);

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();

  let navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const positionName = watch("position_name");
  const positionDisplayName = watch("position_display_name");

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    var user = JSON.stringify({
      position_name: data.position_name,
      position_display_name: data.position_display_name,
      department: data.department.value,
      team_id: data.team.value,
      created_by_id: userID,
      updated_by_id: userID,
    });

    const url = VARIABLES.url + "/api/position/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };
    console.log(user);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Position created successfully",
        });
        getJobDescriptionData();
        getGenericDropdownData();
        reset();
        document.getElementById("button-button-closemodal").click();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to create position",
          });
        }
      });
  };

  const teamOptionCondition = () => {
    return departmentEntry
      ? "No Team Under the selected department"
      : "Please Select Department first";
  };

  const handleBlur = (e) => {
    console.log(e.target.value);
    if (positionName === "") {
      reset({
        position_name: e.target.value,
      });
    }
    if (positionDisplayName === "") {
      reset({
        position_display_name: e.target.value,
      });
    }
  };

  useEffect(() => {
    console.log(departmentEntry?.label);
    const rawfilteredTeamData = teamDropdownData.filter(
      (info) => info?.department_display_name == departmentEntry?.label
    );
    setFilteredTeamData(rawfilteredTeamData);
    resetField("team");
  }, [departmentEntry]);

  useEffect(() => {
    getDepartmentData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="createpositionmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Position
              </h5>
              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Department
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={departmentDropdownData?.map((info) => ({
                              value: info.id,
                              label: info.department_display_name,
                            }))}
                            isLoading={isGenricDropdownDataLoading}
                            onChange={(e) => {
                              setDepartmentEntry(e);
                              field.onChange(e);
                            }}
                            isClearable={true}
                            id="select-forms-department"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="department"
                        control={control}
                      />
                      {errors.department && (
                        <p className="fs-8 text-danger">
                          Department is required
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Team
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={filteredTeamData?.map((info) => ({
                              value: info.id,
                              label: info.team_display_name,
                            }))}
                            noOptionsMessage={teamOptionCondition}
                            isClearable={true}
                            id="select-forms-team"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="team"
                        control={control}
                      />
                      {errors.team && (
                        <p className="fs-8 text-danger">Team is required</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      Position name
                      <span className="fs-8 text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter position name"
                      id="text-forms-position_name"
                      {...register("position_name", {
                        required: true,
                        onBlur: handleBlur,
                      })}
                    />
                    {errors.position_name && (
                      <span className="fs-8 text-danger">
                        Position name is required
                      </span>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      Position display name
                      <span className="fs-8 text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter position display name"
                      id="text-forms-position_display_name"
                      {...register("position_display_name", {
                        required: true,
                        onBlur: handleBlur,
                      })}
                    />
                    {errors.position_display_name && (
                      <span className="fs-8 text-danger">
                        Position display name is required
                      </span>
                    )}
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        id="submit-button-backbutton"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      {submitLoading ? (
                        <button
                          id="button-loading"
                          className="btn text-dark"
                          disabled
                        >
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-createposition"
                        >
                          Create
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePositionModal;
