import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { ActivityLogsContext } from "../../Context/ActivityLogsContext";
import WelcomeMessage from "../../Base/WelcomeMessage";

const PendingOnYou = () => {
    const [searchJobDescription, setSearchJobDescription] = useState("");
    console.log(searchJobDescription);
    const privileges = secureLocalStorage.getItem("privileges");
    const userID = secureLocalStorage.getItem("userID");

    const {
        rawPendingOnYou,
        isPendingOnYouData,
        isPendingOnYouLoading,
        PendingOnYouEntries,
        PendingOnYouCount,
        PendingOnYouPageCount,
        getPendingOnYouData,
        setPendingOnYouEntries,
        handlePendingOnYouClick,
    } = useContext(ActivityLogsContext);

    const handleEntries = (event) => {
        setPendingOnYouEntries(event.target.value);
    };

    const onChangeHandler = (event) => {
        setSearchJobDescription(event.target.value);
    };

    const onSearchHandler = (method, event) => {
        event.preventDefault();
        if (method === "search") {
            getPendingOnYouData(userID, 1, searchJobDescription);
        } else {
            getPendingOnYouData(userID, 1);
            setSearchJobDescription(null);
        }
    };

    useEffect(() => {
        getPendingOnYouData(userID, 1, searchJobDescription);
    }, [PendingOnYouEntries]);

    return (
        <>
            <WelcomeMessage />
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content d-flex">
                        <h3 className="nk-block-title page-title">Pending On You</h3>
                        {isPendingOnYouLoading ? (
                            <div class="spinner-border text-primary ms-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            <div className="nk-block">
                <div className="card">
                    <div className="card-inner-group">
                        <div className="card-inner border-bottom-0">
                            <div className="form-control-wrap">
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            value={searchJobDescription}
                                            className="form-control"
                                            placeholder="Search by message"
                                            onChange={onChangeHandler}
                                            id="text-form-input-search"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-dim"
                                                id="button-button-search"
                                                onClick={(e) => onSearchHandler("search", e)}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className="btn btn-outline-success btn-dim"
                                                id="button-button-view"
                                                onClick={(e) => onSearchHandler("view", e)}
                                            >
                                                Clear Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card-inner p-0">
                            <div className="table-responsive">
                                <table className="table">
                                    <div className="nk-tb-list">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col">
                                                <span>Message</span>
                                            </div>
                                        </div>

                                        {rawPendingOnYou.length > 0 ? (
                                            rawPendingOnYou.map((post, index) => (
                                                <div className="nk-tb-item" key={index}>
                                                    <div className="nk-tb-col">
                                                        <span class="tb-lead" style={{ color: post?.acknowledgement === 1 ? '#808080' : post?.acknowledgement === 0 ? '#FF7F7F' : '#000' }}>
                                                            {post?.message || '-'}</span>
                                                    </div>
                                                </div>
                                            ))
                                        ) : isPendingOnYouData ? (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Good job! You are all caught up.</span>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Loading.....</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </table>
                            </div>
                        </div>
                        <div className="card-inner">
                            <div className="nk-block-between-md g-3">
                                <div className="g">
                                    <ReactPaginate
                                        key={PendingOnYouPageCount}
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={PendingOnYouPageCount}
                                        marginPagesDisplayed={2}
                                        onPageChange={(data) => {
                                            handlePendingOnYouClick(data, userID, searchJobDescription);
                                        }}
                                        containerClassName={
                                            "pagination justify-content-center justify-content-md-start"
                                        }
                                        pageClassName={"paginate_button page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"paginate_button page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                                <div className="g">
                                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                        <div>
                                            Total entries:{" "}
                                            <span className="fw-bold">
                                                {PendingOnYouCount === null ? 0 : PendingOnYouCount}
                                            </span>
                                        </div>
                                        <div>No of entries per page:</div>
                                        <div>
                                            <select
                                                className="form-select js-select2"
                                                data-search="on"
                                                data-dropdown="xs center"
                                                value={PendingOnYouEntries}
                                                onChange={handleEntries}
                                                id="select-form-enteries"
                                            >
                                                <option value="5">5</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PendingOnYou;