import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const BackgroundVerificationContext = createContext(null);

function BackgroundVerificationContextProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const [isLoading, setIsLoading] = useState(true);

  const [bgVerificationDataOne, setBGVerificationDataOne] = useState([]);
  const [bgVerificationDataTwo, setBGVerificationDataTwo] = useState([]);
  const [bgVerificationDataThree, setBGVerificationDataThree] = useState([]);

  //to get background verification data for forms
  const getBGVerificationData = async (candidate_job_id, bgv_type, access) => {
    setIsLoading(true);
    let url =
      VARIABLES.url +
      `/api/backgroundverification/?candidate_job_id=${candidate_job_id}&bgv_type=${bgv_type}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      if (bgv_type === "bgv_1") {
        setBGVerificationDataOne(res.data.data);
      } else if (bgv_type === "bgv_2") {
        setBGVerificationDataTwo(res.data.data);
      } else if (bgv_type === "bgv_3") {
        setBGVerificationDataThree(res.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
  };

  const value = {
    isLoading,
    bgVerificationDataOne,
    bgVerificationDataTwo,
    bgVerificationDataThree,
    getBGVerificationData,
  };
  return (
    <div>
      <BackgroundVerificationContext.Provider value={value}>
        {children}
      </BackgroundVerificationContext.Provider>
    </div>
  );
}

export default BackgroundVerificationContextProvider;
