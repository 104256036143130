import axios from "axios";
import React, { useState,useContext } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const UserDetailsContext = createContext(null);

function UserDetailsProvider({ children }) {
  const {RefreshToken} = useContext(RefreshTokenContext)

  // userdetails stored in "rawUserDetails"
  const [isUsersData, setIsUsersData] = useState(false);
  const [rawUsersDetails, setRawUsersDetails] = useState([]);
  console.log(rawUsersDetails)
  const [usersEntries, setUsersEntries] = useState(5);
  const [usersCount, setUsersCount] = useState(null);
  const [usersPageCount, setUsersPageCount] = useState(0);
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  const getUserDetailsData = async (page, searchUser,access) => {
    setIsUsersLoading(true);
    console.log(searchUser);
    let url;
    if (searchUser) {
      url =
        VARIABLES.url +
        `/api/userdetails/?login_types_id=${VARIABLES.rms_id}&entries=${usersEntries}&page=${page}&event=${searchUser}`;
    } else {
      url =
        VARIABLES.url +
        `/api/userdetails/?login_types_id=${VARIABLES.rms_id}&entries=${usersEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Authorization" : `Bearer ${access}`
       }
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsUsersLoading(false);
      if (response.length !== 0) {
        setIsUsersData(false);
        setRawUsersDetails(res.data.data);
      } else {
        setIsUsersData(true);
        setRawUsersDetails(res.data.data);
      }
      const total = res.data.count;
      setUsersCount(total);
      setUsersPageCount(Math.ceil(total / usersEntries));
    } catch (error) {
      console.log(error);
      setIsUsersLoading(false);
      if(error?.response?.status === 403) {
        RefreshToken()
        setIsUsersData(false);
      }else {
        setIsUsersData(true);
      }
    }
  };

  const handlePageClick = async (data, searchUser) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getUserDetailsData(
      currentPage,
      searchUser
    );
  };

  return (
    <div>
      <UserDetailsContext.Provider
        value={{
          rawUsersDetails,
          getUserDetailsData,
          usersEntries,
          usersPageCount,
          setUsersEntries,
          usersCount,
          handlePageClick,
          isUsersData,
          isUsersLoading,
        }}
      >
        {children}
      </UserDetailsContext.Provider>
    </div>
  );
}

export default UserDetailsProvider;
