import React from "react";
import { Route, Routes } from "react-router-dom";
import AddRequisition from "../Pages/RequestedJobOpening/AddRequisition";
import RequestedJobOpening from "../Pages/RequestedJobOpening/RequestedJobOpening";
import EditRequested from "../Pages/RequestedJobOpening/EditRequested";
import WriteRequestedJD from "../Pages/RequestedJD/WriteRequestedJD";
import EditSeo from "../Pages/SEO/EditSeo";
import UserManagement from "../Pages/UserManagement/UserManagement";
import EducationalDetails from "../Pages/Stepper/CandidateDetails/EducationalDetails";
import ProfessionalDetails from "../Pages/Stepper/CandidateDetails/ProfessionalDetails";
import ScheduleInterviewCreate from "../Pages/ScheduleInterview/ScheduleInterviewCreate";
import InterviewQuestionnaire from "../Pages/InterviewQuestionnaire/InterviewQuestionnaire";
import MettleTest from "../Pages/Stepper/MettleTest/MettleTest";
import AssignAssignment from "../Pages/Stepper/AssignAssignment/AssignAssignment";
import AssignmentReview from "../Pages/Stepper/AssignmentReview/AssignmentReview";
import MettleTestReview from "../Pages/Stepper/MettleTestReview/MettleTestReview";
import MDApproval from "../Pages/Stepper/MDApproval/MDApproval";
import SendOffer from "../Pages/Stepper/SendOffer/SendOffer";
import TechnicalInterviewRound from "../Pages/Stepper/TechnicalInterviewRound/TechnicalInterviewRound";
import JobAssignmentCreate from "../Pages/JobAssignment/JobAssignmentCreate";
import JobAssignment from "../Pages/JobAssignment/JobAssignment";
import JobAssignmentEdit from "../Pages/JobAssignment/JobAssignmentEdit";
import InterviewQuestionnaireCreate from "../Pages/InterviewQuestionnaire/InterviewQuestionnaireCreate";
import InterviewQuestionnaireEdit from "../Pages/InterviewQuestionnaire/InterviewQuestionnaireEdit";
import UserManagementCreate from "../Pages/UserManagement/UserManagementCreate";
import Roles from "../Pages/Settings/Roles/Roles";
import RolesCreate from "../Pages/Settings/Roles/RolesCreate";
import RolesEdit from "../Pages/Settings/Roles/RolesEdit";
import TypeMaster from "../Pages/Settings/TypeMaster/TypeMaster";
import TypeMasterCreate from "../Pages/Settings/TypeMaster/TypeMasterCreate";
import TypeMasterEdit from "../Pages/Settings/TypeMaster/TypeMasterEdit";
import ScheduleInterviewDetails from "../Pages/ScheduleInterview/ScheduleInterviewDetails";
import StepperCandidateDetails from "../Pages/StepperCandidateDetails/StepperCandidateDetails";
import WorkFlowDemo from "../Pages/Workflow/WorflowDemo";
import WorkFlowCreate from "../Pages/Workflow/WorkFlowCreate";
import WorkFlowEdit from "../Pages/Workflow/WorkflowEdit";
import CandidateResumeCreate from "../Pages/CandidateResume/CandidateResumeCreate";
import CandidateResumeEdit from "../Pages/CandidateResume/CandidateResumeEdit";
import RequestedJDNew from "../Pages/RequestedJD/RequestedJDNew";
import AddJobAssignment from "../Pages/RequestedJD/AddJobAssignment";
import ViewAllResume from "../Pages/CandidateApplied/ViewAllResume";

import Department from "../Pages/Department/Department";
import CreateDepartment from "../Pages/Department/CreateDepartment";
import EditDepartment from "../Pages/Department/EditDepartment";
import Team from "../Pages/Team/Team";
import CreateTeam from "../Pages/Team/CreateTeam";
import EditTeam from "../Pages/Team/EditTeam";
import CreatePosition from "../Pages/Position/CreatePosition";
import EditPosition from "../Pages/Position/EditPosition";
import Position from "../Pages/Position/Position";
import Privilege from "../Pages/Settings/Privileges/Privilege";
import EditPrivilege from "../Pages/Settings/Privileges/EditPrivilege";
import JobWorkflowDemo from "../Pages/JobWorkflow/JobWorkflowDemo";
import Seniority from "../Pages/Seniority/Seniority";
import CreateSeniority from "../Pages/Seniority/CreateSeniority";
import EditSeniority from "../Pages/Seniority/EditSeniority";
import CandidateResumeTable from "../Pages/CandidateResume/CandidateResume";

import AgencyMaster from "../Pages/AgencyMaster/AgencyMaster";
import CreateAgencyMaster from "../Pages/AgencyMaster/CreateAgencyMaster";
import EditAgencyMaster from "../Pages/AgencyMaster/EditAgencyMaster";
import ResumePoolAdvanceFilter from "../Pages/ResumePoolAdvanceFilter/ResumePoolAdvanceFilter";

import RequestAcceptance from "../Pages/RequestAcceptance/RequestAcceptance";
import Seo from "../Pages/SEO/Seo";
import Tam from "../Pages/TAM/Tam";
import RequisitionAssignedRE from "../Pages/TAM/RequisitionAssignedRE";
import RecruitmentCordinator from "../Pages/RecruitmentCordinator/RecruitmentCordinator";
import InterviewerSME from "../Pages/InterviewerSME/InterviewerSME";
import RE1 from "../Pages/RE-1/RE1";
import RE3 from "../Pages/RE-3/RE3";
import RE1Create from "../Pages/RE-1/RE1Create";
import RE2Create from "../Pages/RE-2/RE2Create";
import RE3Create from "../Pages/RE-3/RE3Create";
import RE2 from "../Pages/RE-2/RE2";
import secureLocalStorage from "react-secure-storage";
import JobOpeningDetails from "../Pages/JobOpeningDetails/JobOpeningDetails";
import ViewRCCandidatesResume from "../Pages/RecruitmentCordinator/ViewRCCandidatesResume";
import ViewSMECandidatesResume from "../Pages/InterviewerSME/ViewSMECandidatesResume";
import NotFound from "../Pages/ErrorPage/NotFound";
import StepperForm from "../Pages/WorkflowStepperForm/StepperForm";
import RERequisitionDetails from "../Pages/JobOpeningDetails/RERequisitionDetails";
import ScheduleCandidateInterview from "../Pages/ScheduleCandidateInterview/ScheduleCandidateInterview";
import ScheduleCandidateView from "../Pages/ScheduleCandidateInterview/ScheduleCandidateView";
import ScheduleCalendar from "../Pages/ScheduleCandidateInterview/ScheduleCalendar";
import HRInterviewRound from "../Pages/Stepper/HRInterviewRound/HRInterviewRound";
import SmartHireForm from "../Pages/Stepper/SmartHireForm/SmartHireForm";
import SMECandidateResume from "../Pages/InterviewerSME/SMECandidteApplied";
import TelephonicRound from "../Pages/Stepper/TelephonicInterviewRound/TelephonicRound";
import KYC from "../Pages/Stepper/KYC/KYC";
import HRD from "../Pages/HRDApproval/HRD";
import HRDApproval from "../Pages/Stepper/HRDApproval/HRDApproval";
import ResumeDownload from "../Pages/ResumeDownload/ResumeDownload";
import ActivityLogs from "../Pages/ActivityLogs/ActivityLogs";
import CandidatesAppliedForReq from "../Pages/ActivityLogs/CandidatesAppliedForReq";
import CandidateTimeline from "../Pages/ActivityLogs/CandidateTimeline";
import RequisitionTimeline from "../Pages/ActivityLogs/RequisitionTimeline";
import PersonalDetails from "../Pages/Stepper/CandidateDetails/PersonalDetails";
import TechnicalInterviewReview from "../Pages/Stepper/TechnicalInterviewRound/TechnicalInterviewReview";
import SmartHireFormReview from "../Pages/Stepper/SmartHireForm/SmartHireFormReview";
import TelephonicInterviewReview from "../Pages/Stepper/TelephonicInterviewRound/TelephonicInterviewReview";
import SmeScheduledInterviews from "../Pages/InterviewerSME/SmeScheduledInterviews";
import RCCandidateResume from "../Pages/RecruitmentCordinator/RCCandidateApplied";
import RCScheduledInterviews from "../Pages/RecruitmentCordinator/RCScheduledInterviews";
import JobLocation from "../Pages/JobLocation/JobLocation";
import JobLocationCreate from "../Pages/JobLocation/CreateJobLocation";
import EditJobLocation from "../Pages/JobLocation/EditJobLocation";
import AdditionalDetails from "../Pages/Stepper/CandidateDetails/AdditionalDetails";
import BGV1 from "../Pages/Stepper/BackgroundVerification/BGV1";
import BGV2 from "../Pages/Stepper/BackgroundVerification/BGV2";
import BGV3 from "../Pages/Stepper/BackgroundVerification/BGV3";
import BGV1Review from "../Pages/Stepper/BackgroundVerification/BGV1Review";
import BGV2Review from "../Pages/Stepper/BackgroundVerification/BGV2Review";
import BGV3Review from "../Pages/Stepper/BackgroundVerification/BGV3Review";
import HRInterviewReview from "../Pages/Stepper/HRInterviewRound/HRInterviewReview";
import ScheduleInterviewUpdate from "../Pages/ScheduleInterview/ScheduleInterviewUpdate";
import Notifications from "../Pages/Notifications/Notifications";
import CandidateList from "../Pages/RequisitionStatus/CandidateList";
import CandidateStatus from "../Pages/RequisitionStatus/CandidateStatus";
import MDRequisitionList from "../Pages/MDFormApproval/MDRequisitionList";
import MDCandidateList from "../Pages/MDFormApproval/MDCandidateList";
import HRDRequisitionList from "../Pages/HRDFormApproval/HRDRequisitionList";
import HRDCandidateList from "../Pages/HRDFormApproval/HRDCandidateList";
import NotificationMapper from "../Pages/NotificationMapper/NotificationMapper";
import NotificationMapperEdit from "../Pages/NotificationMapper/NotificationMapperEdit";
import VersionTracker from "../Pages/VersionTracker/VersionTracker";
import VersionTrackerCreate from "../Pages/VersionTracker/VersionTrackerCreate";
import CandidateEntries from "../Pages/CandidateEntries/CandidateEntries";
import CandidateOtherDetails from "../Pages/CandidateEntries/CandidateOtherDetails";
import AssignmentUpload from "../Pages/Stepper/AssignAssignment/AssignmentUpload";
import MettleTestUpload from "../Pages/Stepper/MettleTest/MettleTestUpload";
import AllRequisition from "../Pages/AllRequsition/AllRequisition";
import SeoDetails from "../Pages/JobOpeningDetails/SeoDetails";
import ReferralResume from "../Pages/Referral Resume/ReferralResume";
import TrackRequisitions from "../Pages/TrackRequisitions/TrackRequisitions";
import PendingOnYou from "../Pages/PendingOnYou/PendingOnYou";
import RequisitionDetails from "../Pages/TAM/RequisitionDetails";
import TamCalendar from "../Pages/TAM/TamCalendar";
import SMECandidateDrafted from "../Pages/InterviewerDrafter/SMECandidateDrafted";
import SMECandidateAll from "../Pages/InterviewerAll/SMECandidateAll";
import MastersCombinedForm from "../Pages/MastersCombinedForm/MastersCombinedForm";
import DepartmentReq from "../Pages/Department/DepartmentReq";
import CandidateAcceptanceStatus from "../Pages/CandidateStatus/CandidateAcceptanceStatus";
import CandidateAcceptanceForReq from "../Pages/CandidateStatus/CandidateAcceptanceForReq";
import RCCandidateAll from "../Pages/RecruitmentCordinator/RCCandidateAll";

const Content = () => {
  const privileges = secureLocalStorage.getItem("privileges");

  return (
    <>
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <Routes>
              <Route path="*" element={<NotFound />} />

              {/* Requisition Manager Start */}
              {privileges.includes("requisition_retrieve") ? (
                <Route exact path="/" element={<RequestedJobOpening />} />
              ) : (
                <Route path="/" element={<NotFound />} />
              )}

              {/* CHANGE */}
              {/* {privileges.includes("P1") ? ( */}
              <Route exact path="/pendingonyou" element={<PendingOnYou />} />
              {/* ) : (
                <Route path="/pendingonyou" element={<NotFound />} />
              )} */}

              {privileges.includes("requisition_create") ? (
                <Route
                  path="/addrequestrequisition"
                  element={<AddRequisition />}
                />
              ) : (
                <Route path="/addrequestrequisition" element={<NotFound />} />
              )}

              {privileges.includes("requisition_update") ? (
                <Route path="/editrequest" element={<EditRequested />} />
              ) : (
                <Route path="/editrequest" element={<NotFound />} />
              )}
              {/* Requisition Manager End */}

              {/* All Requisition Start */}
              {privileges.includes("all_requisition_retrieve") ? (
                <Route path="/allrequisition" element={<AllRequisition />} />
              ) : (
                <Route path="/allrequisition" element={<NotFound />} />
              )}
              {/* All Requisition End */}

              {/* Requisition Status Starts */}
              {privileges.includes("candidate_list") ? (
                <Route path="/candidatelist" element={<CandidateList />} />
              ) : (
                <Route path="/candidatelist" element={<NotFound />} />
              )}

              {/* To-Do: privilege */}
              {/* {privileges.includes("candidate_list") ? ( */}
              <Route
                path="/candidateacceptstatus"
                element={<CandidateAcceptanceStatus />}
              />
              {/* ) : (
                <Route path="/candidatelist" element={<NotFound />} />
              )} */}

              {/* To-Do: privilege */}
              {/* {privileges.includes("candidate_list") ? ( */}
              <Route
                path="/candidateacceptstatusforreq"
                element={<CandidateAcceptanceForReq />}
              />
              {/* ) : (
                <Route path="/candidatelist" element={<NotFound />} />
              )} */}

              {privileges.includes("candidate_status") ? (
                <Route path="/candidatestatus" element={<CandidateStatus />} />
              ) : (
                <Route path="/candidatestatus" element={<NotFound />} />
              )}
              {/* Requisition Status Ends */}

              {privileges.includes("md_requisition_list") ? (
                <Route path="/md-approval" element={<MDRequisitionList />} />
              ) : (
                <Route path="/editrequest" element={<NotFound />} />
              )}

              {privileges.includes("md_candidate_list") ? (
                <Route path="/mdcandidatelist" element={<MDCandidateList />} />
              ) : (
                <Route path="/editrequest" element={<NotFound />} />
              )}

              {privileges.includes("hrd_requisition_list") ? (
                <Route path="/hrd-approval" element={<HRDRequisitionList />} />
              ) : (
                <Route path="/editrequest" element={<NotFound />} />
              )}

              {privileges.includes("hrd_candidate_list") ? (
                <Route
                  path="/hrdcandidatelist"
                  element={<HRDCandidateList />}
                />
              ) : (
                <Route path="/editrequest" element={<NotFound />} />
              )}

              {/* Requisition/ Job Opening Details Starts */}
              {privileges.includes("job_opening_retrieve") ? (
                <Route
                  path="/jobopeningdetails"
                  element={<JobOpeningDetails />}
                />
              ) : (
                <Route path="/jobopeningdetails" element={<NotFound />} />
              )}

              {privileges.includes("job_opening_retrieve") ? (
                <Route path="/seodetails" element={<SeoDetails />} />
              ) : (
                <Route path="/seodetails" element={<NotFound />} />
              )}
              {/* Requisition/ Job Opening Details Ends */}

              {/* Requisition Approval Starts */}
              {privileges.includes("requisition_approval_retrieve") ? (
                <Route
                  path="/requisitionapproval"
                  element={<RequestAcceptance />}
                />
              ) : (
                <Route path="/requisitionapproval" element={<NotFound />} />
              )}
              {/* Requisition Approval Ends */}

              {/* Candidate Job Enteries/ Details Starts */}
              {privileges.includes("candidate_entries_retrieve") ? (
                <Route
                  path="/candidateentries"
                  element={<CandidateEntries />}
                />
              ) : (
                <Route path="/candidateentries" element={<NotFound />} />
              )}

              {privileges.includes("candidate_entries_details") ? (
                <Route
                  path="/candidateotherdetails"
                  element={<CandidateOtherDetails />}
                />
              ) : (
                <Route path="/candidateotherdetails" element={<NotFound />} />
              )}
              {/* Candidate Job Enteries/ Details Starts */}

              {/* TAM Requisition Details Start */}
              {privileges.includes("assess_requisition_retrieve") ? (
                <Route path="/assess-requisitions" element={<Tam />} />
              ) : (
                <Route path="/assess-requisitions" element={<NotFound />} />
              )}

              {/* CHANGE */}
              {/* {privileges.includes("P1") ? ( */}
              <Route path="/tamcalendar" element={<TamCalendar />} />
              {/* ) : (
                <Route path="/tamcalendar" element={<NotFound />} />
              )} */}

              {/* CHANGE */}
              {/* {privileges.includes("P1") ? ( */}
              <Route
                path="/requisitiondetails"
                element={<RequisitionDetails />}
              />
              {/* ) : (
                <Route path="/requisitiondetails" element={<NotFound />} />
              )} */}

              {privileges.includes("assign_user_to_requisition") ? (
                <Route path="/assignedrc" element={<RequisitionAssignedRE />} />
              ) : (
                <Route path="/assignedrc" element={<NotFound />} />
              )}
              {/* TAM Requisition Details Start */}

              {/* RE1 Requisition Details Starts */}
              {privileges.includes("college_outreach_requisition_retrieve") ? (
                <Route path="/college-outreach" element={<RE1 />} />
              ) : (
                <Route path="/college-outreach" element={<NotFound />} />
              )}

              {privileges.includes("college_outreach_resume_upload") ? (
                <Route path="/re1create" element={<RE1Create />} />
              ) : (
                <Route path="/re1create" element={<NotFound />} />
              )}
              {/* RE1 Requisition Details Ends */}

              {/* RE2 Requisition Details Starts */}
              {privileges.includes("digital_outreach_requisition_retrieve") ? (
                <Route path="/digital-outreach" element={<RE2 />} />
              ) : (
                <Route path="/digital-outreach" element={<NotFound />} />
              )}

              {privileges.includes("digital_outreach_resume_upload") ? (
                <Route path="/re2create" element={<RE2Create />} />
              ) : (
                <Route path="/re2create" element={<NotFound />} />
              )}
              {/* RE2 Requisition Details Ends */}

              {/* RE3 Requisition Details Starts */}
              {privileges.includes("agency_outreach_requisition_retrieve") ? (
                <Route path="/agency-outreach" element={<RE3 />} />
              ) : (
                <Route path="/agency-outreach" element={<NotFound />} />
              )}

              {privileges.includes("agency_outreach_resume_upload") ? (
                <Route path="/re3create" element={<RE3Create />} />
              ) : (
                <Route path="/re3create" element={<NotFound />} />
              )}
              {/* RE3 Requisition Details Ends */}

              {/* SEO Details Starts */}
              {privileges.includes("seo_retrieve") ? (
                <Route path="/seo" element={<Seo />} />
              ) : (
                <Route path="/seo" element={<NotFound />} />
              )}

              {privileges.includes("seo_update") ? (
                <Route exact path="/editseo" element={<EditSeo />} />
              ) : (
                <Route path="/editseo" element={<NotFound />} />
              )}
              {/* SEO Details Ends */}

              {/* RC Details Starts */}
              {privileges.includes("rc_schedule_interview_retrieve") ? (
                <Route
                  path="/rcscheduledinterviews"
                  element={<RCScheduledInterviews />}
                />
              ) : (
                <Route path="/rcscheduledinterviews" element={<NotFound />} />
              )}

              {privileges.includes("pre_screening_requisition_retrieve") ? (
                <Route
                  path="/pre-screening"
                  element={<RecruitmentCordinator />}
                />
              ) : (
                <Route path="/pre-screening" element={<NotFound />} />
              )}

              {/* {privileges.includes("rc_candidate_resume_update") ? ( */}
              <Route
                path="/viewrccandidateresume"
                element={<ViewRCCandidatesResume />}
              />
              {/* ) : (
                <Route path="/viewrccandidateresume" element={<NotFound />} />
              )} */}

              {privileges.includes("candidate_details_retrieve") ? (
                <Route
                  path="/rccandidateapplied"
                  element={<RCCandidateResume />}
                />
              ) : (
                <Route path="/rccandidateapplied" element={<NotFound />} />
              )}

              {privileges.includes("view_all_resume") ? (
                <Route path="/viewallresume" element={<ViewAllResume />} />
              ) : (
                <Route path="/viewallresume" element={<NotFound />} />
              )}

              {/* {privileges.includes("view_all_resume") ? ( */}
              <Route
                path="/smecandidatedrafted"
                element={<SMECandidateDrafted />}
              />

              <Route path="/smecandidateall" element={<SMECandidateAll />} />
              {/* ) : (
                <Route path="/smecandidateall" element={<NotFound />} />
              )} */}

              <Route path="/rccandidateall" element={<RCCandidateAll />} />
              {/* ) : (
                <Route path="/rccandidateall" element={<NotFound />} />
              )} */}

              {/* RC Details Ends */}

              {/* SME Details Starts */}
              {privileges.includes("sme_schedule_interview_retrieve") ? (
                <Route
                  path="/smescheduledinterviews"
                  element={<SmeScheduledInterviews />}
                />
              ) : (
                <Route path="/smescheduledinterviews" element={<NotFound />} />
              )}

              {privileges.includes("shortlisting_requisition_retrieve") ? (
                <Route path="/shortlisting" element={<InterviewerSME />} />
              ) : (
                <Route path="/shortlisting" element={<NotFound />} />
              )}

              {/* {privileges.includes("sme_candidate_resume_update") ? ( */}
              <Route
                path="/viewsmecandidateresume"
                element={<ViewSMECandidatesResume />}
              />
              {/* ) : (
                <Route path="/viewsmecandidateresume" element={<NotFound />} />
              )} */}

              {privileges.includes("candidate_details_retrieve") ? (
                <Route
                  path="/smecandidateapplied"
                  element={<SMECandidateResume />}
                />
              ) : (
                <Route path="/smecandidateapplied" element={<NotFound />} />
              )}
              {/* SME Details Ends */}

              {/* Resume Advance Pool Details Starts */}
              {privileges.includes("resume_pool_advance_filter") ? (
                <Route
                  path="/resumepooladvancefilter"
                  element={<ResumePoolAdvanceFilter />}
                />
              ) : (
                <Route path="/resumepooladvancefilter" element={<NotFound />} />
              )}
              {/* Resume Advance Pool Details Ends */}

              {privileges.includes("hrd_eagle_eye_view") ? (
                <Route path="/hrdapproval" element={<HRD />} />
              ) : (
                <Route path="/hrdapproval" element={<NotFound />} />
              )}

              {privileges.includes("activity_logs") ? (
                <Route path="/activitylogs" element={<ActivityLogs />} />
              ) : (
                <Route path="/activitylogs" element={<NotFound />} />
              )}

              {privileges.includes("requisition_activity_logs") ? (
                <Route
                  path="/requisitiontimeline"
                  element={<RequisitionTimeline />}
                />
              ) : (
                <Route path="/requisitiontimeline" element={<NotFound />} />
              )}

              {privileges.includes("candidate_activity_logs") ? (
                <Route
                  path="/candidatetimeline"
                  element={<CandidateTimeline />}
                />
              ) : (
                <Route path="/candidatetimeline" element={<NotFound />} />
              )}

              {privileges.includes("candidate_details_retrieve") ? (
                <Route
                  path="/candidateappliedforreq"
                  element={<CandidatesAppliedForReq />}
                />
              ) : (
                <Route path="/candidateappliedforreq" element={<NotFound />} />
              )}

              {privileges.includes(
                "interview_scheduling_requisition_retrieve"
              ) ? (
                <Route
                  path="/interviewscheduling"
                  element={<ScheduleCandidateInterview />}
                />
              ) : (
                <Route path="/interviewscheduling" element={<NotFound />} />
              )}

              {privileges.includes("track_requisition_retrieve") ? (
                <Route
                  path="/trackrequisitions"
                  element={<TrackRequisitions />}
                />
              ) : (
                <Route path="/trackrequisitions" element={<NotFound />} />
              )}

              {[
                "shortlisted_candidate_details",
                "view_shortlisted_resume",
              ].some((privilege) => privileges.includes(privilege)) ? (
                <Route
                  path="/schedulecandidateview"
                  element={<ScheduleCandidateView />}
                />
              ) : (
                <Route path="/schedulecandidateview" element={<NotFound />} />
              )}

              {privileges.includes("view_calendar") ? (
                <Route
                  path="/schedulecalendar"
                  element={<ScheduleCalendar />}
                />
              ) : (
                <Route path="/schedulecalendar" element={<NotFound />} />
              )}

              {privileges.includes("schedule_interview_create") ? (
                <Route
                  path="/scheduleinterviewcreate"
                  element={<ScheduleInterviewCreate />}
                />
              ) : (
                <Route path="/scheduleinterviewcreate" element={<NotFound />} />
              )}

              {privileges.includes("schedule_interview_update") ? (
                <Route
                  path="/scheduleinterviewedit"
                  element={<ScheduleInterviewUpdate />}
                />
              ) : (
                <Route path="/scheduleinterviewedit" element={<NotFound />} />
              )}

              {privileges.includes("schedule_interview_retrieve") ? (
                <Route
                  path="/scheduleinterviewdetails"
                  element={<ScheduleInterviewDetails />}
                />
              ) : (
                <Route
                  path="/scheduleinterviewdetails"
                  element={<NotFound />}
                />
              )}

              {/* Dashboard/ Landing Page Ends */}

              {/* Masters Route starts */}
              {privileges.includes("usermanagement_retrieve") ? (
                <Route path="/usermanagement" element={<UserManagement />} />
              ) : (
                <Route path="/usermanagement" element={<NotFound />} />
              )}

              {privileges.includes("usermanagement_create") ? (
                <Route path="/createuser" element={<UserManagementCreate />} />
              ) : (
                <Route path="/createuser" element={<NotFound />} />
              )}

              {privileges.includes("department_retrieve") ? (
                <Route path="/department" element={<Department />} />
              ) : (
                <Route path="/department" element={<NotFound />} />
              )}

              {/* To-Do: privilege */}
              {/* {privileges.includes("department_retrieve") ? ( */}
              <Route
                path="/departmentrequisitions"
                element={<DepartmentReq />}
              />
              {/* ) : (
                <Route path="/department" element={<NotFound />} />
              )} */}

              {privileges.includes("department_create") ? (
                <Route path="/adddepartment" element={<CreateDepartment />} />
              ) : (
                <Route path="/adddepartment" element={<NotFound />} />
              )}

              {privileges.includes("department_update") ? (
                <Route path="/editDepartment" element={<EditDepartment />} />
              ) : (
                <Route path="/editDepartment" element={<NotFound />} />
              )}

              {privileges.includes("candidate_entries_retrieve") ? (
                <Route
                  path="/candidateentries"
                  element={<CandidateEntries />}
                />
              ) : (
                <Route path="/candidateentries" element={<NotFound />} />
              )}

              {privileges.includes("candidate_entries_details") ? (
                <Route
                  path="/candidateotherdetails"
                  element={<CandidateOtherDetails />}
                />
              ) : (
                <Route path="/candidateotherdetails" element={<NotFound />} />
              )}

              {privileges.includes("team_retrieve") ? (
                <Route path="/team" element={<Team />} />
              ) : (
                <Route path="/team" element={<NotFound />} />
              )}

              {privileges.includes("team_create") ? (
                <Route path="/addteam" element={<CreateTeam />} />
              ) : (
                <Route path="/addteam" element={<NotFound />} />
              )}

              {privileges.includes("team_update") ? (
                <Route path="/editteam" element={<EditTeam />} />
              ) : (
                <Route path="/editteam" element={<NotFound />} />
              )}

              {privileges.includes("position_retrieve") ? (
                <Route path="/position" element={<Position />} />
              ) : (
                <Route path="/position" element={<NotFound />} />
              )}

              {/* {privileges.includes("position_retrieve") ? ( */}
              <Route
                path="/mastercombinedform"
                element={<MastersCombinedForm />}
              />
              {/* ) : (
                <Route path="/position" element={<NotFound />} />
              )} */}

              {privileges.includes("position_create") ? (
                <Route path="/addposition" element={<CreatePosition />} />
              ) : (
                <Route path="/addposition" element={<NotFound />} />
              )}

              {privileges.includes("position_update") ? (
                <Route path="/editposition" element={<EditPosition />} />
              ) : (
                <Route path="/editposition" element={<NotFound />} />
              )}

              {privileges.includes("seniority_retrieve") ? (
                <Route path="/seniority" element={<Seniority />} />
              ) : (
                <Route path="/seniority" element={<NotFound />} />
              )}

              {privileges.includes("seniority_create") ? (
                <Route path="/createseniority" element={<CreateSeniority />} />
              ) : (
                <Route path="/createseniority" element={<NotFound />} />
              )}

              {privileges.includes("seniority_update") ? (
                <Route path="/editseniority" element={<EditSeniority />} />
              ) : (
                <Route path="/editseniority" element={<NotFound />} />
              )}

              {privileges.includes("agency_retrieve") ? (
                <Route path="/agencymaster" element={<AgencyMaster />} />
              ) : (
                <Route path="/agencymaster" element={<NotFound />} />
              )}

              {privileges.includes("agency_create") ? (
                <Route path="/addagency" element={<CreateAgencyMaster />} />
              ) : (
                <Route path="/addagency" element={<NotFound />} />
              )}

              {privileges.includes("agency_update") ? (
                <Route path="/editagency" element={<EditAgencyMaster />} />
              ) : (
                <Route path="/editagency" element={<NotFound />} />
              )}

              {/* {privileges.includes("tag_retrieve") ? (
                <Route path="/tag" element={<Tag />} />
              ) : (
                <Route path="/tag" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_create") ? (
                <Route path="/createtag" element={<CreateTag />} />
              ) : (
                <Route path="/createtag" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_update") ? (
                <Route path="/edittag" element={<EditTag />} />
              ) : (
                <Route path="/edittag" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_category_retrieve") ? (
                <Route path="/tagcategory" element={<TagCategory />} />
              ) : (
                <Route path="/tagcategory" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_category_create") ? (
                <Route
                  path="/createtagcategory"
                  element={<CreateTagCategory />}
                />
              ) : (
                <Route path="/createtagcategory" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_category_update") ? (
                <Route path="/edittagcategory" element={<EditTagCategory />} />
              ) : (
                <Route path="/edittagcategory" element={<NotFound />} />
              )} */}

              {privileges.includes("candidate_resume_retrieve") ? (
                <Route
                  path="/candidateresume"
                  element={<CandidateResumeTable />}
                />
              ) : (
                <Route path="/candidateresume" element={<NotFound />} />
              )}

              {["candidate_resume_create", "refer_candidate_create"].some(
                (privilege) => privileges.includes(privilege)
              ) ? (
                <Route
                  path="/createcandidateresume"
                  element={<CandidateResumeCreate />}
                />
              ) : (
                <Route path="/createcandidateresume" element={<NotFound />} />
              )}

              {["candidate_resume_update", "refer_candidate_update"].some(
                (privilege) => privileges.includes(privilege)
              ) ? (
                <Route
                  path="/editcandidateresume"
                  element={<CandidateResumeEdit />}
                />
              ) : (
                <Route path="/editcandidateresume" element={<NotFound />} />
              )}

              {privileges.includes("refer_candidate_retrieve") ? (
                <Route path="/referralresume" element={<ReferralResume />} />
              ) : (
                <Route path="/referralresume" element={<NotFound />} />
              )}

              {privileges.includes("notification_mapper_retrieve") ? (
                <Route
                  path="/notificationmapper"
                  element={<NotificationMapper />}
                />
              ) : (
                <Route path="/notificationmapper" element={<NotFound />} />
              )}

              {privileges.includes("notification_mapper_update") ? (
                <Route
                  path="/editnotificationmapper"
                  element={<NotificationMapperEdit />}
                />
              ) : (
                <Route path="/editnotificationmapper" element={<NotFound />} />
              )}

              {privileges.includes("version_tracker_retrieve") ? (
                <Route path="/versiontracker" element={<VersionTracker />} />
              ) : (
                <Route path="/versiontracker" element={<NotFound />} />
              )}

              {privileges.includes("version_tracker_create") ? (
                <Route
                  path="/versiontrackercreate"
                  element={<VersionTrackerCreate />}
                />
              ) : (
                <Route path="/versiontrackercreate" element={<NotFound />} />
              )}

              {privileges.includes("job_assignment_retrieve") ? (
                <Route path="/jobassignment" element={<JobAssignment />} />
              ) : (
                <Route path="/jobassignment" element={<NotFound />} />
              )}

              {privileges.includes("job_assignment_create") ? (
                <Route
                  path="/createjobassignment"
                  element={<JobAssignmentCreate />}
                />
              ) : (
                <Route path="/createjobassignment" element={<NotFound />} />
              )}

              {privileges.includes("job_assignment_update") ? (
                <Route
                  path="/editjobassignment"
                  element={<JobAssignmentEdit />}
                />
              ) : (
                <Route path="/editjobassignment" element={<NotFound />} />
              )}

              {privileges.includes("job_assignment_update") ? (
                <Route
                  path="/addjobassignment"
                  element={<AddJobAssignment />}
                />
              ) : (
                <Route path="/editjobassignment" element={<NotFound />} />
              )}

              {privileges.includes("job_opening_update") ? (
                <Route path="/writejd" element={<WriteRequestedJD />} />
              ) : (
                <Route path="/writejd" element={<NotFound />} />
              )}

              {privileges.includes("job_opening_retrieve") ? (
                <Route path="/jobdescription" element={<RequestedJDNew />} />
              ) : (
                <Route path="/jobdescription" element={<NotFound />} />
              )}

              {privileges.includes("interview_questionnaire_retrieve") ? (
                <Route
                  path="/interviewquestionnaire"
                  element={<InterviewQuestionnaire />}
                />
              ) : (
                <Route path="/interviewquestionnaire" element={<NotFound />} />
              )}

              {privileges.includes("interview_questionnaire_create") ? (
                <Route
                  path="/createinterviewquestionnaire"
                  element={<InterviewQuestionnaireCreate />}
                />
              ) : (
                <Route
                  path="/createinterviewquestionnaire"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("interview_questionnaire_update") ? (
                <Route
                  path="/editinterviewquestionnaire"
                  element={<InterviewQuestionnaireEdit />}
                />
              ) : (
                <Route
                  path="/editinterviewquestionnaire"
                  element={<NotFound />}
                />
              )}

              {["bgv_agency_retrieve", "job_location_retrieve"].some(
                (privilege) => privileges.includes(privilege)
              ) ? (
                <Route path="/joblocation" element={<JobLocation />} />
              ) : (
                <Route path="/joblocation" element={<NotFound />} />
              )}

              {privileges.includes("job_location_create") ? (
                <Route
                  path="/createjoblocation"
                  element={<JobLocationCreate />}
                />
              ) : (
                <Route path="/createjoblocation" element={<NotFound />} />
              )}

              {privileges.includes("job_location_update") ? (
                <Route path="/editjoblocation" element={<EditJobLocation />} />
              ) : (
                <Route path="/editjoblocation" element={<NotFound />} />
              )}

              {/* Masters Route Ends */}

              {/* Workflow Forms Starts */}

              {["SME View Progress", "view_progress"].some((privilege) =>
                privileges.includes(privilege)
              ) ? (
                <Route path="/stepper" element={<StepperForm />} />
              ) : (
                <Route path="/stepper" element={<NotFound />} />
              )}

              {privileges.includes("Assign Assignment") ? (
                <Route
                  path="/assignassignment"
                  element={<AssignAssignment />}
                />
              ) : (
                <Route path="/assignassignment" element={<NotFound />} />
              )}

              {privileges.includes("Assignment Upload") ? (
                <Route
                  path="/assignmentupload"
                  element={<AssignmentUpload />}
                />
              ) : (
                <Route path="/assignmentupload" element={<NotFound />} />
              )}

              {privileges.includes("Assignment Review") ? (
                <Route
                  path="/assignmentreview"
                  element={<AssignmentReview />}
                />
              ) : (
                <Route path="/assignmentreview" element={<NotFound />} />
              )}

              {privileges.includes("Test") ? (
                <Route path="/mettletest" element={<MettleTest />} />
              ) : (
                <Route path="/mettletest" element={<NotFound />} />
              )}

              {privileges.includes("Psychometric Test Upload") ? (
                <Route
                  path="/mettletestupload"
                  element={<MettleTestUpload />}
                />
              ) : (
                <Route path="/mettletestupload" element={<NotFound />} />
              )}

              {privileges.includes("Test Review") ? (
                <Route
                  path="/mettletestreview"
                  element={<MettleTestReview />}
                />
              ) : (
                <Route path="/mettletestreview" element={<NotFound />} />
              )}

              {privileges.includes("MD Approval") ? (
                <Route path="/mdapproval" element={<MDApproval />} />
              ) : (
                <Route path="/mdapproval" element={<NotFound />} />
              )}

              {privileges.includes("HRD Approval") ? (
                <Route path="/hrdapprovalform" element={<HRDApproval />} />
              ) : (
                <Route path="/hrdapprovalform" element={<HRDApproval />} />
              )}

              {privileges.includes("Send Offer") ? (
                <Route path="/sendoffer" element={<SendOffer />} />
              ) : (
                <Route path="/sendoffer" element={<NotFound />} />
              )}

              {privileges.includes("Technical Interview") ? (
                <Route
                  path="/technicalinterviewround"
                  element={<TechnicalInterviewRound />}
                />
              ) : (
                <Route path="/technicalinterviewround" element={<NotFound />} />
              )}

              {privileges.includes("Technical Interview Review") ? (
                <Route
                  path="/technicalinterviewreview"
                  element={<TechnicalInterviewReview />}
                />
              ) : (
                <Route
                  path="/technicalinterviewreview"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("HR Interview") ? (
                <Route
                  path="/hrinterviewround"
                  element={<HRInterviewRound />}
                />
              ) : (
                <Route path="/hrinterviewround" element={<NotFound />} />
              )}

              {privileges.includes("HR Interview Review") ? (
                <Route
                  path="/hrinterviewreview"
                  element={<HRInterviewReview />}
                />
              ) : (
                <Route path="/hrinterviewreview" element={<NotFound />} />
              )}

              {privileges.includes("Telephonic Interview") ? (
                <Route
                  path="/telephonicinterviewround"
                  element={<TelephonicRound />}
                />
              ) : (
                <Route
                  path="/telephonicinterviewround"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("Telephonic Interview Review") ? (
                <Route
                  path="/telephonicinterviewreview"
                  element={<TelephonicInterviewReview />}
                />
              ) : (
                <Route
                  path="/telephonicinterviewreview"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("Smart Hire Form") ? (
                <Route path="/smarthireform" element={<SmartHireForm />} />
              ) : (
                <Route path="/smarthireform" element={<NotFound />} />
              )}

              {privileges.includes("Smart Hire Form Review") ? (
                <Route
                  path="/smarthireformreview"
                  element={<SmartHireFormReview />}
                />
              ) : (
                <Route path="/smarthireformreview" element={<NotFound />} />
              )}

              {privileges.includes("Background Verification one") ? (
                <Route path="/backgroundverification1" element={<BGV1 />} />
              ) : (
                <Route path="/backgroundverification1" element={<NotFound />} />
              )}

              {privileges.includes("Background Verification two") ? (
                <Route path="/backgroundverification2" element={<BGV2 />} />
              ) : (
                <Route path="/backgroundverification2" element={<NotFound />} />
              )}

              {privileges.includes("Background Verification three") ? (
                <Route path="/backgroundverification3" element={<BGV3 />} />
              ) : (
                <Route path="/backgroundverification3" element={<NotFound />} />
              )}

              {privileges.includes("Background Verification one Review") ? (
                <Route
                  path="/backgroundverification1review"
                  element={<BGV1Review />}
                />
              ) : (
                <Route
                  path="/backgroundverification1review"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("Background Verification two Review") ? (
                <Route
                  path="/backgroundverification2review"
                  element={<BGV2Review />}
                />
              ) : (
                <Route
                  path="/backgroundverification2review"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("Background Verification three Review") ? (
                <Route
                  path="/backgroundverification3review"
                  element={<BGV3Review />}
                />
              ) : (
                <Route
                  path="/backgroundverification3review"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("KYC") ? (
                <Route path="/kyc" element={<KYC />} />
              ) : (
                <Route path="/KYC" element={<KYC />} />
              )}

              {privileges.includes("workflow_form_candidate_details") ? (
                <Route
                  path="/formcandidatedetails"
                  element={<StepperCandidateDetails />}
                />
              ) : (
                <Route path="/formcandidatedetails" element={<NotFound />} />
              )}
              {/* Workflow Forms Ends */}

              {/* Setting Tab Start */}
              {privileges.includes("privilege_retrieve") ? (
                <Route path="/privilege" element={<Privilege />} />
              ) : (
                <Route path="/privilege" element={<NotFound />} />
              )}

              {privileges.includes("privilege_update") ? (
                <Route path="/editprivilege" element={<EditPrivilege />} />
              ) : (
                <Route path="/editprivilege" element={<NotFound />} />
              )}

              {privileges.includes("role_retrieve") ? (
                <Route path="/roles" element={<Roles />} />
              ) : (
                <Route path="/roles" element={<NotFound />} />
              )}

              {privileges.includes("role_create") ? (
                <Route path="/createrole" element={<RolesCreate />} />
              ) : (
                <Route path="/createrole" element={<NotFound />} />
              )}

              {privileges.includes("role_update") ? (
                <Route path="/editrole" element={<RolesEdit />} />
              ) : (
                <Route path="/editrole" element={<NotFound />} />
              )}

              {privileges.includes("type_master_retrieve") ? (
                <Route path="/typemaster" element={<TypeMaster />} />
              ) : (
                <Route path="/typemaster" element={<NotFound />} />
              )}

              {privileges.includes("type_master_create") ? (
                <Route
                  path="/createtypemaster"
                  element={<TypeMasterCreate />}
                />
              ) : (
                <Route path="/createtypemaster" element={<NotFound />} />
              )}

              {privileges.includes("type_master_update") ? (
                <Route path="/edittypemaster" element={<TypeMasterEdit />} />
              ) : (
                <Route path="/edittypemaster" element={<NotFound />} />
              )}
              {/* Setting Tab End */}

              {/* Doubt START */}
              {privileges.includes("job_opening_retrieve") ? (
                <Route
                  path="/rerequisitiondetails"
                  element={<RERequisitionDetails />}
                />
              ) : (
                <Route path="/rerequisitiondetails" element={<NotFound />} />
              )}

              {privileges.includes("Personal Details Retrieve") ? (
                <Route path="/personaldetails" element={<PersonalDetails />} />
              ) : (
                <Route path="/personaldetails" element={<NotFound />} />
              )}

              {privileges.includes("Educational Details Retrieve") ? (
                <Route
                  path="/educationdetails"
                  element={<EducationalDetails />}
                />
              ) : (
                <Route path="/educationdetails" element={<NotFound />} />
              )}

              {privileges.includes("Professional Details Retrieve") ? (
                <Route
                  path="/professionaldetails"
                  element={<ProfessionalDetails />}
                />
              ) : (
                <Route path="/professionaldetails" element={<NotFound />} />
              )}

              {privileges.includes("Additional Details Retrieve") ? (
                <Route
                  path="/additionaldetails"
                  element={<AdditionalDetails />}
                />
              ) : (
                <Route path="/additionaldetails" element={<NotFound />} />
              )}

              {privileges.includes("workflowmanagement_retrieve") ? (
                <Route path="/jobworkflowdemo" element={<JobWorkflowDemo />} />
              ) : (
                <Route path="/jobworkflowdemo" element={<NotFound />} />
              )}

              {privileges.includes("workflow_tabs_configurable_retrieve") ? (
                <Route path="/workflow" element={<WorkFlowDemo />} />
              ) : (
                <Route path="/workflow" element={<NotFound />} />
              )}

              {privileges.includes("workflow_configure_first_tab_create") ? (
                <Route path="/createfirsttab" element={<WorkFlowCreate />} />
              ) : (
                <Route path="/createfirsttab" element={<NotFound />} />
              )}

              {privileges.includes("workflow_configure_tab_update") ? (
                <Route path="/workflowedit" element={<WorkFlowEdit />} />
              ) : (
                <Route path="/workflowedit" element={<NotFound />} />
              )}

              {privileges.includes("error_resume_download") ? (
                <Route path="/resumedownload" element={<ResumeDownload />} />
              ) : (
                <Route path="/resumedownload" element={<NotFound />} />
              )}

              {privileges.includes("view_all_notifications") ? (
                <Route path="/tickets" element={<Notifications />} />
              ) : (
                <Route path="/tickets" element={<NotFound />} />
              )}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
