import axios from "axios";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constants";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { useLocation } from "react-router-dom";

const AddStepperTab = (props) => {
  console.log(props.formId);

  const { dependsOnDropdownData, getDependsOnDropdownData } =
    useContext(WorkflowContext);

  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const location = useLocation();

  const [submitLoading, setSubmitLoading] = useState(false);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const candidate_id = location.state?.candidate_id;
  console.log(candidate_id);

  const defaultDependOnWorkflow = [{ value: 0, label: "No Depend On" }];

  const combineDependOnFormWorkflow = [
    ...defaultDependOnWorkflow,
    ...dependsOnDropdownData,
  ];

  const onSubmit = (data) => {
    console.log(data);
    let dependOn = data.depends_on.map((api) => api.value);
    console.log(dependOn);
    var user = JSON.stringify({
      tab_name: props.formId.form.form_name,
      parent_id: props.formId.parent_id,
      sequence: props.formId.sequence,
      depends_on: JSON.stringify(dependOn),
      form_id: props.formId.form.id,
      workflow: props.formId?.WF,
      candidate_job_id: candidate_id,
      is_subtab: 0,
      current_id: props.formId?.id,
      level: props.formId.level,
      is_iterable: data.is_iterable ? true : false,
      created_by_id: userID,
      updated_by_id: userID,
    });
    var config = {
      method: "post",
      url: VARIABLES.url + `/api/candidateformworkflow/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getDependsOnDropdownData(candidate_id);
        // getCandidateStepperFlow(candidate_id);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
      });
  };

  // useEffect(() => {
  //   getDependsOnDropdownData(candidate_id);
  // }, []);
  return (
    <>
      <div className="nk-block-head-content">
        {submitLoading ? (
          <button className="btn text-dark" disabled>
            Loading...
          </button>
        ) : (
          <>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#dependson"
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-createuser"
            >
              <em className="icon ni ni-plus"></em>
            </button>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#dependson"
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-createuser"
            >
              <em className="icon ni ni-plus"></em>
              <span>Add {props.formId?.form?.form_name || "tab"}</span>
            </button>
          </>
        )}
      </div>
      <div
        className="modal fade"
        id="dependson"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Creating new {props.formId?.form?.form_name || "tab"}
              </h5>

              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Depends On <span className="text-danger">*</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            isMulti
                            options={combineDependOnFormWorkflow}
                            isClearable={true}
                            id="select-forms-depends_on"
                          />
                        )}
                        name="depends_on"
                        control={control}
                        rules={{ required: true }}
                      />
                      {errors.depends_on && (
                        <p className="fs-8 text-danger">
                          Depends On is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <input {...register("is_iterable")} type="checkbox" />
                    <label className="form-label ms-2">Iterable</label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="submit-button-editUserRequisition"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStepperTab;
