import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const ScheduleInterviewCreate = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  //DROPDOWN DATA
  const {
    isDropdownDataLoading,
    interviewTypeDropdownData,
    interviewModeDropdownData,
  } = useContext(TypemasterDropdownContext);
  console.log("interviewTypeDropdownData: ", interviewTypeDropdownData);
  console.log("interviewModeDropdownData: ", interviewModeDropdownData);

  const { isGenricParamsDropdownDataLoading, usersDropdownData } = useContext(
    GenericDropdownContext
  );

  const SMEUsersData = usersDropdownData?.filter((info) =>
    info.role?.find((role) =>
      role.role_name?.includes("SME") || role.role_name?.includes("Departmental Manager")
    )
  );
  console.log("SMEUsersData: ", SMEUsersData)

  const RCUsersData = usersDropdownData?.filter((info) =>
    info.role?.find((role) =>
      role.role_name?.includes("RC")
    )
  );
  console.log("RCUsersData: ", RCUsersData)

  const [selectedInterviewMode, setSelectedInterviewMode] = useState('');
  console.log("selectedInterviewMode: ", selectedInterviewMode)

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.state?.CandidateData);
  console.log(location.state?.requisition_id);

  const candidate_job_id = location.state?.CandidateData?.id;
  console.log(candidate_job_id);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const [interviewerName, setInterviewerName] = useState(null);
  console.log(interviewerName);
  const [availableSlots, setAvailableSlots] = useState([]);
  console.log(availableSlots);
  const [isAvailableSlotsLoading, setIsAvailableSlotsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    const start_date = moment(data.slot_avaliable)
      .utcOffset("+05:30")
      .format()
      .split("+");
    var user = JSON.stringify({
      candidate_job_id: candidate_job_id,
      requisition_id: location.state?.requisition_id,
      interview_mode: data.interview_mode.value,
      interviewer_name_id: data.interview_name.map((val) => val.value),
      interview_type: data.interview_type.value,
      interview_datetime: start_date[0],
      created_by_id: userID,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidateinterview/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);
    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully scheduled interview",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to schedule interview",
        });
      });
  };

  const getAvaliableSlots = async () => {
    const interview_name = interviewerName?.map((api) => api.value);
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url + `/api/available-slots/?interviewer_id=${interview_name}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    setIsAvailableSlotsLoading(true);
    try {
      const res = await axios(config);
      console.log(res.data.data);
      let response = res.data.data;
      if (response.length !== 0) {
        setAvailableSlots(res.data.data);
        setIsAvailableSlotsLoading(false);
      } else {
        setAvailableSlots(res.data.data);
        setIsAvailableSlotsLoading(true);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsAvailableSlotsLoading(true);
      } else {
        setIsAvailableSlotsLoading(false);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  useEffect(() => {
    getAvaliableSlots();
  }, [interviewerName]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Schedule candidate Interview
            </h3>
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <label className="form-label fs-4">
                    Candidate name : &nbsp;
                    {formatName(location.state?.CandidateData?.candidate_name)}
                  </label>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    {" "}
                    Interview Type <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewTypeDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isLoading={isDropdownDataLoading}
                        onChange={(selectedOption) => {
                          setSelectedInterviewMode(selectedOption?.value || '');
                          field.onChange(selectedOption);
                        }}
                        isClearable={true}
                        id="select-forms-interviewtype"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_type"
                    control={control}
                  />
                  {errors.interview_type && (
                    <span className="fs-8 text-danger">
                      Interview Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interview Mode
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewModeDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isClearable={true}
                        isLoading={isDropdownDataLoading}
                        id="select-forms-interviewmode"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_mode"
                    control={control}
                  />
                  {errors.interview_mode && (
                    <span className="fs-8 text-danger">
                      Interview Mode is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interviewer Name<span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={(selectedInterviewMode === 'HR Telephonic' ? RCUsersData : SMEUsersData)?.map((info) => ({
                          value: info.user.id,
                          label: `${info.user.first_name} ${info.user.last_name}`,
                        }))}
                        isClearable={true}
                        isLoading={isGenricParamsDropdownDataLoading}
                        isMulti
                        onChange={(e) => {
                          if (e) {
                            setInterviewerName(e);
                          } else {
                            setInterviewerName(null);
                          }
                          field.onChange(e);
                        }}
                        id="select-forms-interviewname"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_name"
                    control={control}
                  />
                  {errors.interview_name && (
                    <span className="fs-8 text-danger">
                      Interview Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    {" "}
                    Interview Date-Time <span className="text-danger"> *</span>
                  </label>
                  <ul className="row g-3">
                    {interviewerName && availableSlots.length === 0 ? (
                      <div>No Slots Available</div>
                    ) : availableSlots.length === 0 ? (
                      <div>Select Interviewer Name First</div>
                    ) : (
                      <div></div>
                    )}
                    {/* {
                      availableSlots.length === 0 && <div>Select Interviewer Name First</div>
                    } */}
                    {availableSlots.map((slot, index) => {
                      return (
                        <li className="col-sm-12 col-lg-4 col-md-4" key={index}>
                          <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full checked">
                            <input
                              type="radio"
                              id={`checkbox-form-slot_avaliable-${index}`}
                              className="custom-control-input"
                              name="slot_avaliable"
                              value={slot.slot_start_time}
                              {...register("slot_avaliable", {
                                required: true,
                              })}
                            />
                            <label
                              className="custom-control-label"
                              for={`checkbox-form-slot_avaliable-${index}`}
                            >
                              <span className="d-flex flex-column text-center">
                                <span className="lead-text mb-1 mt-3">
                                  {moment(slot.slot_start_time).format(
                                    "Do MMMM YYYY, h:mm:ss a"
                                  )}
                                </span>
                                {/* <span className="sub-text">{slot.slot_end_time}</span> */}
                              </span>
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-secondary me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createscheduleinterview"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScheduleInterviewCreate;
