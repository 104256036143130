import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { ActivityLogsContext } from "../../Context/ActivityLogsContext";
import moment from "moment/moment";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const RequisitionTimeline = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isAccess } = useContext(RefreshTokenContext);
  const [searchStage, setSearchStage] = useState("");
  console.log(searchStage);

  const {
    rawRequisitionTimeline,
    isRequisitionTimelineData,
    isRequisitionTimelineLoading,
    requisitionTimelineEntries,
    requisitionTimelineCount,
    requisitionTimelinePageCount,
    getRequisitionTimelineData,
    setRequisitionTimelineEntries,
    handleRequisitionTimelineClick,
  } = useContext(ActivityLogsContext);
  console.log("rawRequisitionTimeline: ", rawRequisitionTimeline);

  const handleEntries = (event) => {
    setRequisitionTimelineEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchStage(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRequisitionTimelineData(1, location?.state?.reqId, searchStage);
    } else {
      getRequisitionTimelineData(1, location?.state?.reqId);
      setSearchStage("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getRequisitionTimelineData(
        1,
        location?.state?.reqId,
        searchStage,
        access
      );
    } else {
      let access = secureLocalStorage.getItem("access");
      getRequisitionTimelineData(
        1,
        location?.state?.reqId,
        searchStage,
        access
      );
    }
  }, [requisitionTimelineEntries, isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Requisition Timeline - {location?.state?.JobTitle}
            </h3>
            {isRequisitionTimelineLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchStage}
                      className="form-control"
                      placeholder="Search by stage"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Stage</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Start Date</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>End Date</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Completion Time</span>
                        <br />
                        <span>(Days, HH:MM)</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Completion Status</span>
                      </div>
                    </div>
                    {rawRequisitionTimeline &&
                      rawRequisitionTimeline.length !== 0 ? (
                      rawRequisitionTimeline.map((data, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.stage || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {moment(data?.start_time).format(
                                  "Do MMMM YYYY, h:mm a"
                                ) || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.end_time
                                  ? moment(data?.end_time).format(
                                    "Do MMMM YYYY, h:mm a"
                                  )
                                  : "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.duration[0]
                                  ? data?.duration[0].days +
                                  " Days, " +
                                  data?.duration[0].hours +
                                  ":" +
                                  data?.duration[0].minutes
                                  : "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.stage_status == "Pending" ? (
                                  <span class="badge bg-warning">Pending</span>
                                ) : (
                                  <span class="badge bg-success">
                                    Completed
                                  </span>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isRequisitionTimelineData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={requisitionTimelinePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={requisitionTimelinePageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleRequisitionTimelineClick(
                        data,
                        location?.state?.reqId,
                        searchStage
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {requisitionTimelineCount === null
                          ? 0
                          : requisitionTimelineCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={requisitionTimelineEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequisitionTimeline;
