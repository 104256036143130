import React, { useContext, useEffect, useState } from "react";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import CandidatePoolResumes from "../CandidatePoolResumes/CandidatePoolResumes";
import { useLocation } from "react-router-dom";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";

const ViewSMECandidatesResume = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const {
    rawSMEResumeData,
    isSMEResumeLoading,
    getAllCandidateResume,
    getSMECandidateResume,
  } = useContext(ViewResumeContext);

  console.log("rawSMEResumeData: ", rawSMEResumeData)

  const location = useLocation();

  const [submitMessage, setSubmitMessage] = useState(null);

  const [sortedSMEResumeData, setSortedSMEResumeData] = useState([null]);
  console.log("sortedSMEResumeData:", sortedSMEResumeData)
  const [IndexOfResume, setIndexOfResume] = useState(null);

  useEffect(() => {
    console.log(rawSMEResumeData);
    const index_of_resume = rawSMEResumeData.findIndex(
      (obj) => obj.id === location.state.candidateID
    );
    setIndexOfResume(index_of_resume);
    setSortedSMEResumeData(rawSMEResumeData);
  }, [rawSMEResumeData, location.state.candidateID]);

  // useEffect(() => {
  //   if (isAccess) {
  //     let access = secureLocalStorage.getItem("access");
  //     getSMECandidateResume(access);
  //   }
  // }, [isAccess]);

  return (
    <>
      <CandidatePoolResumes
        rawResumeData={sortedSMEResumeData}
        isLoading={isSMEResumeLoading}
        getResumeData={getAllCandidateResume}
        reqID={location.state.reqID}
        from={location.state?.from}
        setSubmitMessage={setSubmitMessage}
        submitMessage={submitMessage}
        shortlisting_type={"SME"}
        resume_index={IndexOfResume}
      />
    </>
  );
};

export default ViewSMECandidatesResume;
