import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { CandidateResumeContext } from "../../Context/CandidateResumeContext";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import moment from "moment";

const SMECandidateResume = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const access = secureLocalStorage.getItem("access");
  const [searchResume, setSearchResume] = useState("");
  console.log(searchResume);
  const [sortingDate, setSortingDate] = useState("");
  const navigate = useNavigate();
  const privileges = secureLocalStorage.getItem("privileges");
  const location = useLocation();
  const {
    rawAcceptedCandidateDetails,
    getAcceptedCandidateDetails,
    candidateEntries,
    candidatePageCount,
    setCandidateEntries,
    candidateCount,
    handleAcceptCandidateClick,
    isCandidateData,
    isCandidateLoading,
  } = useContext(CandidateResumeContext);

  const { getSMECandidateResume, getAllCandidateResume } =
    useContext(ViewResumeContext);

  console.log(rawAcceptedCandidateDetails);

  const handleEntries = (event) => {
    setCandidateEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchResume(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.requisition?.id,
        searchResume,
        sortingDate
      );
    } else {
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.requisition?.id,
        "",
        sortingDate
      );
      setSearchResume("");
    }
  };

  const handleSorting = (event) => {
    setSortingDate(event);
    getAcceptedCandidateDetails(
      1,
      location.state.editpost.requisition.id,
      searchResume,
      event
    );
  };

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.requisition?.id,
        searchResume,
        "",
        access
      );
    } else {
      let access = secureLocalStorage.getItem("access");
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.requisition?.id,
        searchResume,
        "",
        access
      );
    }
  }, [candidateEntries, isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              {location.state.status === "viewallshortlisted"
                ? "Shortlisted candidate for "
                : "Candidate applied for "}
              {location.state?.editpost?.job_opening?.job_title || "-"}
            </h3>
            {isCandidateLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>

          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>

        {location.state.status !== "viewallshortlisted" && (
          <div className="d-flex align-end justify-end mt-2">
            {privileges.includes("view_shortlisted_resume") ? (
              <div className="nk-block-head-content me-2">
                <NavLink
                  to={"/schedulecandidateview"}
                  state={{
                    editpost: location.state.editpost,
                    status: "viewallshortlisted",
                    from: 'sme_approved'
                  }}
                  className="toggle btn btn-icon btn-primary d-md-none"
                  id="button-button-viewdraftedresume"
                >
                  <em className="icon ni ni-plus"></em>
                </NavLink>

                <NavLink
                  to={"/schedulecandidateview"}
                  state={{
                    editpost: location.state.editpost,
                    status: "viewallshortlisted",
                    from: 'sme_approved'
                  }}
                  className="toggle btn btn-primary d-none d-md-inline-flex"
                  id="button-button-viewdraftedresume"
                >
                  <em className="icon ni ni-eye"></em>
                  <span>Shortlisted Candidate</span>
                </NavLink>
              </div>
            ) : (
              ""
            )}

            {privileges.includes("view_saved_resume") ? (
              <div className="nk-block-head-content me-2">
                <NavLink
                  to={"/smecandidatedrafted"}
                  state={{
                    reqID: location.state.editpost.requisition.id,
                    editpost: location.state.editpost,
                    shortlisting_type: "SME",
                  }}
                  className="toggle btn btn-icon btn-primary d-md-none"
                  id="button-button-viewdraftedresume"
                >
                  <em className="icon ni ni-plus"></em>
                </NavLink>

                <NavLink
                  to={"/smecandidatedrafted"}
                  state={{
                    reqID: location.state.editpost.requisition.id,
                    editpost: location.state.editpost,
                    shortlisting_type: "SME",
                  }}
                  className="toggle btn btn-primary d-none d-md-inline-flex"
                  id="button-button-viewdraftedresume"
                >
                  <em className="icon ni ni-eye"></em>
                  <span>View saved for later resumes</span>
                </NavLink>
              </div>
            ) : (
              ""
            )}

            {privileges.includes("view_all_resume") ? (
              <div className="nk-block-head-content me-2">
                <NavLink
                  to={"/smecandidateall"}
                  state={{
                    reqID: location.state.editpost.requisition.id,
                    shortlisting_type: "SME",
                    editpost: location.state.editpost,
                    from: 'rc_approved'
                  }}
                  className="toggle btn btn-icon btn-primary d-md-none"
                  id="button-button-viewallresume"
                >
                  <em className="icon ni ni-plus"></em>
                </NavLink>

                <NavLink
                  to={"/smecandidateall"}
                  state={{
                    reqID: location.state.editpost.requisition.id,
                    shortlisting_type: "SME",
                    editpost: location.state.editpost,
                    from: 'rc_approved'
                  }}
                  className="toggle btn btn-primary d-none d-md-inline-flex"
                  id="button-button-viewallresume"
                >
                  <em className="icon ni ni-eye"></em>
                  <span>View all resumes</span>
                </NavLink>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchResume}
                      className="form-control"
                      placeholder="Search using Candidate Name and Email"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Email-ID</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Mobile no.</span>
                      </div>

                      {/* {privileges.includes("sme_candidate_resume_update") ? ( */}
                      <div className="nk-tb-col">
                        <span>Resume</span>
                      </div>
                      {/* ) : (
                        " "
                      )} */}
                      <div className="nk-tb-col">
                        <span className="d-flex align-center">
                          Shortlisted by RC on
                          <div class="card-tools me-n1 ms-1">
                            <ul class="btn-toolbar gx-1">
                              <li>
                                <div class="dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    class="btn btn-trigger btn-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                  >
                                    <em class="icon ni ni-filter-alt"></em>
                                  </a>
                                  <div class="dropdown-menu dropdown-menu-sm">
                                    <div class="dropdown-head">
                                      <a
                                        href="javascript:void(0)"
                                        class="sub-title dropdown-title"
                                        onClick={() => handleSorting("latest")}
                                      >
                                        Newest First
                                      </a>
                                    </div>
                                    <div class="dropdown-head">
                                      <a
                                        href="javascript:void(0)"
                                        class="sub-title dropdown-title"
                                        onClick={() => handleSorting("oldest")}
                                      >
                                        Oldest First
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </div>
                    </div>
                    {rawAcceptedCandidateDetails.length !== 0 ? (
                      rawAcceptedCandidateDetails.map((data, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {formatName(data?.candidate_name)}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.email || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.mobile || "-"}
                              </span>
                            </span>
                          </div>

                          {/* {privileges.includes(
                            "sme_candidate_resume_update"
                          ) ? ( */}

                          {location.state.status === "viewallshortlisted" ?
                            <div className="nk-tb-col">
                              <span className="tb-sub">
                                {data?.resume ? (
                                  <NavLink
                                    to="/viewsmecandidateresume"
                                    className="btn btn-primary"
                                    id="button-button-viewresume"
                                    href={data?.resume}
                                    onClick={() =>
                                      getSMECandidateResume(
                                        location.state.editpost.requisition.id,
                                        ""
                                      )
                                    }
                                    state={{
                                      reqID:
                                        location.state.editpost.requisition.id,
                                      candidateID: data.id,
                                      from: "selected",
                                    }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ color: "white" }}
                                    ></em>
                                  </NavLink>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </div>
                            :
                            <div className="nk-tb-col">
                              <span className="tb-sub">
                                {data?.resume ? (
                                  <NavLink
                                    to="/viewsmecandidateresume"
                                    className="btn btn-primary"
                                    id="button-button-viewresume"
                                    href={data?.resume}
                                    onClick={() =>
                                      getSMECandidateResume(
                                        location.state.editpost.requisition.id,
                                        ""
                                      )
                                    }
                                    state={{
                                      reqID:
                                        location.state.editpost.requisition.id,
                                      candidateID: data.id,
                                      from: "",
                                    }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ color: "white" }}
                                    ></em>
                                  </NavLink>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </div>
                          }
                          {/* ) : (
                            " "
                          )} */}
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {moment(data?.updated_at).format(
                                  "Do MMMM YYYY"
                                ) || "-"}
                              </span>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isCandidateData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">
                              Good job! You are all caught up.
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidatePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidatePageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleAcceptCandidateClick(
                        data,
                        location?.state?.editpost?.requisition?.id,
                        searchResume
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {candidateCount === null ? 0 : candidateCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMECandidateResume;
