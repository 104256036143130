import React, { useEffect, useState, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { ScheduleCandidateInterviewContext } from "../../Context/ScheduleCandidateInterviewContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import moment from "moment";

const HRDCandidateList = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  let location = useLocation();
  console.log(location.state);
  const navigate = useNavigate();
  const [searchCandidate, setSearchCandidate] = useState("");
  console.log(searchCandidate);
  const [sortingDate, setSortingDate] = useState("");
  console.log(sortingDate);

  const privileges = secureLocalStorage.getItem("privileges");
  let access = secureLocalStorage.getItem("access");

  const {
    rawAcceptedCandidateDetails,
    getAcceptedCandidateDetails,
    candidateEntries,
    candidatePageCount,
    setCandidateEntries,
    candidateCount,
    handleAcceptCandidateClick,
    isCandidateData,
    isCandidateLoading,
  } = useContext(ScheduleCandidateInterviewContext);
  console.log(rawAcceptedCandidateDetails);

  const handleEntries = (event) => {
    setCandidateEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchCandidate(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.id,
        searchCandidate,
        sortingDate,
        access,
        "sme_approved"
      );
    } else {
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.id,
        "",
        sortingDate,
        access,
        "sme_approved"
      );
      setSearchCandidate("");
    }
  };

  const handleSorting = (event) => {
    setSortingDate(event);
    getAcceptedCandidateDetails(
      1,
      location.state.editpost?.id,
      searchCandidate,
      event,
      access,
      "sme_approved"
    );
  };

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.id,
        searchCandidate,
        "",
        access,
        "sme_approved"
      );
    } else {
      let access = secureLocalStorage.getItem("access");
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.id,
        searchCandidate,
        "",
        access,
        "sme_approved"
      );
    }
  }, [candidateEntries, isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Candidate List for Requisition:{" "}
              {location.state?.editpost?.job_opening?.job_title}
            </h3>
            {isCandidateLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content d-flex">
            <div class="nk-block-head-content ms-3">
              <a
                id="back-button"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="back-button"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchCandidate}
                      className="form-control"
                      placeholder="Search by candidate name, email"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Email</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Mobile</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Resume</span>
                      </div>

                      {privileges.includes("HRD Approval") ? (
                        <div className="nk-tb-col">
                          <span>Approval Form</span>
                        </div>
                      ) : ""}

                      <div className="nk-tb-col">
                        <span className="d-flex align-center">
                          Shortlisted by SME on
                          <div class="card-tools me-n1 ms-1">
                            <ul class="btn-toolbar gx-1">
                              <li>
                                <div class="dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    class="btn btn-trigger btn-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                  >
                                    <em class="icon ni ni-filter-alt"></em>
                                  </a>
                                  <div class="dropdown-menu dropdown-menu-sm">
                                    <div class="dropdown-head">
                                      <a
                                        href="javascript:void(0)"
                                        class="sub-title dropdown-title"
                                        onClick={() => handleSorting("latest")}
                                      >
                                        Newest First
                                      </a>
                                    </div>
                                    <div class="dropdown-head">
                                      <a
                                        href="javascript:void(0)"
                                        class="sub-title dropdown-title"
                                        onClick={() => handleSorting("oldest")}
                                      >
                                        Oldest First
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </div>
                    </div>

                    {rawAcceptedCandidateDetails.length !== 0 ? (
                      rawAcceptedCandidateDetails.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {formatName(post?.candidate_name)}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post?.email || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.mobile || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col tb-col-md">
                            {post?.resume ? (
                              <a
                                className="btn btn-primary"
                                id="button-button-viewresume"
                                href={post?.resume || "-"}
                                rel="noreferrer"
                              >
                                <em
                                  className="icon ni ni-file-docs"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>

                          {privileges.includes("HRD Approval") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/hrdapprovalform"}
                                state={{
                                  candidate_id: post.id,
                                  requisition_id: location?.state?.editpost?.id,
                                  job_opening_id:
                                    location?.state?.editpost?.job_opening?.id,
                                  workflowState: "HRD_tab",
                                }}
                                className="btn btn-primary"
                                id="button-button-mdcandidatelist"
                              >
                                <em className="icon ni ni-eye"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {moment(post?.updated_at).format(
                                  "Do MMMM YYYY"
                                ) || "-"}
                              </span>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isCandidateData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidatePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidatePageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleAcceptCandidateClick(
                        data,
                        location?.state?.editpost?.id,
                        searchCandidate,
                        "",
                        access,
                        "sme_approved"
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {candidateCount === null ? 0 : candidateCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRDCandidateList;
