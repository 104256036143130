import axios from "axios";
import React, { useState, createContext, useContext } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const ViewResumeContext = createContext(null);

function ViewResumeProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const [isAllResumeLoading, setIsAllResumeLoading] = useState(false);
  const [rawAllResumeData, setRawAllResumeData] = useState([]);
  console.log(rawAllResumeData);

  //show resumes to RC
  const [rawRCResumeData, setRawRCResumeData] = useState([]);
  console.log(rawRCResumeData);
  const [isRCResumeLoading, setIsRCResumeLoading] = useState(false);

  //show resumes to SME
  const [rawSMEResumeData, setRawSMEResumeData] = useState([]);
  console.log(rawSMEResumeData);
  const [isSMEResumeLoading, setIsSMEResumeLoading] = useState(false);

  const [draftedDataLoading, setDraftedDataLoading] = useState(false);
  const [draftedEntries, setDraftedEntries] = useState(5);
  const [isDraftedCandidateData, setIsDraftedCandidateData] = useState(false);
  const [rawDraftedCandidateData, setRawDraftedCandidateData] = useState([]);
  const [draftedCount, setDraftedCount] = useState(null);
  const [draftedPageCount, setDraftedPageCount] = useState(0);

  const [completeDataLoading, setCompleteDataLoading] = useState(false);
  const [completeEntries, setCompleteEntries] = useState(5);
  const [iscompleteCandidateData, setIsCompleteCandidateData] = useState(false);
  const [rawCompleteCandidateData, setRawCompleteCandidateData] = useState([]);
  const [completeCount, setCompleteCount] = useState(null);
  const [completePageCount, setCompletePageCount] = useState(0);

  const getDraftedResumes = async (
    page,
    requisition_id,
    searchCandidate,
    sortingDate,
    access,
    shortlisting_type
  ) => {
    let url;
    // const shortlisting_type = "rc_drafted";
    setDraftedDataLoading(true);
    if (searchCandidate) {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&shortlisted_type=${shortlisting_type}&page=${page}&event=${searchCandidate}&order_by=-updated_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&shortlisted_type=${shortlisting_type}&page=${page}&event=${searchCandidate}&order_by=updated_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&shortlisted_type=${shortlisting_type}&entries=${draftedEntries}&page=${page}&event=${searchCandidate}`;
      }
    } else {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&shortlisted_type=${shortlisting_type}&page=${page}&order_by=-updated_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&shortlisted_type=${shortlisting_type}&page=${page}&order_by=updated_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&shortlisted_type=${shortlisting_type}&entries=${draftedEntries}&page=${page}`;
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setDraftedDataLoading(true);
      const res = await axios(config);
      setDraftedDataLoading(false);

      if (res.length !== 0) {
        setIsDraftedCandidateData(true);
        setRawDraftedCandidateData(res.data.data);
      } else {
        setIsDraftedCandidateData(false);
        setRawDraftedCandidateData(res.data.data);
      }
      const total = res.data.count;
      setDraftedCount(total);
      setDraftedPageCount(Math.ceil(total / draftedEntries));
    } catch (error) {
      console.log(error);
      setDraftedDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getAllResumes = async (
    page,
    requisition_id,
    searchCandidate,
    sortingDate,
    access
  ) => {
    let url;
    setCompleteDataLoading(true);
    if (searchCandidate) {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&page=${page}&event=${searchCandidate}&order_by=-updated_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&page=${page}&event=${searchCandidate}&order_by=updated_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&entries=${completeEntries}&page=${page}&event=${searchCandidate}`;
      }
    } else {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&page=${page}&order_by=-updated_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&page=${page}&order_by=updated_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${requisition_id}&entries=${completeEntries}&page=${page}`;
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setCompleteDataLoading(true);
      const res = await axios(config);
      setCompleteDataLoading(false);

      if (res.length !== 0) {
        setIsCompleteCandidateData(false);
        setRawCompleteCandidateData(res.data.data);
      } else {
        setIsCompleteCandidateData(true);
        setRawCompleteCandidateData(res.data.data);
      }
      const total = res.data.count;
      setCompleteCount(total);
      setCompletePageCount(Math.ceil(total / completeEntries));
    } catch (error) {
      console.log(error);
      setCompleteDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getAllCandidateResume = async (
    requisition_id,
    access,
    resume_status,
    type
  ) => {
    let url;

    if (resume_status == "drafted") {
      const shortlisting_type = type.toLowerCase() + "_drafted";
      url =
        VARIABLES.url +
        `/api/candidateresume/?requisition_id=${requisition_id}&shortlisted_type=${shortlisting_type}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateresume/?requisition_id=${requisition_id}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setIsAllResumeLoading(true);
      const res = await axios(config);
      setRawAllResumeData(res.data.data);
      setIsAllResumeLoading(false);
    } catch (error) {
      console.log(error);
      setIsAllResumeLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getRCCandidateResume = async (requisition_id, access) => {
    const url =
      VARIABLES.url +
      `/api/candidateresume/?shortlisted_type=not_shortlisted&requisition_id=${requisition_id}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setIsRCResumeLoading(true);
      const res = await axios(config);
      console.log(res);
      setIsRCResumeLoading(false);
      setRawRCResumeData(res.data.data);
    } catch (error) {
      console.log(error);
      setIsRCResumeLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getSMECandidateResume = async (requisition_id, type, shortlisting_type, access) => {
    let url;
    if (type === 'draft') {
      url =
        VARIABLES.url +
        `/api/candidateresume/?requisition_id=${requisition_id}&shortlisted_type=${shortlisting_type}`;

    } else {
      url =
        VARIABLES.url +
        `/api/candidateresume/?shortlisted_type=rc_approved&requisition_id=${requisition_id}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setIsSMEResumeLoading(true);
      const res = await axios(config);
      setRawSMEResumeData(res.data.data);
      setIsSMEResumeLoading(false);
    } catch (error) {
      console.log(error);
      setIsSMEResumeLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const handleDraftedClick = async (data, reqId, searchCandidate) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getDraftedResumes(
      currentPage,
      reqId,
      searchCandidate
    );
  };

  const handleCompleteClick = async (data, reqId, searchCandidate) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAllResumes(
      currentPage,
      reqId,
      searchCandidate
    );
  };

  return (
    <div>
      <ViewResumeContext.Provider
        value={{
          getAllCandidateResume,
          isAllResumeLoading,
          rawAllResumeData,

          getRCCandidateResume,
          rawRCResumeData,
          isRCResumeLoading,

          getSMECandidateResume,
          rawSMEResumeData,
          isSMEResumeLoading,

          getDraftedResumes,
          draftedDataLoading,
          setDraftedEntries,
          draftedEntries,
          isDraftedCandidateData,
          rawDraftedCandidateData,
          draftedCount,
          draftedPageCount,
          handleDraftedClick,

          getAllResumes,
          completeDataLoading,
          setCompleteEntries,
          completeEntries,
          iscompleteCandidateData,
          rawCompleteCandidateData,
          completeCount,
          completePageCount,
          handleCompleteClick,
        }}
      >
        {children}
      </ViewResumeContext.Provider>
    </div>
  );
}

export default ViewResumeProvider;
