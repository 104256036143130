import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const TagContext = createContext(null);

function TagProvider({ children }) {
  // To store Tag Data.
  const [isTagData, setIsTagData] = useState(false);
  const [rawTag, setRawTag] = useState([]);
  const [tagEntries, setTagEntries] = useState(5)
  const [tagCount, setTagCount] = useState(null)
  const [tagPageCount, setTagPageCount] = useState(0)
  const [isTagLoading, setIsTagLoading] = useState(false);

  // To store TagCategory Data.
  const [isTagCategoryData, setIsTagCategoryData] = useState(false);
  const [rawTagCategory, setRawTagCategory] = useState([]);
  const [tagCategoryEntries, setTagCategoryEntries] = useState(5)
  const [tagCategoryCount, setTagCategoryCount] = useState(null)
  const [tagCategoryPageCount, setTagCategoryPageCount] = useState(0)
  const [isTagCategoryLoading, setIsTagCategoryLoading] = useState(false);

   // "GetTag" API call to get Tag data
   const getTag = async (page, searchTag) => {
    setIsTagLoading(true);
    console.log(searchTag)
    let url;
    if (searchTag) {
      url = VARIABLES.url + `/api/tag/?entries=${tagEntries}&page=${page}&event=${searchTag}`;
    } else {
      url = VARIABLES.url + `/api/tag/?entries=${tagEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsTagLoading(false);
      if (response.length !== 0) {
        setIsTagData(false);
        setRawTag(res.data.data);
      } else {
        setIsTagData(true);
        setRawTag(res.data.data);
      }
      const total = res.data.count;
      setTagCount(total);
      setTagPageCount(Math.ceil(total / tagEntries));
    } catch (error) {
      console.log(error);
      setIsTagLoading(false);
      setIsTagData(true);
    }
  };

  const handleTagClick = async (data,searchTag) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getTag(currentPage,searchTag);
  };

  // "GetTagCategory" API call to get TagCategory data
  const getTagCategory = async (page, searchTagCategory) => {
    setIsTagCategoryLoading(true);
    console.log(searchTagCategory)
    let url;
    if (searchTagCategory) {
      url = VARIABLES.url + `/api/tagcategory/?entries=${tagCategoryEntries}&page=${page}&event=${searchTagCategory}`;
    } else {
      url = VARIABLES.url + `/api/tagcategory/?entries=${tagCategoryEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsTagCategoryLoading(false);
      if (response.length !== 0) {
        setIsTagCategoryData(false);
        setRawTagCategory(res.data.data);
      } else {
        setIsTagCategoryData(true);
        setRawTagCategory(res.data.data);
      }
      const total = res.data.count;
      setTagCategoryCount(total);
      setTagCategoryPageCount(Math.ceil(total / tagCategoryEntries));
    } catch (error) {
      console.log(error);
      setIsTagCategoryLoading(false);
      setIsTagCategoryData(true);
    }
  };

  const handleTagCategoryClick = async (data,searchTagCategory) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getTagCategory(currentPage,searchTagCategory);
  };

  return (
    <div>
      <TagContext.Provider
        value={{
          rawTag,
          getTag,
          tagEntries,
          tagPageCount,
          setTagEntries,
          tagCount,
          handleTagClick,
          isTagData,
          isTagLoading,

          rawTagCategory,
          getTagCategory,
          tagCategoryEntries,
          tagCategoryPageCount,
          setTagCategoryEntries,
          tagCategoryCount,
          handleTagCategoryClick,
          isTagCategoryData,
          isTagCategoryLoading,
        }}
      >
        {children}
      </TagContext.Provider>
    </div>
  );
}

export default TagProvider;
