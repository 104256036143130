import React, { useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { ScheduleCandidateInterviewContext } from "../../Context/ScheduleCandidateInterviewContext";
import { useForm, useFormState } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import Swal from "sweetalert2";
import Select from "react-select";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import ViewScheduledSlots from "./ViewScheduledSlots";
// import { tamInterviewSlots } from '../WorkflowStepperForm/StepperFormJson'

const localizer = momentLocalizer(moment);

const TamCalendar = () => {
    const { RefreshToken, isAccess } = useContext(RefreshTokenContext);
    const { interviewersDropdownData, isInterviewersDropdownDataLoading, getInterviewersDropdownData, jobTitleDropdownData, isGenricDropdownDataLoading, candidateDropdownData, getCandidateDropdownData, isCandidateDropdownDataLoading } = useContext(
        GenericDropdownContext
    );
    console.log(interviewersDropdownData)

    const {
        tamInterviewSlots,
        getTamInterviewSlots,
        setInterviewerName,
        interviewerName,
        requisitionName,
        setRequisitionName,
        candidateName,
        setCandidateName,
        isInterviewSlotsLoading
    } = useContext(ScheduleCandidateInterviewContext);
    console.log(tamInterviewSlots);

    const userID = secureLocalStorage.getItem("userID");
    const access = secureLocalStorage.getItem("access");
    const location = useLocation();
    const privileges = secureLocalStorage.getItem("privileges");
    console.log(location);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm();
    const { isValid } = useFormState({ control });

    const [submitMessage, setSubmitMessage] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [selectedStartDate, setSelectedStartDate] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [isScheduleData, setIsScheduleData] = useState(null);
    console.log("isScheduleData: ", isScheduleData)

    const interviewerOptionCondition = () => {
        return requisitionName?.length > 0
            ? "No interviewers under the selected requisition"
            : "Please select requisition first";
    };

    const candidateOptionCondition = () => {
        return requisitionName?.length > 0
            ? "No candidates under the selected interviewer"
            : "Please select interviewer first";
    };

    const handleClosed = () => {
        setShowModal(false);
        reset();
    };

    const handleSelectSlot = (slotInfo) => {
        console.log(slotInfo);
        setIsScheduleData(null);
        setShowModal(true);
        setSelectedStartDate(slotInfo.start);
    };

    const handleSelectedEvent = (event) => {
        console.log(event)
        setShowModal(true);
        console.log(event);
        setIsScheduleData(event);
        var defaultValues = {};
        defaultValues.start_at = moment(event.slot_start_time).format(
            "YYYY-MM-DDThh:mm"
        );
        reset(defaultValues);
    };

    const handleSelectedRequisition = (selectedOption) => {
        setRequisitionName(selectedOption ? selectedOption : null);
    }

    const handleInterviewerName = (selectedOption) => {
        setInterviewerName(selectedOption ? selectedOption : null);
    };

    const handleCandidateName = (selectedOption) => {
        setCandidateName(selectedOption ? selectedOption : null);
    };

    const [event, setEvent] = useState([]);
    console.log(event);

    // Predefined list of colors
    const colours = [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#F1C40F",
        "#8E44AD",
        "#1ABC9C",
        "#E74C3C",
        "#2ECC71",
        "#3498DB",
        "#9B59B6",
        "#E67E22",
        "#D35400",
        "#2980B9",
        "#16A085",
        "#27AE60",
        "#F39C12",
        "#BDC3C7",
        "#7F8C8D",
        "#C0392B",
        "#95A5A6",
    ];

    // Function to get a color based on the interviewer's ID
    const getColor = (interviewerId) => {
        // Use modulo to ensure the index is within the range of colors array
        return colours[interviewerId % colours.length];
    };

    console.log(getColor(3))

    useEffect(() => {
        const processedEvents = tamInterviewSlots.flatMap((interviewer) => {
            const color = getColor(interviewer.interviewer.id);
            return interviewer.interviewer.slots.map((slot) => ({
                title: "Booked Slot",
                data: interviewer.interviewer,
                slot: slot,
                interviewer_id: interviewer.interviewer.id,
                slot_start_time: slot.slot_start_time,
                slot_end_time: slot.slot_end_time,
                color,
            }));
        });

        setEvent(processedEvents);
    }, [tamInterviewSlots]);

    // Custom event style to apply color
    const eventStyleGetter = (event) => {
        const backgroundColor = event.color;
        const style = {
            backgroundColor,
            // borderRadius: "0px",
            // opacity: 0.8,
            // color: "white",
            border: "0",
            // display: "block",
        };
        return {
            style: style,
        };
    };

    useEffect(() => {
        if (selectedStartDate !== null) {
            console.log(selectedStartDate);
            var defaultValues = {};
            defaultValues.start_at =
                moment(selectedStartDate).format("YYYY-MM-DDThh:mm");
            reset(defaultValues);
        }
    }, [selectedStartDate]);

    useEffect(() => {
        if (isAccess) {
            let access = secureLocalStorage.getItem("access");
            getTamInterviewSlots(access);
        } else {
            let access = secureLocalStorage.getItem("access");
            getTamInterviewSlots(access);
        }
    }, [isAccess, requisitionName, interviewerName, candidateName]);

    useEffect(() => {
        getInterviewersDropdownData(requisitionName);
    }, [requisitionName])

    useEffect(() => {
        getCandidateDropdownData(requisitionName, interviewerName);
    }, [interviewerName])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSubmitMessage(null);
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, [submitMessage]);

    return (
        <>
            <div className="nk-block-head nk-block-head-sm">
                {isInterviewSlotsLoading ? (
                    <div class="spinner-border text-primary ms-2" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                ) : (
                    ""
                )}
                <div className="nk-block-between">
                    <div className="d-flex gap-2">
                        <div className="" style={{ width: "20rem" }}>
                            <Select
                                options={jobTitleDropdownData?.map((info) => ({
                                    value: info.id,
                                    label: info.job_opening__job_title,
                                }))}
                                isMulti
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 1000,
                                    }),
                                }}
                                value={requisitionName}
                                placeholder="Select Requisition"
                                id="select-form-requisition"
                                onChange={handleSelectedRequisition}
                                isLoading={isGenricDropdownDataLoading}
                            />
                        </div>
                        <div className="" style={{ width: "20rem" }}>
                            <Select
                                options={interviewersDropdownData?.map((info) => ({
                                    value: info.id,
                                    label: `${info.first_name} ${info.last_name}`,
                                }))}
                                isMulti
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 1000,
                                    }),
                                }}
                                value={interviewerName}
                                placeholder="Select Interviewers"
                                id="select-form-users"
                                onChange={handleInterviewerName}
                                isLoading={isInterviewersDropdownDataLoading}
                                noOptionsMessage={interviewerOptionCondition}
                            />
                        </div>
                        <div className="" style={{ width: "20rem" }}>
                            <Select
                                options={candidateDropdownData?.map((info) => ({
                                    value: info.id,
                                    label: `${info.first_name} ${info.last_name}`,
                                }))}
                                isMulti
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 1000,
                                    }),
                                }}
                                value={candidateName}
                                placeholder="Select Candidate"
                                id="select-form-candidate"
                                onChange={handleCandidateName}
                                isLoading={isCandidateDropdownDataLoading}
                                noOptionsMessage={candidateOptionCondition}
                            />
                        </div>
                    </div>
                    <div className="nk-block-head-content d-flex">
                        <div class="nk-block-head-content ms-3">
                            <a
                                id="back-button"
                                className="btn btn-light d-none d-sm-inline-flex"
                                onClick={() => navigate(-1)}
                            >
                                <em class="icon ni ni-arrow-left"></em>
                                <span>Back</span>
                            </a>
                            <a
                                id="back-button"
                                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                                onClick={() => navigate(-1)}
                            >
                                <em class="icon ni ni-arrow-left"></em>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card"
            // style={{ zIndex: "-1" }}
            >
                <div className="card-inner">
                    <div>
                        <Calendar
                            localizer={localizer}
                            events={event}
                            startAccessor={(event) => new Date(event.slot_start_time)}
                            endAccessor={(event) => new Date(event.slot_end_time)}
                            style={{ height: "100vh" }}
                            selectable={true}
                            defaultView="day"
                            views={[
                                Views.MONTH,
                                Views.WORK_WEEK,
                                Views.WEEK,
                                Views.DAY,
                                Views.AGENDA,
                            ]}
                            // components={{
                            //   event: EventComponent, // Use custom event component
                            // }}
                            onSelectSlot={handleSelectSlot}
                            onSelectEvent={handleSelectedEvent}
                            eventPropGetter={eventStyleGetter}
                        />
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <div className="d-flex flex-wrap">
                    {tamInterviewSlots?.map((interviewer) => (
                        <div
                            key={interviewer?.interviewer?.id}
                            className="d-flex align-items-center me-3 mb-2"
                        >
                            <div
                                style={{
                                    width: "15px",
                                    height: "15px",
                                    backgroundColor: getColor(interviewer?.interviewer?.id),
                                    marginRight: "10px",
                                    borderRadius: "50%"
                                }}
                            ></div>
                            <span>{`${interviewer?.interviewer?.first_name} ${interviewer?.interviewer?.last_name}`}</span>
                        </div>
                    ))}
                </div>
            </div>

            {showModal && (
                <div
                    className="modal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    style={{
                        display: "block",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <div className="modal-dialog" role="document" style={{ maxWidth: "1000px" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title d-flex align-center" id="exampleModalLabel">
                                    <div
                                        style={{
                                            width: "15px",
                                            height: "15px",
                                            backgroundColor: isScheduleData.color,
                                            marginRight: "10px",
                                            borderRadius: "50%"
                                        }}
                                    ></div>
                                    Booked Slot
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => handleClosed()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <div className="profile-ud-list">

                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Interviewer name</span>
                                                <span className="profile-ud-value">
                                                    {isScheduleData?.data?.first_name || "-"} {" "} {isScheduleData?.data?.last_name || "-"}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Job Title</span>
                                                <span className="profile-ud-value">
                                                    {isScheduleData?.slot?.requisition?.job_opening?.job_title || "-"}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label" style={{ textWrap: "balance" }}>Interview Start Date & Time</span>
                                                <span className="profile-ud-value">
                                                    {isScheduleData?.slot_start_time || "-"}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label" style={{ textWrap: "balance" }}>Interview End Date & Time</span>
                                                <span className="profile-ud-value">
                                                    {isScheduleData?.slot_end_time || "-"}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Candidate name</span>
                                                <span className="profile-ud-value">
                                                    {isScheduleData?.slot?.slot_accepted_by?.first_name || "-"}{" "}{isScheduleData?.slot?.slot_accepted_by?.last_name || "-"}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {submitLoading ? (
                                    <button
                                        id="loading-button"
                                        className="btn text-dark"
                                        disabled
                                    >
                                        Loading...
                                    </button>
                                ) : isValid === true ? (
                                    <button
                                        type="submit"
                                        className="btn btn-lg btn-primary"
                                        id="submit-button-free_slot"
                                        data-bs-dismiss="modal"
                                    >
                                        Submit
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="btn btn-lg btn-primary"
                                        id="submit-button-free_slot"
                                        data-bs-dismiss="modal"
                                    >
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div>
                {submitMessage && (
                    <div className="example-alerts position-fixed bottom-0 end-0 p-3">
                        <div className="gy-4">
                            <div className="example-alert">
                                <div
                                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                                >
                                    <em
                                        className={`icon ni ni-${submitMessage.icon}-circle`}
                                    ></em>
                                    <strong>{submitMessage.message}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <ViewScheduledSlots />
        </>
    );
};

export default TamCalendar;
