import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { TAMRE1RE2RE3Context } from "../../Context/TAMRE1RE2RE3Context";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Swal from "sweetalert2";

const Tam = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawTamData,
    getTamData,
    setRawTamData,
    tamEntries,
    tamPageCount,
    tamPageNo,
    setTamPageNo,
    setTamEntries,
    tamCount,
    handleTamClick,
    isTamData,
    isTamLoading,
    setIsTamLoading
  } = useContext(TAMRE1RE2RE3Context);
  const [submitMessage, setSubmitMessage] = useState(false);


  const { requisitionID } = useContext(TypemasterDropdownContext);
  console.log(requisitionID);

  const handleEntries = (event) => {
    setTamEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      // getTamData(tamPageNo, searchRequest);
      setTamPageNo(1);
      getTamData(1, searchRequest);
    } else {
      // getTamData(tamPageNo);
      // setSearchRequest("");
      setTamPageNo(1);
      getTamData(1);
      setSearchRequest("");
    }
  };

  const handleStatus = (updatedStatus, index) => {
    console.log(updatedStatus);
    console.log(index);
    setRawTamData((prevActivityStatus) => {
      const updatedPostings = [...prevActivityStatus];
      updatedPostings[index].requisition_acceptance = updatedStatus ? 1 : 3;
      return updatedPostings;
    });

    var user = JSON.stringify({
      requisition_acceptance: updatedStatus ? 1 : 3,
      updated_by_id: userID,
    });

    console.log(user);
    const url =
      VARIABLES.url + `/api/requestrequisition/${rawTamData[index]?.id}/?type=close_requisition`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };
    setIsTamLoading(true)
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (updatedStatus) {
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Requisition Opened",
          });
        } else {
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Requisition Closed",
          });
        }
        setIsTamLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setIsTamLoading(false)
        setRawTamData((prevActivityStatus) => {
          const reversePostings = [...prevActivityStatus];
          reversePostings[index].activity_status = updatedStatus ? 1 : 3;
          return reversePostings;
        });
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to change requisition status",
        });
      });
  };

  const handleStatusAlert = (isChecked, index) => {
    Swal.fire({
      text: "Are you sure to close this requisition? Once closed, it cannot be reopened.",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleStatus(isChecked, index);
      } else {
      }
    });
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getTamData(tamPageNo, searchRequest, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getTamData(tamPageNo, searchRequest, access);
    }
  }, [tamEntries, isAccess, tamPageNo]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Assess Requisitions</h3>
            {isTamLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content d-flex">
            {privileges.includes("view_calendar") ? (
              <NavLink
                to="/tamcalendar"
                className="btn btn-outline-success btn-dim me-2"
                id="button-button-calendar"
              >
                Calendar
              </NavLink>
            ) : (
              " "
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search using Requisition ID (e.g., 126), job title, department, pending since"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Pending Since (Days)</span>
                        <br />
                        <span>for assigning to RE</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Status</span>
                      </div>

                      {privileges.includes("close_requisition") ? (
                        <div className="nk-tb-col">
                          <span>Requisition Status</span>
                        </div>
                      ) : (
                        " "
                      )}

                      {privileges.includes("assign_user_to_requisition") ? (
                        <div className="nk-tb-col">
                          <span>Action</span>
                          {/* <br />
                          <span>(Assign to REs)</span> */}
                        </div>
                      ) : (
                        " "
                      )}

                      <div className="nk-tb-col">
                        <span>Details</span>
                      </div>
                    </div>

                    {rawTamData.length !== 0 ? (
                      rawTamData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name + post.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {privileges.includes("job_opening_retrieve") ? (
                                  <NavLink
                                    to={"/jobopeningdetails"}
                                    state={{ editpost: post }}
                                  >
                                    {" "}
                                    {post?.job_opening?.job_title || "-"}
                                  </NavLink>
                                ) : (
                                  <> {post?.job_opening?.job_title || "-"}</>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {/* {privileges.includes("job_opening_retrieve") ? ( */}
                              <NavLink
                                to={"/departmentrequisitions"}
                                state={{ editpost: post?.job_opening?.department?.id }}
                              >
                                {post?.job_opening?.department
                                  ?.department_display_name || "-"}
                              </NavLink>
                              {/* ) : (
                                <>
                                  {post?.job_opening?.department
                                    ?.department_display_name || "-"}
                                </>
                              )} */}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.pending_since}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.assign_status !== null ? (
                                post?.assign_status === 0 ? (
                                  <span className="badge bg-warning">
                                    Pending
                                  </span>
                                ) : post?.assign_status === 1 ? (
                                  <span className="badge bg-success">
                                    Assigned
                                  </span>
                                )
                                  : (
                                    "N/A"
                                  )
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </div>

                          {privileges.includes("close_requisition") ? (
                            <div className="nk-tb-col">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch${index}`}
                                  checked={post?.requisition_acceptance === 1}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    if (isChecked) {
                                      setSubmitMessage({
                                        type: "warning",
                                        icon: "cross",
                                        message: "Requisition cannot be reopened once closed",
                                      });
                                      return;
                                    }
                                    handleStatusAlert(isChecked, index);
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  id="button-button-status"
                                  htmlFor={`customSwitch${index}`}
                                ></label>
                              </div>
                            </div>
                          ) : ""}

                          {privileges.includes(
                            "assign_user_to_requisition"
                          ) ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/assignedrc"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-associateuseredit"
                              >
                                <em class="icon ni ni-user-alt"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          <div className="nk-tb-col">
                            <NavLink
                              to={"/requisitiondetails"}
                              state={{ editpost: post }}
                              className="btn btn-sm btn-primary"
                              id="button-button-associateuseredit"
                            >
                              <em class="icon ni ni-info"></em>
                            </NavLink>
                          </div>
                        </div>
                      ))
                    ) : isTamData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    // key={tamPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={tamPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) => handleTamClick(data, searchRequest)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={tamPageNo - 1}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {tamCount === null ? 0 : tamCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={tamEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Tam;
