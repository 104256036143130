import React from "react";

const Footer = () => {
  return (
    <>
      <div className="nk-footer">
        <div className="container-xl wide-xl">
          <div className="nk-footer-wrap">
            <div className="nk-footer-copyright">
              &copy; 2024 Fluidscapes Consultant Pvt.Ltd.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
