import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";

export const JobOpeningContext = createContext(null);

function JobOpeningProvider({ children }) {
const {RefreshToken} = useContext(RefreshTokenContext)
  // const [associateUser, setAssociateUser] = useState([]);

  // To store Job Description Data.
  const [isJobDescriptionData, setIsJobDescriptionData] = useState(false);
  const [rawJobDescription, setRawJobDescription] = useState([]);
  console.log(rawJobDescription);
  const [jobDescriptionEntries, setJobDescriptionEntries] = useState(5);
  const [jobDescriptionCount, setJobDescriptionCount] = useState(null);
  const [jobDescriptionPageCount, setJobDescriptionPageCount] = useState(0);
  const [isJobDescriptionLoading, setIsJobDescriptionLoading] = useState(false);

  // "GetJobDescription" API call to get JobDescription data
  const getJobDescriptionData = async (page, searchJobDescription,access) => {
    setIsJobDescriptionLoading(true);
    console.log(searchJobDescription);
    let url;
    if (searchJobDescription) {
      url =
        VARIABLES.url +
        `/api/jobopening/?entries=${jobDescriptionEntries}&page=${page}&event=${searchJobDescription}`;
    } else {
      url =
        VARIABLES.url +
        `/api/jobopening/?entries=${jobDescriptionEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Authorization" : `Bearer ${access}`
       }
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsJobDescriptionLoading(false);
      if (response.length !== 0) {
        setIsJobDescriptionData(false);
        setRawJobDescription(res.data.data);
      } else {
        setIsJobDescriptionData(true);
        setRawJobDescription(res.data.data);
      }
      const total = res.data.count;
      setJobDescriptionCount(total);
      setJobDescriptionPageCount(Math.ceil(total / jobDescriptionEntries));
    } catch (error) {
      console.log(error);
      setIsJobDescriptionLoading(false);
      if(error?.response?.status === 403) {
        RefreshToken()
        setIsJobDescriptionData(false);
      }else {
        setIsJobDescriptionData(true);
      }
    }
  };

  const handleJobDescriptionClick = async (data, searchJobDescription) => {
    let access = secureLocalStorage.getItem("access")
    console.log(access)
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getJobDescriptionData(
      currentPage,
      searchJobDescription,
      access
    );
  };

  // const getAssociateUserData = async () => {
  //   try {
  //     console.log("getting associate user data");
  //     const res = await axios.get(VARIABLES.url + "/api/associateuser/");
  //     setAssociateUser(res.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div>
      <JobOpeningContext.Provider
        value={{
          rawJobDescription,
          getJobDescriptionData,
          jobDescriptionEntries,
          jobDescriptionPageCount,
          setJobDescriptionEntries,
          jobDescriptionCount,
          handleJobDescriptionClick,
          isJobDescriptionData,
          isJobDescriptionLoading,

          // getAssociateUserData,
          // associateUser,
          // setAssociateUser,
        }}
      >
        {children}
      </JobOpeningContext.Provider>
    </div>
  );
}

export default JobOpeningProvider;
