import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Sidebar = () => {
  const privileges = secureLocalStorage.getItem("privileges");

  // For setting
  const [sidebarActive, setSidebarActive] = useState(false);
  const handleActive = (status) => {
    setSidebarActive(status);
  };

  // For forms
  const [sidebarForm, setSidebarForm] = useState(false);
  const handleForm = (status) => {
    setSidebarForm(status);
  };

  // For masters
  const [sidebarAssign, setSidebarAssign] = useState(false);
  const handleAssign = (status) => {
    setSidebarAssign(status);
  };

  // For workflow
  const [sidebarWorkflow, setSidebarWorkflow] = useState(false);
  const handleWorkflow = (status) => {
    setSidebarWorkflow(status);
  };

  // For P1
  const [sidebarAssignP1, setSidebarAssignP1] = useState(false);
  const handleAssignP1 = (status) => {
    setSidebarAssignP1(status);
  };

  // For P2
  const [sidebarAssignP2, setSidebarAssignP2] = useState(false);
  const handleAssignP2 = (status) => {
    setSidebarAssignP2(status);
  };

  // For P2- College outreach
  const [sidebarAssignCollege, setSidebarAssignCollege] = useState(false);
  const handleAssignCollege = (status) => {
    setSidebarAssignCollege(status);
  };

  // For P2- Digital outreach
  const [sidebarAssignDigital, setSidebarAssignDigital] = useState(false);
  const handleAssignDigital = (status) => {
    setSidebarAssignDigital(status);
  };

  // For P2- Agency outreach
  const [sidebarAssignAgency, setSidebarAssignAgency] = useState(false);
  const handleAssignAgency = (status) => {
    setSidebarAssignAgency(status);
  };

  // For P3
  const [sidebarAssignP3, setSidebarAssignP3] = useState(false);
  const handleAssignP3 = (status) => {
    setSidebarAssignP3(status);
  };

  // For P4
  const [sidebarAssignP4, setSidebarAssignP4] = useState(false);
  const handleAssignP4 = (status) => {
    setSidebarAssignP4(status);
  };

  // For assignment and interview questions
  const [sidebarAssessment, setSidebarAssessment] = useState(false);
  const handleAssessment = (status) => {
    setSidebarAssessment(status);
  };

  return (
    <>
      <div
        className="nk-sidebar is-light nk-sidebar-fixed is-light"
        data-content="sidebarMenu"
      >
        <div className="nk-sidebar-element nk-sidebar-head p-2">
          <div
            style={{
              position: "absolute",
              top: "1rem",
              zIndex: "1",
              left: "0.5rem",
              right: "0.5rem",
            }}
          >
            <div className="nk-sidebar-brand bg-blue rounded-3 w-100 p-3">
              {/* <a
              href="http://www.fluidscapes.in/"
              className="logo-link nk-sidebar-logo"
              id="logo-fluidscapes"
            >
              <img
                className="logo-light logo-img"
                src="./assets/images/RMS-Logo.png"
                srcSet="./assets/images/RMS-Logo.png 2x"
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src="./assets/images/RMS-Logo.png"
                srcSet="./assets/images/RMS-Logo.png 2x"
                alt="logo-dark"
              />
              <img
                className="logo-small logo-img logo-img-small"
                src="./assets/images/RMS-Logo.png"
                srcSet="./assets/images/RMS-Logo.png 2x"
                alt="logo-small"
              />
            </a> */}
              <img src={require("../../Resources/images/RMS-Logo.png")} />
            </div>
            <div className="nk-menu-trigger me-n2">
              <a
                href="javascript:void(0)"
                className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
                data-target="sidebarMenu"
              >
                <em className="icon ni ni-arrow-left"></em>
              </a>
            </div>
          </div>
        </div>
        {/* <!-- .nk-sidebar-element --> */}
        <div className="nk-sidebar-element" style={{ paddingTop: "3rem" }}>
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                {["requisition_retrieve"].some((privilege) =>
                  privileges.includes(privilege)
                ) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/"
                      className="nk-menu-link"
                      id="sidebar-requested-requisition"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-grid-add-c"></em>
                      </span>
                      <span className="nk-menu-text">My Requisition</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}

                {privileges.includes("all_requisition_retrieve") ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/allrequisition"
                      className="nk-menu-link"
                      id="sidebar-requested-requisition"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-line-chart"></em>
                      </span>
                      <span className="nk-menu-text">All Requisition</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}

                {/* P1 Tab Starts */}
                {[
                  "p1_tab",
                  "requisition_approval_retrieve",
                  "assess_requisition_retrieve",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li
                    className={`${sidebarAssignP1
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                      }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleAssignP1(!sidebarAssignP1)}
                      id="sidebar-p1"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-shield-star"></em>
                      </span>
                      <span className="nk-menu-text">P1-Request Handling</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {["requisition_approval_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/requisitionapproval"
                            className="nk-menu-link"
                            id="sidebar-requisitionapproval"
                          >
                            <span className="nk-menu-text">
                              Requisition Approval
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["assess_requisition_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/assess-requisitions"
                            className="nk-menu-link"
                            id="sidebar-tam"
                          >
                            <span className="nk-menu-text">
                              Assess Requisitions
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {/* P2 Tab Starts */}
                {[
                  "p2_tab",
                  "college_outreach_requisition_retrieve",
                  "digital_outreach_requisition_retrieve",
                  "agency_outreach_requisition_retrieve",
                  "resume_pool_advance_filter",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li
                    className={`${sidebarAssignP2
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                      }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleAssignP2(!sidebarAssignP2)}
                      id="sidebar-p2"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-shield-star"></em>
                      </span>
                      <span className="nk-menu-text">
                        P2-Outreach | Sourcing
                      </span>
                    </a>
                    <ul className="nk-menu-sub">
                      {/* College */}
                      {["college_outreach_requisition_retrieve"].some(
                        (privilege) => privileges.includes(privilege)
                      ) ? (
                        <li
                          className={`${sidebarAssignCollege
                            ? "nk-menu-item has-sub active"
                            : "nk-menu-item has-sub"
                            }`}
                        >
                          <a
                            href="javascript:void(0)"
                            className="nk-menu-link nk-menu-toggle"
                            onClick={() =>
                              handleAssignCollege(!sidebarAssignCollege)
                            }
                            id="sidebar-p2"
                          >
                            <span className="nk-menu-text">
                              College Outreach
                            </span>
                          </a>
                          <ul className="nk-menu-sub">
                            {["college_outreach_requisition_retrieve"].some(
                              (privilege) => privileges.includes(privilege)
                            ) ? (
                              <li className="nk-menu-item">
                                <NavLink
                                  to="/college-outreach"
                                  className="nk-menu-link"
                                  id="sidebar-collegeoutreach"
                                  state={{ action: "Outreach" }}
                                >
                                  <span className="nk-menu-text">Outreach</span>
                                </NavLink>
                              </li>
                            ) : (
                              " "
                            )}

                            {["college_outreach_requisition_retrieve"].some(
                              (privilege) => privileges.includes(privilege)
                            ) ? (
                              <li className="nk-menu-item">
                                <NavLink
                                  to="/college-outreach"
                                  className="nk-menu-link"
                                  id="sidebar-collegeoutreach"
                                  state={{ action: "Upload" }}
                                >
                                  <span className="nk-menu-text">
                                    Upload & Manage
                                  </span>
                                </NavLink>
                              </li>
                            ) : (
                              " "
                            )}
                          </ul>
                        </li>
                      ) : (
                        ""
                      )}

                      {/* Digital */}
                      {["digital_outreach_requisition_retrieve"].some(
                        (privilege) => privileges.includes(privilege)
                      ) ? (
                        <li
                          className={`${sidebarAssignDigital
                            ? "nk-menu-item has-sub active"
                            : "nk-menu-item has-sub"
                            }`}
                        >
                          <a
                            href="javascript:void(0)"
                            className="nk-menu-link nk-menu-toggle"
                            onClick={() =>
                              handleAssignDigital(!sidebarAssignDigital)
                            }
                            id="sidebar-p2"
                          >
                            <span className="nk-menu-text">
                              Digital Outreach
                            </span>
                          </a>
                          <ul className="nk-menu-sub">
                            {["digital_outreach_requisition_retrieve"].some(
                              (privilege) => privileges.includes(privilege)
                            ) ? (
                              <li className="nk-menu-item">
                                <NavLink
                                  to="/digital-outreach"
                                  className="nk-menu-link"
                                  id="sidebar-digitaloutreach"
                                  state={{ action: "Outreach" }}
                                >
                                  <span className="nk-menu-text">Outreach</span>
                                </NavLink>
                              </li>
                            ) : (
                              " "
                            )}

                            {["digital_outreach_requisition_retrieve"].some(
                              (privilege) => privileges.includes(privilege)
                            ) ? (
                              <li className="nk-menu-item">
                                <NavLink
                                  to="/digital-outreach"
                                  className="nk-menu-link"
                                  id="sidebar-digitaloutreach"
                                  state={{ action: "Upload" }}
                                >
                                  <span className="nk-menu-text">
                                    Upload & Manage
                                  </span>
                                </NavLink>
                              </li>
                            ) : (
                              " "
                            )}
                          </ul>
                        </li>
                      ) : (
                        ""
                      )}

                      {/* Agency */}
                      {["agency_outreach_requisition_retrieve"].some(
                        (privilege) => privileges.includes(privilege)
                      ) ? (
                        <li
                          className={`${sidebarAssignAgency
                            ? "nk-menu-item has-sub active"
                            : "nk-menu-item has-sub"
                            }`}
                        >
                          <a
                            href="javascript:void(0)"
                            className="nk-menu-link nk-menu-toggle"
                            onClick={() =>
                              handleAssignAgency(!sidebarAssignAgency)
                            }
                            id="sidebar-p2"
                          >
                            <span className="nk-menu-text">
                              Agency Outreach
                            </span>
                          </a>
                          <ul className="nk-menu-sub">
                            {["agency_outreach_requisition_retrieve"].some(
                              (privilege) => privileges.includes(privilege)
                            ) ? (
                              <li className="nk-menu-item">
                                <NavLink
                                  to="/agency-outreach"
                                  className="nk-menu-link"
                                  id="sidebar-agencyoutreach"
                                  state={{ action: "Outreach" }}
                                >
                                  <span className="nk-menu-text">Outreach</span>
                                </NavLink>
                              </li>
                            ) : (
                              " "
                            )}

                            {["agency_outreach_requisition_retrieve"].some(
                              (privilege) => privileges.includes(privilege)
                            ) ? (
                              <li className="nk-menu-item">
                                <NavLink
                                  to="/agency-outreach"
                                  className="nk-menu-link"
                                  id="sidebar-agencyoutreach"
                                  state={{ action: "Upload" }}
                                >
                                  <span className="nk-menu-text">
                                    Upload & Manage
                                  </span>
                                </NavLink>
                              </li>
                            ) : (
                              " "
                            )}
                          </ul>
                        </li>
                      ) : (
                        ""
                      )}

                      {["seo_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/seo"
                            className="nk-menu-link"
                            id="sidebar-seo"
                          >
                            <span className="nk-menu-text">SEO</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["resume_pool_advance_filter"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/resumepooladvancefilter"
                            className="nk-menu-link"
                            id="sidebar-resume_pool_advance_filter"
                          >
                            <span className="nk-menu-text">Talent Pool</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {/* P3 Tab Starts */}
                {[
                  "p3_tab",
                  "pre_screening_requisition_retrieve",
                  "shortlisting_requisition_retrieve",
                  "interview_scheduling_requisition_retrieve",
                  "track_requisition_retrieve",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li
                    className={`${sidebarAssignP3
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                      }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleAssignP3(!sidebarAssignP3)}
                      id="sidebar-p3"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-shield-star"></em>
                      </span>
                      <span className="nk-menu-text">P3-Screening</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {["pre_screening_requisition_retrieve"].some(
                        (privilege) => privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/pre-screening"
                            className="nk-menu-link"
                            id="sidebar-recruitment_cordinator"
                          >
                            <span className="nk-menu-text">Pre-screening</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["shortlisting_requisition_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/shortlisting"
                            className="nk-menu-link"
                            id="sidebar-shortlisting"
                          >
                            <span className="nk-menu-text">Shortlisting</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["interview_scheduling_requisition_retrieve"].some(
                        (privilege) => privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/interviewscheduling"
                            className="nk-menu-link"
                            id="sidebar-interviewscheduling"
                          >
                            <span className="nk-menu-text">
                              Interview Scheduling
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["track_requisition_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/trackrequisitions"
                            className="nk-menu-link"
                            id="sidebar-trackrequisitions"
                          >
                            <span className="nk-menu-text">
                              Track Requisitions
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {/* P4 Tab Starts */}
                {["p4_tab", "hrd_requisition_list", "md_requisition_list"].some(
                  (privilege) => privileges.includes(privilege)
                ) ? (
                  <li
                    className={`${sidebarAssignP4
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                      }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleAssignP4(!sidebarAssignP4)}
                      id="sidebar-p1"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-shield-star"></em>
                      </span>
                      <span className="nk-menu-text">P4-Closure</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {privileges.includes("hrd_requisition_list") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/hrd-approval"
                            className="nk-menu-link"
                            id="sidebar-hrd-approval"
                          >
                            <span className="nk-menu-text">HR-D Approval</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("md_requisition_list") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/md-approval"
                            className="nk-menu-link"
                            id="sidebar-md-approval"
                          >
                            <span className="nk-menu-text">MD Approval</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {/* {["seo_retrieve"].some((privilege) =>
                  privileges.includes(privilege)
                ) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/seo"
                      className="nk-menu-link"
                      id="sidebar-seo"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-trend-up"></em>
                      </span>
                      <span className="nk-menu-text">SEO</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )} */}

                {/* {privileges.includes("hrd_eagle_eye_view") ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/hrdapproval"
                      className="nk-menu-link"
                      id="sidebar-hrd_eagle_view"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-activity"></em>
                      </span>
                      <span className="nk-menu-text">HRD Approval</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )} */}

                {/* To-Do: privilege */}
                {privileges.includes("candidate_extended_with_offers") ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/candidateacceptstatus"
                      className="nk-menu-link"
                      id="sidebar-referralresume"
                    >
                      <span className="nk-menu-icon">
                        <em class="icon ni ni-user-round"></em>
                      </span>
                      <span className="nk-menu-text">Candidates extended with offers</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {privileges.includes("refer_candidate_retrieve") ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/referralresume"
                      className="nk-menu-link"
                      id="sidebar-referralresume"
                    >
                      <span className="nk-menu-icon">
                        <em class="icon ni ni-copy-fill"></em>
                      </span>
                      <span className="nk-menu-text">Refer Candidate</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {privileges.includes("activity_logs") ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/activitylogs"
                      className="nk-menu-link"
                      id="sidebar-activity_logs"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-user"></em>
                      </span>
                      <span className="nk-menu-text">Activity Logs</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {/* Masters Tab Starts */}
                {privileges.includes("masters") ? (
                  <li
                    className={`${sidebarAssign
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                      }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleAssign(!sidebarAssign)}
                      id="sidebar-master"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-shield-star"></em>
                      </span>
                      <span className="nk-menu-text">Masters</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {["usermanagement_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/usermanagement"
                            className="nk-menu-link"
                            id="sidebar-usermanagement"
                          >
                            <span className="nk-menu-text">
                              User Management
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {/* {[
                        "workflowmanagement_retrieve",
                        "workflowmanagement_create",
                        "workflowmanagement_update",
                        "workflow_tabs_configurable_retrieve",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li
                          className={`${
                            sidebarWorkflow
                              ? "nk-menu-item has-sub active"
                              : "nk-menu-item has-sub"
                          }`}
                        >
                          <a
                            href="javascript:void(0)"
                            className="nk-menu-link nk-menu-toggle"
                            d="sidebar-jobworkflowdemo"
                            onClick={() => handleWorkflow(!sidebarWorkflow)}
                          >
                            <span className="nk-menu-text">
                              Workflow Management
                            </span>
                          </a>
                          <ul className="nk-menu-sub">
                            <li className="nk-menu-item">
                              <NavLink
                                to="/jobworkflowdemo"
                                state={{ categoryName: "Candidate" }}
                                className="nk-menu-link"
                                id="sidebar-jobworkflowdemo-candidate"
                              >
                                <span className="nk-menu-text">Candidate</span>
                              </NavLink>
                            </li>
                            <li className="nk-menu-item">
                              <NavLink
                                to="/jobworkflowdemo"
                                state={{ categoryName: "network" }}
                                className="nk-menu-link"
                                id="sidebar-jobworkflowdemo-network"
                              >
                                <span className="nk-menu-text">Network</span>
                              </NavLink>
                            </li>
                            <li className="nk-menu-item">
                              <NavLink
                                to="/jobworkflowdemo"
                                state={{ categoryName: "Developer" }}
                                className="nk-menu-link"
                                id="sidebar-jobworkflowdemo-developer"
                              >
                                <span className="nk-menu-text">Developer</span>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      ) : (
                        " "
                      )} */}

                      {[
                        "assessment",
                        "interview_questionnaire_retrieve",
                        "job_assignment_retrieve",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li
                          className={`${sidebarAssessment
                            ? "nk-menu-item has-sub active"
                            : "nk-menu-item has-sub"
                            }`}
                        >
                          <a
                            href="javascript:void(0)"
                            className="nk-menu-link nk-menu-toggle"
                            d="sidebar-jobworkflowdemo"
                            onClick={() => handleAssessment(!sidebarAssessment)}
                          >
                            <span className="nk-menu-text">Assessment</span>
                          </a>
                          <ul className="nk-menu-sub">
                            {["interview_questionnaire_retrieve"].some(
                              (privilege) => privileges.includes(privilege)
                            ) ? (
                              <li className="nk-menu-item">
                                <NavLink
                                  to="/interviewquestionnaire"
                                  className="nk-menu-link pe-0"
                                  id="sidebar-interviewquestionnaire"
                                >
                                  <span className="nk-menu-text">
                                    Interview Questionnaire
                                  </span>
                                </NavLink>
                              </li>
                            ) : (
                              " "
                            )}

                            {["job_assignment_retrieve"].some((privilege) =>
                              privileges.includes(privilege)
                            ) ? (
                              <li className="nk-menu-item">
                                <NavLink
                                  to="/jobassignment"
                                  className="nk-menu-link"
                                  id="sidebar-jobassignment"
                                >
                                  <span className="nk-menu-text">
                                    Job Assignment
                                  </span>
                                </NavLink>
                              </li>
                            ) : (
                              " "
                            )}
                          </ul>
                        </li>
                      ) : (
                        " "
                      )}

                      {["job_location_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/joblocation"
                            className="nk-menu-link"
                            id="sidebar-joblocation"
                          >
                            <span className="nk-menu-text">Job Location</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["bgv_agency_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/joblocation"
                            className="nk-menu-link"
                            id="sidebar-joblocation"
                            state={{ status: "bgv" }}
                          >
                            <span className="nk-menu-text">BGV Agencies</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["job_opening_retrieve"].every((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/jobdescription"
                            className="nk-menu-link"
                            id="sidebar-jobdescription"
                          >
                            <span className="nk-menu-text">
                              Job Description
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["seniority_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/seniority"
                            className="nk-menu-link"
                            id="sidebar-seniority"
                          >
                            <span className="nk-menu-text">Seniority</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["department_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/department"
                            className="nk-menu-link"
                            id="sidebar-department"
                          >
                            <span className="nk-menu-text">Department</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["team_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/team"
                            className="nk-menu-link"
                            id="sidebar-team"
                          >
                            <span className="nk-menu-text">Team</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["position_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/position"
                            className="nk-menu-link"
                            id="sidebar-position"
                          >
                            <span className="nk-menu-text">Position</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {/* {["position_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? ( */}
                      {/* <li className="nk-menu-item">
                        <NavLink
                          to="/mastercombinedform"
                          className="nk-menu-link"
                          id="sidebar-position"
                        >
                          <span className="nk-menu-text">Combined Form</span>
                        </NavLink>
                      </li> */}
                      {/* ) : (
                        " "
                      )} */}

                      {["agency_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/agencymaster"
                            className="nk-menu-link"
                            id="sidebar-agencymaster"
                          >
                            <span className="nk-menu-text">Agency Master</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["candidate_resume_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/candidateresume"
                            className="nk-menu-link"
                            id="sidebar-resumepool"
                          >
                            <span className="nk-menu-text">Talent Pool</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {/* {privileges.includes("refer_candidate_retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/referralresume"
                            className="nk-menu-link"
                            id="sidebar-referralresume"
                          >
                            <span className="nk-menu-text">
                              Refer Candidate
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )} */}

                      {privileges.includes("notification_mapper_retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/notificationmapper"
                            className="nk-menu-link"
                            id="sidebar-notificationmapper"
                          >
                            <span className="nk-menu-text">
                              Notification Mapper
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("version_tracker_retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/versiontracker"
                            className="nk-menu-link"
                            id="sidebar-versiontracker"
                          >
                            <span className="nk-menu-text">
                              Version Tracker
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("candidate_entries_retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/candidateentries"
                            className="nk-menu-link"
                            id="sidebar-candidateentries"
                          >
                            <span className="nk-menu-text">
                              Candidate Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {/* {[
                        "tag_retrieve",
                        "tag_create",
                        "tag_update",
                        "tag_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/tag"
                            className="nk-menu-link"
                            id="sidebar-tag"
                          >
                            <span className="nk-menu-text">Tags</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )} */}
                      {/* {[
                        "tag_category_retrieve",
                        "tag_category_create",
                        "tag_category_update",
                        "tag_category_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/tagcategory"
                            className="nk-menu-link"
                            id="sidebar-tagcategory"
                          >
                            <span className="nk-menu-text">Tag Category</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )} */}
                    </ul>
                  </li>
                ) : (
                  " "
                )}

                {/* Form Workflow Tab with Assignment,Test,MD-Approval,Send Offer,Background Verification and Technical as sub tabs */}
                {privileges.includes("form_workflow_tab") ? (
                  <li
                    className={`${sidebarForm
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                      }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      id="stepper-forms"
                      onClick={() => handleForm(!sidebarForm)}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-files"></em>
                      </span>
                      <span className="nk-menu-text">Forms</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {privileges.includes("Assign Assignment") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/assignassignment"
                            className="nk-menu-link"
                            id="sidebar-assignassignment"
                          >
                            <span className="nk-menu-text">
                              Assign Assignment
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Assignment Upload") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/assignmentupload"
                            className="nk-menu-link"
                            id="sidebar-assignmentupload"
                          >
                            <span className="nk-menu-text">
                              Assignment Upload
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Assignment Review") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/assignmentreview"
                            className="nk-menu-link"
                            id="sidebar-assignmentreview"
                          >
                            <span className="nk-menu-text">
                              Assignment Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Test") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/mettletest"
                            className="nk-menu-link"
                            id="sidebar-mettletest"
                          >
                            <span className="nk-menu-text">
                              Psychometric Test
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Psychometric Test Upload") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/assignassignment"
                            className="nk-menu-link"
                            id="sidebar-assignassignment"
                          >
                            <span className="nk-menu-text">
                              Assign Assignment
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Test Review") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/mettletestreview"
                            className="nk-menu-link"
                            id="sidebar-mettletestreview"
                          >
                            <span className="nk-menu-text">
                              Psychometric Test Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("HRD Approval") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/hrdapprovalform"
                            className="nk-menu-link"
                            id="sidebar-hrdapprovalform"
                          >
                            <span className="nk-menu-text">HRD Approval</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("MD Approval") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/mdapproval"
                            className="nk-menu-link"
                            id="sidebar-mdapproval"
                          >
                            <span className="nk-menu-text">MD Approval</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Send Offer") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/sendoffer"
                            className="nk-menu-link"
                            id="sidebar-sendoffer"
                          >
                            <span className="nk-menu-text">Send Offer</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Technical Interview") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/technicalinterviewround"
                            className="nk-menu-link"
                            id="sidebar-technicalinterviewround"
                          >
                            <span className="nk-menu-text">
                              Technical Interview Round
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Technical Interview Review") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/technicalinterviewreview"
                            className="nk-menu-link"
                            id="sidebar-technicalinterviewreview"
                          >
                            <span className="nk-menu-text">
                              Technical Interview Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("HR Interview") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/hrinterviewround"
                            className="nk-menu-link"
                            id="sidebar-hrinterviewround"
                          >
                            <span className="nk-menu-text">
                              HR Interview Round
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("HR Interview Review") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/hrinterviewreview"
                            className="nk-menu-link"
                            id="sidebar-hrinterviewreview"
                          >
                            <span className="nk-menu-text">
                              HR Interview Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Telephonic Interview") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/telephonicinterviewround"
                            className="nk-menu-link"
                            id="sidebar-telephonicinterviewround"
                          >
                            <span className="nk-menu-text">
                              Telephonic Interview Round
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Telephonic Interview Review") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/telephonicinterviewreview"
                            className="nk-menu-link"
                            id="sidebar-telephonicinterviewreview"
                          >
                            <span className="nk-menu-text">
                              Telephonic Interview Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Smart Hire Form") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/smarthireform"
                            className="nk-menu-link"
                            id="sidebar-smarthireform"
                          >
                            <span className="nk-menu-text">
                              Smart Hire Form
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Smart Hire Form Review") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/smarthireformreview"
                            className="nk-menu-link"
                            id="sidebar-smarthireformreview"
                          >
                            <span className="nk-menu-text">
                              Smart Hire Form Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Background Verification one") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/backgroundverification1"
                            className="nk-menu-link"
                            id="sidebar-background_verfification1"
                          >
                            <span className="nk-menu-text">
                              Background Verification 1
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes(
                        "Background Verification one Review"
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/backgroundverification1review"
                            className="nk-menu-link"
                            id="sidebar-background_verfification1_review"
                          >
                            <span className="nk-menu-text">
                              Background Verification 1 Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Background Verification two") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/backgroundverification2"
                            className="nk-menu-link"
                            id="sidebar-background_verfification1"
                          >
                            <span className="nk-menu-text">
                              Background Verification 2
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes(
                        "Background Verification two Review"
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/backgroundverification2review"
                            className="nk-menu-link"
                            id="sidebar-background_verfification2_review"
                          >
                            <span className="nk-menu-text">
                              Background Verification 2 Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Background Verification three") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/backgroundverification3"
                            className="nk-menu-link"
                            id="sidebar-background_verfification1"
                          >
                            <span className="nk-menu-text">
                              Background Verification 3
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes(
                        "Background Verification three Review"
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/backgroundverification3review"
                            className="nk-menu-link"
                            id="sidebar-background_verfification3_review"
                          >
                            <span className="nk-menu-text">
                              Background Verification 3 Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("KYC") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/kyc"
                            className="nk-menu-link"
                            id="sidebar-kyc"
                          >
                            <span className="nk-menu-text">KYC</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes(
                        "workflow_form_candidate_details"
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/formcandidatedetails"
                            className="nk-menu-link"
                            id="sidebar-candidatedetails"
                          >
                            <span className="nk-menu-text">
                              Candidate Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Personal Details Retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/personaldetails"
                            className="nk-menu-link"
                            id="sidebar-personaldetails"
                          >
                            <span className="nk-menu-text">
                              Personal Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Educational Details Retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/educationdetails"
                            className="nk-menu-link"
                            id="sidebar-educationdetails"
                          >
                            <span className="nk-menu-text">
                              Educational Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Professional Details Retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/professionaldetails"
                            className="nk-menu-link"
                            id="sidebar-professionaldetails"
                          >
                            <span className="nk-menu-text">
                              Professional Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Additional Details Retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/additionaldetails"
                            className="nk-menu-link"
                            id="sidebar-additionaldetails"
                          >
                            <span className="nk-menu-text">
                              Additional Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                  </li>
                ) : (
                  " "
                )}

                {/* Setting Tab with role, privilege and typemaster as sub tabs */}
                {privileges.includes("setting_tab") ? (
                  <li
                    className={`${sidebarActive
                      ? "nk-menu-item has-sub active"
                      : "nk-menu-item has-sub"
                      }`}
                    style={{ marginBottom: "2rem" }}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleActive(!sidebarActive)}
                      id="sidebar-setting"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-setting"></em>
                      </span>
                      <span className="nk-menu-text">Settings</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {["role_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/roles"
                            className="nk-menu-link"
                            id="sidebar-role"
                          >
                            <span className="nk-menu-text">Role</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["privilege_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/privilege"
                            className="nk-menu-link"
                            id="sidebar-privilege"
                          >
                            <span className="nk-menu-text">Privilege</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["type_master_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/typemaster"
                            className="nk-menu-link"
                            id="sidebar-typemaster"
                          >
                            <span className="nk-menu-text">Type Master</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                    {/* <!-- .nk-menu-sub --> */}
                  </li>
                ) : (
                  " "
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
