import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import { WorkflowContext } from "../../Context/WorkflowContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const WorkFlowCreate = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  let location = useLocation();
  let navigate = useNavigate();
  console.log(location.state.editpost);

  const {
    formWorkflowOptions,
    getFormWorkflowData,
    getFormWorkflowDropdownData,
  } = useContext(WorkflowContext);

  const {
    isGenricDropdownDataLoading,
    getGenericDropdownData,
    formsDropdownData,
  } = useContext(GenericDropdownContext);

  const defaultParentIdWorkflow = [{ value: 0, label: "No Parent Id" }];
  const defaultDependOnWorkflow = [{ value: 0, label: "No Depend On" }];
  const combineParentIdFormWorkflow = [
    ...defaultParentIdWorkflow,
    ...formWorkflowOptions,
  ];
  const combineDependOnFormWorkflow = [
    ...defaultDependOnWorkflow,
    ...formWorkflowOptions,
  ];

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    // let dependOn = data.depends_on.map((api) => api.value);
    // console.log(dependOn);
    console.log(data);
    var workflow = JSON.stringify({
      tab_name: data.tab_name,
      parent_id: 0,
      sequence: 0,
      depends_on: [0],
      form_id: data.form_id.value,
      workflow_id: location.state.editpost.id,
      is_iterable: data.is_iterable ? true : false,
      is_subtab: 0,
      current_id: 0,
      level: 0,
      created_by_id: userID,
      updated_by_id: userID,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/formworkflow/",
      headers: {
        "Content-Type": "application/json",
      },
      data: workflow,
    };

    console.log(workflow);
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        getFormWorkflowData();
        getFormWorkflowDropdownData();
        getGenericDropdownData();
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Workflow created successfully",
        });
        setTimeout(() => {
          navigate("/workflow", {
            state: { editpost: location.state.editpost },
          });
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to create workflow",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">
              Workflow name: {location.state.editpost?.workflow_name}
            </h4>
          </div>
        </div>
      </div>
      <div className="row g-gs">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-inner">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Tab Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="text-forms-tab_name"
                        placeholder="Enter Tab Name"
                        {...register("tab_name", { required: true })}
                      />
                      {errors.tab_name && (
                        <p className="fs-8 text-danger">Tab Name is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Assessment</label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={formsDropdownData?.map((info) => ({
                              value: info.id,
                              label: info.form_name,
                            }))}
                            isClearable={true}
                            isLoading={isGenricDropdownDataLoading}
                            id="select-forms-form_id"
                          />
                        )}
                        defaultValue=""
                        name="form_id"
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <input
                      id="checkbox-forms-iterable"
                      {...register("is_iterable")}
                      type="checkbox"
                    />
                    <label className="form-label ms-2">Iterable</label>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-lg btn-secondary me-3"
                    id="submit-button-backbutton"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  {submitLoading ? (
                    <button
                      id="button-loading"
                      className="btn text-dark"
                      disabled
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary"
                      id="submit-button-createFormWorkFlow"
                    >
                      Create
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div>
          {submitMessage && (
            <div className="example-alerts position-fixed bottom-0 end-0 p-3">
              <div className="gy-4">
                <div className="example-alert">
                  <div
                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                  >
                    <em
                      className={`icon ni ni-${submitMessage.icon}-circle`}
                    ></em>
                    <strong>{submitMessage.message}</strong>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WorkFlowCreate;
