import React, { useContext, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { Controller, useForm, useFormState } from "react-hook-form";
import { RCQuestions } from "../WorkflowStepperForm/StepperFormJson";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const RejectCandidate = (props) => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const { overallRecommendationDropdownData, isDropdownDataLoading } =
    useContext(TypemasterDropdownContext);
  console.log(overallRecommendationDropdownData);
  const { register, handleSubmit, control, reset } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.state?.editdata);

  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const handleCheckboxChange = (label) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected?.includes(label)
        ? prevSelected?.filter((item) => item !== label)
        : [...prevSelected, label]
    );
  };

  const handleEditSubmit = async (data) => {
    try {
      const access = secureLocalStorage.getItem("access");
      let user = new FormData();
      user.append("requisition_id", props.reqID);
      user.append("candidate_status", "rejected");
      user.append("reason", selectedQuestions);
      user.append(
        "overall_recommendation",
        data?.overall_recommendation?.value
          ? data?.overall_recommendation?.value
          : 0
      );
      user.append("comments", data?.comments);
      user.append("rejected_by", props?.shortlisting_type);
      user.append("updated_by_id", userID);

      const config = {
        method: "patch",
        url: `${VARIABLES.url}/api/candidateresume/${props.editdata.id}/?shortlisting_type=${props.shortlisting_type}`,
        headers: {
          Authorization: `Bearer ${access}`,
        },
        data: user,
      };

      setSubmitLoading(true);
      const response = await axios(config);
      console.log(JSON.stringify(response.data));
      setSubmitLoading(false);
      props.setSubmitMessage({
        type: "success",
        icon: "check",
        message: "Candidate rejected successfully",
      });
      document.getElementById("close-modal").click();
      if (props?.status !== "TI_HR") {
        props.setStoredStatus((prevStoredData) => ({
          ...prevStoredData,
          [props.editdata.id]: "rejected",
        }));

        const nextIndex =
          (props.currentIndex + 1) % props.rawResumeData?.length;
        if (nextIndex === 0) {
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          props.setCurrentIndex(nextIndex);
          props.moveToNextResume(nextIndex)
          // props.sliderRef.current.slickGoTo(nextIndex);
        }
      } else {
        await props.onSubmit(props.SubmissionData);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setSubmitLoading(true);
      } else {
        setSubmitLoading(false);
        props.setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to reject candidate",
        });
      }
    }
  };

  return (
    <>
      <div
        class="modal fade"
        id="rejectcandidate"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Rejecting Candidate
              </h5>
              <button
                type="button"
                class="btn-close"
                id="close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <form onSubmit={handleSubmit(handleEditSubmit)} autoComplete="off">
              <div class="modal-body">
                <div className="card-inner">
                  <div className="row g-4">
                    <div className="card-inner">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Sr.no</th>
                              <th>Question</th>
                              <th>Choose</th>
                            </tr>
                          </thead>
                          <tbody>
                            {RCQuestions.map((info, index) => (
                              <tr>
                                <td style={{ width: "5%" }}>{index + 1}</td>
                                <td>{info.label} </td>
                                <td style={{ width: "5%" }}>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    style={{
                                      width: "1.5rem",
                                      height: "1.5rem",
                                    }}
                                    value={info.label}
                                    id={`flexCheckDefault-${index}`}
                                    onChange={() =>
                                      handleCheckboxChange(info?.label)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-label">
                          Overall Recommendation
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={overallRecommendationDropdownData?.map(
                                (info) => ({
                                  value: info.value,
                                  label: info.name,
                                })
                              )}
                              isLoading={isDropdownDataLoading}
                              isClearable={true}
                              id="select-forms-overall_recommendation"
                            />
                          )}
                          defaultValue=""
                          name="overall_recommendation"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-label">Comments</label>
                        <textarea
                          style={{ height: "20px" }}
                          className="form-control"
                          id="date-forms-comments"
                          placeholder="Enter comments"
                          {...register("comments")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-rejectResume"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectCandidate;
