import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { InterviewQuestionContext } from "../../Context/InterviewQuestionMasterContext";
import Select from "react-select";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";

const InterviewQuestionnaire = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const privileges = secureLocalStorage.getItem("privileges");
  const { isDropdownDataLoading, interviewQuestionnaireTypeDropdownData } = useContext(
    TypemasterDropdownContext
  );
  const { isGenricDropdownDataLoading, jobOpeningDropdownData } = useContext(
    GenericDropdownContext
  );
  const {
    rawInterviewQuestionData,
    getInterviewQuestionnair,
    questionEntries,
    questionPageCount,
    setQuestionEntries,
    questionCount,
    handleQuestionClick,
    isQuestionData,
    isQuestionLoading,
    setSelectedJobTitle,
    setSelectedInterviewType,
    selectedJobTitle,
    selectedInterviewType,
  } = useContext(InterviewQuestionContext);
  console.log(rawInterviewQuestionData);
  console.log("selectedJobTitle :", selectedJobTitle);

  const handleEntries = (event) => {
    setQuestionEntries(event.target.value);
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getInterviewQuestionnair(1, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getInterviewQuestionnair(1, access);
    }
  }, [questionEntries, selectedJobTitle, selectedInterviewType, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Interview Questionnaire
            </h3>
            {isQuestionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("interview_questionnaire_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/createinterviewquestionnaire"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-createinterviewquestionnaire"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/createinterviewquestionnaire"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-createinterviewquestionnaire"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create Interview Questionnaire</span>
              </NavLink>
            </div>
          ) : (
            " "
          )}
        </div>
        {/* <!-- .nk-block-between --> */}
      </div>

      <div className="nk-block">
        <div className="card">
          <div className="card-inner">
            <div className="row d-flex">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="form-label"> Job Title</label>
                  <Select
                    options={jobOpeningDropdownData?.map((info) => ({
                      value: info.id,
                      label:
                        info.job_title +
                        " " +
                        info.department_name +
                        " " +
                        info.team_name,
                    }))}
                    isLoading={isGenricDropdownDataLoading}
                    isClearable={true}
                    id="select-forms-jobtitle"
                    onChange={(selectedOption) =>
                      setSelectedJobTitle(selectedOption)
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="form-label"> Interview Type</label>
                  <Select
                    options={interviewQuestionnaireTypeDropdownData?.map((info) => ({
                      value: info.value,
                      label: info.name,
                    }))}
                    isLoading={isDropdownDataLoading}
                    isClearable={true}
                    id="select-forms-interviewtype"
                    onChange={(selectedOption) =>
                      setSelectedInterviewType(selectedOption)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Interview Type</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Category</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Question</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Answer</span>
                      </div>
                      {privileges.includes("interview_questionnaire_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawInterviewQuestionData.length !== 0 ? (
                      rawInterviewQuestionData.map((post, index) => {
                        let category =
                          post?.category && post?.category.slice(2);
                        console.log(category);
                        console.log(post);
                        return (
                          <div className="nk-tb-item">
                            <div className="nk-tb-col">
                              {post?.job_opening?.length > 1 ? (
                                <span className="tb-product">
                                  <span className="title">
                                    {selectedJobTitle ? (
                                      <span>
                                        {selectedJobTitle.label + "," || "-"}{" "}
                                        {". . ."}
                                      </span>
                                    ) : (
                                      <span>
                                        {" "}
                                        {post?.job_opening[0]?.job_title +
                                          "," || "-"}{" "}
                                        {". . ."}
                                      </span>
                                    )}
                                  </span>
                                </span>
                              ) : (
                                <span className="tb-product">
                                  <span className="title">
                                    {post?.job_opening[0]?.job_title || "-"}
                                  </span>
                                </span>
                              )}
                            </div>
                            <div className="nk-tb-col">
                              {post?.interview_type?.length > 0 ? (
                                <span className="tb-sub">
                                  {post?.interview_type?.map((info) => (
                                    <span className="title">
                                      {info || "-"} <br></br>
                                    </span>
                                  ))}
                                </span>
                              ) : (
                                <span className="tb-sub">
                                  <span className="title">
                                    {post?.interview_type}
                                  </span>
                                </span>
                              )}
                            </div>
                            <div className="nk-tb-col">
                              {post?.category?.length > 0 ? (
                                <span className="tb-sub">
                                  {post?.category?.map((info) => (
                                    <span className="title">
                                      {info || "-"}
                                      <br></br>
                                    </span>
                                  ))}
                                </span>
                              ) : (
                                <span className="tb-sub">
                                  <span className="title">
                                    {category || "-"}
                                  </span>
                                </span>
                              )}
                            </div>
                            <div className="nk-tb-col">
                              <span className="tb-sub">{post.question} </span>
                            </div>
                            <div className="nk-tb-col">
                              <span className="tb-lead">{post.answer}</span>
                            </div>

                            {privileges.includes(
                              "interview_questionnaire_update"
                            ) ? (
                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/editinterviewquestionnaire"}
                                  state={{ editpost: post }}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-editinterviewquestionnair"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      })
                    ) : isQuestionData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={questionPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={questionPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) => handleQuestionClick(data)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {questionCount === null ? 0 : questionCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={questionEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewQuestionnaire;
