import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { googleLogout } from "@react-oauth/google";
import { ActivityLogsContext } from "../Context/ActivityLogsContext";

const Header = (props) => {
  const email = secureLocalStorage.getItem("email");
  const first_name = secureLocalStorage.getItem("firstName");
  const last_name = secureLocalStorage.getItem("lastName");
  const role = secureLocalStorage.getItem("role");
  console.log(role);
  const navigate = useNavigate();

  const [showAllRoles, setShowAllRoles] = useState(false);

  const { handlemenu, rawMenu, isMenuLoading } =
    useContext(ActivityLogsContext);

  // Calling logout function in app.js. State Pull up
  const handleLogout = () => {
    googleLogout();
    props.logoutHandler();
    navigate("/");
  };

  const handleShowAllRoles = () => {
    setShowAllRoles((prev) => !prev);
  };

  const handleBlur = () => {
    setShowAllRoles(false);
  };

  useEffect(() => {
    handlemenu();
  }, []);

  return (
    <>
      <div className="nk-header is-light nk-header-fixed is-light">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            <div
              className="nk-menu-trigger d-xl-none ms-n1 me-3"
              id="button-button-sidebar_open"
            >
              <a
                href="javascript:void(0)"
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="sidebarMenu"
              >
                <em className="icon ni ni-menu"></em>
              </a>
            </div>
            <div className="nk-header-brand d-xl-none bg-blue rounded-3 p-2">
              <img
                src={require("../../Resources/images/RMS-Logo.png")}
                style={{ width: "6rem", maxHeight: "5rem" }}
              />
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li class="dropdown language-dropdown d-none d-sm-block me-n1">
                  <a
                    href="#"
                    class="dropdown-toggle nk-quick-nav-icon"
                    data-bs-toggle="dropdown"
                  >
                    <div class="quick-icon">
                      <div class="icon-status icon-status-na">
                        <em class="icon ni ni-view-grid-fill"></em>
                      </div>
                    </div>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <ul
                      class="language-list"
                      style={{ maxHeight: "250px", overflowY: "auto" }}
                    >
                      {!isMenuLoading ? (
                        rawMenu.length !== 0 ? (
                          rawMenu.map((menu, i) => (
                            <li>
                              <a href={menu?.url} class="language-item" target="_blank">
                                {menu?.logo ? (
                                  <img
                                    src={menu?.logo}
                                    alt=""
                                    class="language-flag"
                                  />
                                ) : (
                                  <div className="user-avatar sm me-2">
                                    <span>
                                      {menu?.app_display_name
                                        .charAt(0)
                                        .toUpperCase()}
                                    </span>
                                  </div>
                                )}

                                <span class="language-name">
                                  {menu?.app_display_name}
                                </span>
                              </a>
                            </li>
                          ))
                        ) : (
                          <>
                            <li>
                              <p class="language-item">
                                <span class="language-name">No Data</span>
                              </p>
                            </li>
                          </>
                        )
                      ) : (
                        <>
                          <li>
                            <p class="language-item">
                              <span class="language-name">Loading...</span>
                            </p>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </li>
                <li
                  className="dropdown user-dropdown"
                  id="button-button-dropdown"
                >
                  <a
                    href="javascript:void(0)"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                    </div>
                  </a>
                  {/* <div
                    className="dropdown-menu dropdown-menu-md dropdown-menu-end"
                    onClick={(e) => e.stopPropagation()}
                  > */}
                  <div
                    className="dropdown-menu dropdown-menu-md dropdown-menu-end"
                    onClick={(e) => e.stopPropagation()}
                    onBlur={handleBlur}
                    tabIndex="0"
                  >
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div
                        className="user-card"
                        style={{ alignItems: "normal" }}
                      >
                        <div className="user-avatar">
                          <span>
                            {first_name?.charAt(0).toUpperCase() +
                              last_name?.charAt(0).toUpperCase()}
                          </span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">
                            {first_name[0].toUpperCase() +
                              first_name.slice(1) +
                              " " +
                              last_name[0].toUpperCase() +
                              last_name.slice(1)}
                          </span>
                          <span className="sub-text">{email}</span>
                          {role?.length > 1 ? (
                            <>
                              {!showAllRoles && (
                                <span className="lead-text text-info">
                                  {role[0]?.role_name}
                                  <a
                                    className="badge bg-info ms-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleShowAllRoles}
                                  >
                                    +{role?.length - 1}
                                  </a>
                                </span>
                              )}
                              {showAllRoles &&
                                role.map((info) => (
                                  <span className="lead-text text-info">
                                    {info?.role_name}
                                  </span>
                                ))}
                            </>
                          ) : (
                            <span className="lead-text text-info">
                              {role ? role[0]?.role_name : ""}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li id="button-button-signout">
                          <a href="javascript:void(0)" onClick={handleLogout}>
                            <em className="icon ni ni-signout"></em>
                            <span>Sign out</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
