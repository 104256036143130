import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SeoDetails = () => {
  let location = useLocation();
  console.log(location.state.editpost);
  const navigate = useNavigate();

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-end">
          <div class="nk-block-head-content">
            <a
              className="btn btn-light d-none d-sm-inline-flex"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                {/* <!-- .nav-tabs --> */}

                <div className="card-inner">
                  <div className="nk-block">
                    <div className="nk-block-head">
                      <h5 className="title">
                        {location.state?.editpost?.job_title || "-"}
                      </h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Department</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.department
                              ?.department_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Team</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.team
                              ?.team_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Position</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.position
                              ?.position_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Seniority</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.seniority
                              ?.seniority_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">SEO Title</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.seo_title || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            SEO URL Required
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.url_required || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">SEO Keywords</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.seo_keywords || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            SEO Meta Keys
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.seo_meta_key || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Experience
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.experience && location.state?.editpost?.experience}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Location
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.location || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Vacancy
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.vacancy && location.state?.editpost?.vacancy}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Workflow
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.workflow?.workflow_name || "-"}
                          </span>
                        </div>
                      </div>
                      {/* <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Status</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost
                              ?.requisition_acceptance !== null ? (
                              location.state.editpost.requisition_acceptance ===
                              0 ? (
                                <span className="badge bg-warning">
                                  Pending
                                </span>
                              ) : location.state.editpost
                                  .requisition_acceptance === 1 ? (
                                <span className="badge bg-success">
                                  Accepted
                                </span>
                              ) : location.state.editpost
                                  .requisition_acceptance === 2 ? (
                                <span className="badge bg-danger">
                                  Rejected
                                </span>
                              ) : location.state.editpost
                                  .requisition_acceptance === 3 ? (
                                <span className="badge bg-secondary">
                                  Closed
                                </span>
                              ) : (
                                "N/A"
                              )
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </div>
                      </div> */}
                    </div>
                    {/* <!-- .profile-ud-list --> */}
                  </div>

                  {/* <!-- .nk-block --> */}
                  <div className="nk-divider divider md"></div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Job Description</h5>
                    </div>
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                location.state?.editpost?.job_description ||
                                "-",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Job Requirement</h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                location.state?.editpost?.job_requirement ||
                                "-",
                            }}
                          />
                        </div>
                      </div>
                      {/* <!-- .bq-note-item --> */}
                    </div>
                    {/* <!-- .bq-note --> */}
                  </div>
                </div>
                {/* <!-- .card-inner --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeoDetails;
