import React, { useEffect, useState, useContext } from "react";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import { SettingContext } from "../../Context/SettingsContext";
import { NavLink } from "react-router-dom";
import moment from "moment";

const VersionTracker = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchVersionTracker, setSearchVersionTracker] = useState("");

  const privileges = secureLocalStorage.getItem("privileges");

  const {
    rawVersionTracker,
    getVersionTracker,
    versionTrackerEntries,
    versionTrackerPageCount,
    setVersionTrackerEntries,
    versionTrackerCount,
    handleVersionTrackerClick,
    isVersionTrackerData,
    isVersionTrackerLoading,
  } = useContext(SettingContext);

  const handleEntries = (event) => {
    setVersionTrackerEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchVersionTracker(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getVersionTracker(1, searchVersionTracker);
    } else {
      getVersionTracker(1);
      setSearchVersionTracker("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getVersionTracker(1, searchVersionTracker, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getVersionTracker(1, searchVersionTracker, access);
    }
  }, [versionTrackerEntries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Version Tracker</h3>
            {isVersionTrackerLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("version_tracker_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/versiontrackercreate"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-versiontrackercreate"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/versiontrackercreate"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-versiontrackercreate"
              >
                <em className="icon ni ni-plus"></em>
                <span>Add version</span>
              </NavLink>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchVersionTracker}
                      className="form-control"
                      placeholder="Search by version no, release date"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Version number</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Release date</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Release note</span>
                      </div>
                    </div>

                    {rawVersionTracker.length > 0 ? (
                      rawVersionTracker.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.version_number || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {moment(post.release_date).format(
                                "Do MMMM YYYY, h:mm a"
                              ) || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.release_note ? (
                                <a
                                  className="btn btn-primary"
                                  id="button-button-viewreleasenote"
                                  target="_blank"
                                  href={post?.release_note}
                                >
                                  <em
                                    className="icon ni ni-file-docs"
                                    style={{ color: "white" }}
                                  ></em>
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isVersionTrackerData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading.....</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={versionTrackerPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={versionTrackerPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleVersionTrackerClick(data, searchVersionTracker)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {versionTrackerCount === null ? 0 : versionTrackerCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={versionTrackerEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VersionTracker;
