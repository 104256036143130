import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { BrowserRouter } from 'react-router-dom'
import secureLocalStorage from "react-secure-storage";
import Base from "./Components/Base/Base";
import Login from "./Components/Login/Login";
import { useNavigate } from "react-router-dom";
import { RefreshTokenContext } from "./Components/Context/RefreshTokenContext";

const App = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  console.log("userID:", userID);

  const navigate = useNavigate();

  // Set values in localstorage, userData and isLoggedIn after successful login
  const loginHandler = (data) => {
    console.log(data);
    secureLocalStorage.setItem("isLoggedIn", "USER_LOGGED_IN");
    secureLocalStorage.setItem("userID", data.user.id);
    secureLocalStorage.setItem("username", data.user.username);
    secureLocalStorage.setItem("firstName", data.user.first_name);
    secureLocalStorage.setItem("lastName", data.user.last_name);
    secureLocalStorage.setItem("email", data.user.email);
    const loginType = data.login_types.map((ty) => {
      return { value: ty.id, label: ty.name };
    });
    secureLocalStorage.setItem("login_type", loginType);
    console.log(data.role);
    const role = data.role?.map((rl) => rl);
    // const role = data.role?.map((rl) => rl.role_name);
    console.log("role: ", role);
    secureLocalStorage.setItem("role", role);
    secureLocalStorage.setItem("privileges", data.privilege_list);
    secureLocalStorage.setItem("access", data.access);
    secureLocalStorage.setItem("refresh", data.refresh);
    secureLocalStorage.setItem(
      "landing_page",
      data?.role[0]?.landing_page_path
    );
    setIsLoggedIn(true);

    const userRole = role?.[0];
    console.log("userRole: ", userRole);

    if (userRole) {
      navigate(`${data.role[0].landing_page_path}`);
      // switch (userRole) {
      //   case "Superadmin":
      //     navigate("/");
      //     break;
      //   case "MD":
      //     navigate("/");
      //     break;
      //   case "Departmental Manager":
      //     navigate("/");
      //     break;
      //   case "HR Director":
      //     navigate("/allrequisition");
      //     break;
      //   case "TAM":
      //     navigate("/");
      //     break;
      //   case "RE1":
      //     navigate("/re1");
      //     break;
      //   case "RE2":
      //     navigate("/re2");
      //     break;
      //   case "RE3":
      //     navigate("/re3");
      //     break;
      //   case "RC/HR Exec. Manager":
      //     navigate("/resumepooladvancefilter");
      //     break;
      //   case "Interviewer/SME":
      //     navigate("/interviewersme");
      //     break;
      //   case "SEO":
      //     navigate("/seo");
      //     break;
      //   case "Employee":
      //     navigate("/allrequisition");
      //     break;
      //   default:
      //     console.log("Invalid role");
      //     break;
      // }
    } else {
      navigate("*");
    }
  };

  const logoutHandler = () => {
    secureLocalStorage.removeItem("RWFcandidate_job_id");
    secureLocalStorage.removeItem("isLoggedIn", "USER_LOGGED_OUT");
    secureLocalStorage.removeItem("loggedId");
    secureLocalStorage.removeItem("userID");
    secureLocalStorage.removeItem("username");
    secureLocalStorage.removeItem("firstName");
    secureLocalStorage.removeItem("lastName");
    secureLocalStorage.removeItem("email");
    secureLocalStorage.removeItem("login_type");
    secureLocalStorage.removeItem("privileges");
    secureLocalStorage.removeItem("workflowid");
    secureLocalStorage.removeItem("jobOpeningid");
    secureLocalStorage.removeItem("role");
    secureLocalStorage.removeItem("access");
    secureLocalStorage.removeItem("refresh");

    setIsLoggedIn(false);
  };

  useEffect(() => {
    const userStatus = secureLocalStorage.getItem("isLoggedIn");
    const landingPage = secureLocalStorage.getItem("landing_page");
    if (landingPage) {
      navigate(landingPage);
    }
    if (userStatus === "USER_LOGGED_IN") {
      setIsLoggedIn(true);
    } else if (userStatus === "USER_LOGGED_OUT") {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      {/* <BrowserRouter> */}
      {isLoggedIn && <Base logoutHandler={logoutHandler} />}
      {!isLoggedIn && <Login loginHandler={loginHandler} />}
      {/* </BrowserRouter> */}
    </>
  );
};

export default App;
