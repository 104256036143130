import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const SendOfferContext = createContext(null);

function SendOfferContextProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);

  const [sendOffer, setSendOffer] = useState([]);
  const [isSendOfferDataLoading, setisSendOfferDataLoading] = useState(false);

  const getSendOfferData = async (candidate_job_id, requisition_id, access) => {
    const url =
      VARIABLES.url + `/api/sendoffer/?candidate_job_id=${candidate_job_id}&requisition_id=${requisition_id}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    setisSendOfferDataLoading(true)
    try {
      const res = await axios(config);
      setSendOffer(res.data.data);
      console.log(res.data.data);
      setisSendOfferDataLoading(false)
    } catch (error) {
      console.log(error);
      setisSendOfferDataLoading(false)
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const value = {
    sendOffer,
    isSendOfferDataLoading,
    getSendOfferData,
  };
  return (
    <div>
      <SendOfferContext.Provider value={value}>
        {children}
      </SendOfferContext.Provider>
    </div>
  );
}

export default SendOfferContextProvider;
