import React, { useEffect, useState, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import Select from "react-select";

const AllRequisition = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const privileges = secureLocalStorage.getItem("privileges");
  const { requisitionID } = useContext(TypemasterDropdownContext);

  const location = useLocation();
  const navigate = useNavigate();

  const {
    setSelectedRequisitionStatus,
    selectedRequisitionStatus,
    isAllRequistionData,
    rawAllRequistionData,
    getAllRequistion,
    allRequisitionEntries,
    allRequistionPageCount,
    setAllRequistionEntries,
    allRequisitionCount,
    handleQuestionClick,
    isAllRequisitionLoading,
  } = useContext(RequestedJobOpeningContext);

  const handleEntries = (event) => {
    setAllRequistionEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getAllRequistion(1, searchRequest);
    } else {
      getAllRequistion(1);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getAllRequistion(1, searchRequest, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getAllRequistion(1, searchRequest, access);
    }
  }, [allRequisitionEntries, selectedRequisitionStatus, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">All Requisition</h3>
            {isAllRequisitionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {location.state?.showBackButton && (
            <div class="nk-block-head-content">
              <a
                id="back-button"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="back-button"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label className="form-label"> Requisition Status</label>
                    <Select
                      options={[
                        { value: "All", label: "All" },
                        { value: 1, label: "Accepted" },
                        { value: 2, label: "Rejected" },
                        { value: 0, label: "Pending" },
                        { value: 3, label: "Closed" },
                      ]}
                      // isClearable={true}
                      value={selectedRequisitionStatus}
                      id="select-forms-requisitionstatus"
                      onChange={(selectedOption) =>
                        setSelectedRequisitionStatus(selectedOption)
                      }
                    />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-12"
                  style={{ marginTop: "30px" }}
                >
                  <div className="form-control-wrap">
                    <form>
                      <div className="input-group">
                        <input
                          type="text"
                          value={searchRequest}
                          className="form-control"
                          placeholder="Search using Requisition ID (e.g., 126), job title, department, job type, vacancy, status"
                          onChange={onChangeHandler}
                          id="text-form-input-search"
                        />
                        <div className="input-group-append">
                          <button
                            type="submit"
                            className="btn btn-outline-primary btn-dim"
                            id="button-button-search"
                            onClick={(e) => onSearchHandler("search", e)}
                          >
                            Search
                          </button>
                          <button
                            className="btn btn-outline-success btn-dim"
                            id="button-button-view"
                            onClick={(e) => onSearchHandler("view", e)}
                          >
                            Clear Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Type</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Vacancy</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Status</span>
                      </div>

                      {privileges.includes("requisition_activity_logs") ? (
                        <div className="nk-tb-col">
                          <span>Requisition Timeline</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawAllRequistionData.length !== 0 ? (
                      rawAllRequistionData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {privileges.includes(
                                  "candidate_details_retrieve"
                                ) ? (
                                  <NavLink
                                    to={"/candidateappliedforreq"}
                                    id="button-button-candidateappliedforreq"
                                    state={{
                                      reqId: post?.id,
                                      JobTitle: post?.job_opening?.job_title,
                                    }}
                                  >
                                    <span>
                                      {(requisitionID &&
                                        requisitionID[0]?.name + post?.id) ||
                                        "-"}
                                    </span>
                                  </NavLink>
                                ) : (
                                  <>
                                    {" "}
                                    {(requisitionID &&
                                      requisitionID[0]?.name + post?.id) ||
                                      "-"}
                                  </>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {privileges.includes("job_opening_retrieve") ? (
                                  <NavLink
                                    to={"/jobopeningdetails"}
                                    state={{ editpost: post }}
                                  >
                                    {post?.job_opening?.job_title || "-"}
                                  </NavLink>
                                ) : (
                                  <> {post?.job_opening?.job_title || "-"}</>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {/* {privileges.includes("job_opening_retrieve") ? ( */}
                              <NavLink
                                to={"/departmentrequisitions"}
                                state={{ editpost: post?.job_opening?.department?.id }}
                              >
                                {post?.job_opening?.department
                                  ?.department_display_name || "-"}
                              </NavLink>
                              {/* ) : (
                                <>
                                  {post?.job_opening?.department
                                    ?.department_display_name || "-"}
                                </>
                              )} */}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.job_type || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.no_of_vacancy || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.requisition_acceptance !== null ? (
                                post?.requisition_acceptance === 0 ? (
                                  <span className="badge bg-warning">
                                    Pending
                                  </span>
                                ) : post?.requisition_acceptance === 1 ? (
                                  <span className="badge bg-success">
                                    Accepted
                                  </span>
                                ) : post?.requisition_acceptance === 2 ? (
                                  <span className="badge bg-danger">
                                    Rejected
                                  </span>
                                ) : post?.requisition_acceptance === 3 ? (
                                  <span className="badge bg-secondary">
                                    Closed
                                  </span>
                                ) : (
                                  "N/A"
                                )
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </div>

                          {privileges.includes("requisition_activity_logs") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/requisitiontimeline"}
                                state={{
                                  reqId: post.id,
                                  JobTitle: post?.job_opening?.job_title,
                                }}
                                className="btn btn-sm btn-primary"
                                id="button-button-requsitiontimeline"
                              >
                                <em className="icon ni ni-eye"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isAllRequistionData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={allRequistionPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={allRequistionPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleQuestionClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {allRequisitionCount === null ? 0 : allRequisitionCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={allRequisitionEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllRequisition;
