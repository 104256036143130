import React from "react";
import CandidateResume from "../CandidateResume/CandidateResume";

const ReferralResume = () => {
  return (
    <>
      <CandidateResume data="referral" />
    </>
  );
};

export default ReferralResume;
