import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const ScheduleInterviewContext = createContext(null);

function ScheduleInterviewProvider({ children }) {
  //Scheduled Interview details of candidate
  const [isInterviewData, setIsInterviewData] = useState(false);
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [interviewEntries, setInterviewEntries] = useState(5);
  const [interviewCount, setInterviewCount] = useState(null);
  const [interviewPageCount, setInterviewPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // To store Scheduled Interview details to SME.
  const [isSMEScheduledInterviewData, setIsSMEScheduledInterviewData] =
    useState(false);
  const [SMEScheduledInterviewData, setSMEScheduledInterviewData] = useState(
    []
  );
  const [SMEScheduledInterviewEntries, setSMEScheduledInterviewEntries] =
    useState(5);
  const [SMEScheduledInterviewCount, setSMEScheduledInterviewCount] =
    useState(null);
  const [SMEScheduledInterviewPageCount, setSMEScheduledInterviewPageCount] =
    useState(0);
  const [isSMEScheduledInterviewLoading, setIsSMEScheduledInterviewLoading] =
    useState(false);

  // To store Scheduled Interview details to RC.
  const [isRCScheduledInterviewData, setIsRCScheduledInterviewData] =
    useState(false);
  const [RCScheduledInterviewData, setRCScheduledInterviewData] = useState([]);
  const [RCScheduledInterviewEntries, setRCScheduledInterviewEntries] =
    useState(5);
  const [RCScheduledInterviewCount, setRCScheduledInterviewCount] =
    useState(null);
  const [RCScheduledInterviewPageCount, setRCScheduledInterviewPageCount] =
    useState(0);
  const [isRCScheduledInterviewLoading, setIsRCScheduledInterviewLoading] =
    useState(false);

  //Scheduled Interview details of candidate
  const getInterviewDetails = async (
    page,
    candidateJobID,
    searchDetails,
    requisition_id
  ) => {
    setIsLoading(true);
    let url;
    if (searchDetails) {
      url =
        VARIABLES.url +
        `/api/candidateinterview/?entries=${interviewEntries}&page=${page}&candidate_job_id=${candidateJobID}&event=${searchDetails}&requisition_id=${requisition_id}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateinterview/?entries=${interviewEntries}&page=${page}&candidate_job_id=${candidateJobID}&requisition_id=${requisition_id}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      console.log(res.data);
      setIsLoading(false);
      if (response.length !== 0) {
        setIsInterviewData(false);
        setInterviewDetails(res.data.data);
      } else {
        setIsInterviewData(true);
        setInterviewDetails(res.data.data);
      }
      const total = res.data.count;
      setInterviewCount(total);
      setInterviewPageCount(Math.ceil(total / interviewEntries));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsInterviewData(true);
    }
  };

  const handleInterviewClick = async (
    data,
    candidate_id,
    searchDetails,
    requisition_id
  ) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getInterviewDetails(
      currentPage,
      candidate_id,
      searchDetails,
      requisition_id
    );
  };

  // To store Scheduled Interview details to SME.
  const getSMEScheduledInterviewData = async (page, userID, searchInterview) => {
    setIsSMEScheduledInterviewLoading(true);
    console.log(searchInterview);
    let url;
    if (searchInterview) {
      url =
        VARIABLES.url +
        `/api/candidateinterview/?entries=${SMEScheduledInterviewEntries}&page=${page}&interviewer_id=${userID}&event=${searchInterview}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateinterview/?entries=${SMEScheduledInterviewEntries}&page=${page}&interviewer_id=${userID}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsSMEScheduledInterviewLoading(false);
      if (response.length !== 0) {
        setIsSMEScheduledInterviewData(false);
        setSMEScheduledInterviewData(res.data.data);
      } else {
        setIsSMEScheduledInterviewData(true);
        setSMEScheduledInterviewData(res.data.data);
      }
      const total = res.data.count;
      setSMEScheduledInterviewCount(total);
      setSMEScheduledInterviewPageCount(
        Math.ceil(total / SMEScheduledInterviewEntries)
      );
    } catch (error) {
      console.log(error);
      setIsSMEScheduledInterviewLoading(false);
      setIsSMEScheduledInterviewData(true);
    }
  };

  const handleSMEScheduledInterviewClick = async (data, userID, searchInterview) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getSMEScheduledInterviewData(
      currentPage,
      userID,
      searchInterview
    );
  };

  // To store Scheduled Interview details to RC.
  const getRCScheduledInterviewData = async (page, userID, searchInterview) => {
    setIsRCScheduledInterviewLoading(true);
    console.log(searchInterview);
    let url;
    if (searchInterview) {
      url =
        VARIABLES.url +
        `/api/candidateinterview/?entries=${RCScheduledInterviewEntries}&page=${page}&user_id=${userID}&event=${searchInterview}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateinterview/?entries=${RCScheduledInterviewEntries}&page=${page}&user_id=${userID}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsRCScheduledInterviewLoading(false);
      if (response.length !== 0) {
        setIsRCScheduledInterviewData(false);
        setRCScheduledInterviewData(res.data.data);
      } else {
        setIsRCScheduledInterviewData(true);
        setRCScheduledInterviewData(res.data.data);
      }
      const total = res.data.count;
      setRCScheduledInterviewCount(total);
      setRCScheduledInterviewPageCount(
        Math.ceil(total / RCScheduledInterviewEntries)
      );
    } catch (error) {
      console.log(error);
      setIsRCScheduledInterviewLoading(false);
      setIsRCScheduledInterviewData(true);
    }
  };

  const handleRCScheduledInterviewClick = async (data, userID, searchInterview) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRCScheduledInterviewData(
      currentPage,
      userID,
      searchInterview
    );
  };

  return (
    <div>
      <ScheduleInterviewContext.Provider
        value={{
          interviewDetails,
          getInterviewDetails,
          interviewEntries,
          interviewPageCount,
          setInterviewEntries,
          interviewCount,
          handleInterviewClick,
          isInterviewData,
          isLoading,

          SMEScheduledInterviewData,
          getSMEScheduledInterviewData,
          SMEScheduledInterviewEntries,
          SMEScheduledInterviewPageCount,
          setSMEScheduledInterviewEntries,
          SMEScheduledInterviewCount,
          handleSMEScheduledInterviewClick,
          isSMEScheduledInterviewData,
          isSMEScheduledInterviewLoading,

          RCScheduledInterviewData,
          getRCScheduledInterviewData,
          RCScheduledInterviewEntries,
          RCScheduledInterviewPageCount,
          setRCScheduledInterviewEntries,
          RCScheduledInterviewCount,
          handleRCScheduledInterviewClick,
          isRCScheduledInterviewData,
          isRCScheduledInterviewLoading,
        }}
      >
        {children}
      </ScheduleInterviewContext.Provider>
    </div>
  );
}

export default ScheduleInterviewProvider;
