import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm, useFormState } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { WorkflowContext } from "../../Context/WorkflowContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const JobWorkflowEdit = (props) => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isValid } = useFormState({ control });

  const { getWorkflowData } = useContext(WorkflowContext);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    var workflowname = JSON.stringify({
      workflow_name: data.workflow_name,
      workflow_category: data.workflow_category,
      updated_by_id: userID,
    });
    console.log(workflowname);

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/workflow/${props.editedData.id}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: workflowname,
    };

    setIsLoading(true);
    axios(config)
      .then((response) => {
        setIsLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Workflow successfully updated",
        });
        getWorkflowData(1, "", props?.categoryName);
        reset();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setIsLoading(true);
          RefreshToken();
        } else {
          setIsLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to update workflow",
          });
        }
      });
  };

  useEffect(() => {
    const editedworkflow = props.editedData;
    console.log(editedworkflow);
    if (editedworkflow) {
      var defaultValues = {};
      defaultValues.workflow_name = editedworkflow.workflow_name;
      reset(defaultValues);
    }
  }, [props.editedData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="edit_workflow"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => reset()}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">Workflow Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="text-form-workflow_name"
                        placeholder="Enter Workflow name"
                        {...register("workflow_name", { required: true })}
                      />
                      {errors.workflow_name && (
                        <span className="fs-8 text-danger">
                          Workflow name is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {isLoading ? (
                  <button
                    id="button-loading"
                    className="btn text-dark"
                    disabled
                  >
                    Loading...
                  </button>
                ) : isValid === true ? (
                  <button
                    type="submit"
                    className="btn btn-md btn-primary"
                    id="submit-button-editworkflow"
                    data-bs-dismiss="modal"
                  >
                    Update Workflow
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-md btn-primary"
                    id="submit-button-editworkflow"
                  >
                    Update Workflow
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobWorkflowEdit;
