import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import secureLocalStorage from "react-secure-storage";

export const TechnicalRoundContext = createContext(null);

function TechnicalRoundProvider({ children }) {
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);

  const [interviewScore, setInterviewScore] = useState([]);
  console.log(interviewScore);
  const [questionScore, setQuestionScore] = useState([]);
  console.log(questionScore);

  const getQuestionScore = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url + `/api/interviewquestionscore/?candidate_job=${candidate_job_id}`
      );
      setQuestionScore(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getInterviewScore = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url + `/api/interviewscore/?candidate_job=${candidate_job_id}`
      );
      setInterviewScore(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <TechnicalRoundContext.Provider
        value={{
          questionScore,
          interviewScore,
          getQuestionScore,
          getInterviewScore
        }}
      >
        {children}
      </TechnicalRoundContext.Provider>
    </div>
  );
}

export default TechnicalRoundProvider;
