import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { VARIABLES } from "../../../Constants";
import { MasterTableContext } from "../../../Context/MasterTableContext";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";

const CreateDepartmentModal = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const { getDepartmentData } = useContext(MasterTableContext);

  const {
    getGenericDropdownData,
    isGenricParamsDropdownDataLoading,
    usersDropdownData,
  } = useContext(GenericDropdownContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();
  let navigate = useNavigate();

  const departmentName = watch("department_name");
  const departmentDisplayName = watch("department_display_name");

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    var form_data = JSON.stringify({
      department_name: data.department_name,
      department_display_name: data?.department_display_name,
      department_incharge_id: data?.department_incharge?.value,
      created_by_id: userID,
      updated_by_id: userID,
    });

    const url = VARIABLES.url + "/api/department/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: form_data,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Department created successfully",
        });
        getGenericDropdownData();
        document.getElementById("button-button-closemodal").click();
        reset();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to create department",
          });
        }
      });
  };

  useEffect(() => {
    getDepartmentData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  const handleBlur = (e) => {
    console.log(e.target.value);
    if (departmentName === "") {
      reset({
        department_name: e.target.value,
      });
    }
    if (departmentDisplayName === "") {
      reset({
        department_display_name: e.target.value,
      });
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="createdepartmentmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Department
              </h5>
              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <div className="row g-4">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Department name
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter department name"
                        id="text-forms-department_name"
                        {...register("department_name", {
                          required: true,
                          onBlur: handleBlur,
                        })}
                      />
                      {errors.department_name && (
                        <span className="fs-8 text-danger">
                          Department name is required
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Department display name
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter department display name"
                        id="text-forms-department_display_name"
                        {...register("department_display_name", {
                          required: true,
                          onBlur: handleBlur,
                        })}
                      />
                      {errors.department_display_name && (
                        <span className="fs-8 text-danger">
                          Department display name is required
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Department incharge
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={usersDropdownData?.map((info) => ({
                                value: info.user.id,
                                label: `${info.user.first_name} ${info.user.last_name}`,
                              }))}
                              isClearable={true}
                              isLoading={isGenricParamsDropdownDataLoading}
                              id="select-forms-department_incharge"
                            />
                          )}
                          defaultValue=""
                          name="department_incharge"
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-lg btn-light me-3"
                          id="submit-button-backbutton"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        {submitLoading ? (
                          <button
                            id="button-loading"
                            className="btn text-dark"
                            disabled
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-createdepartment"
                          >
                            Create
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDepartmentModal;
