import React, { useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { ScheduleCandidateInterviewContext } from "../../Context/ScheduleCandidateInterviewContext";
import { useForm, useFormState } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import Swal from "sweetalert2";
import ScheduleFreeSlots from "./ScheduleFreeSlots";
import Select from "react-select";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const localizer = momentLocalizer(moment);

const ScheduleCalendar = () => {
  const { RefreshToken, isAccess } = useContext(RefreshTokenContext);
  const { isGenricParamsDropdownDataLoading, usersDropdownData } = useContext(
    GenericDropdownContext
  );
  const [interviewerDropdownData, setInterviewerDropdownData] = useState([]);
  console.log("interviewerDropdownData: ", interviewerDropdownData);

  const userID = secureLocalStorage.getItem("userID");
  const access = secureLocalStorage.getItem("access");
  const location = useLocation();
  const privileges = secureLocalStorage.getItem("privileges");
  console.log(location);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const { isValid } = useFormState({ control });

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  console.log("selectedStartDate: ", selectedStartDate)

  const [showModal, setShowModal] = useState(false);
  const [isScheduleData, setIsScheduleData] = useState(null);
  console.log("isScheduleData: ", isScheduleData)

  const {
    rawInterviewSlots,
    getInterviewSlots,
    setInterviewerName,
    interviewerName,
  } = useContext(ScheduleCandidateInterviewContext);
  console.log(rawInterviewSlots);

  const EventComponent = ({ event }) => (
    <>
      <div className="rbc-event-label">
        {moment(event.slot_start_time).format("h:mm a") +
          " - " +
          moment(event.slot_end_time).format("h:mm a")}
      </div>
      <div className="rbc-event-content">Free Slot</div>
    </>
  );

  const handleClosed = () => {
    setShowModal(false);
    reset();
  };

  const handleSelectSlot = (slotInfo) => {
    console.log("slot selected");
    console.log(slotInfo);
    setIsScheduleData(null);
    setShowModal(true);
    setSelectedStartDate(slotInfo.start);
  };

  const handleSelectedEvent = (event) => {
    console.log("select event");
    if (event && event.interviewer_id === userID) {
      setShowModal(true);
      console.log(event);
      setIsScheduleData(event);
      var defaultValues = {};
      defaultValues.start_at = moment(event.slot_start_time).format(
        "YYYY-MM-DDThh:mm"
      );
      reset(defaultValues);
    } else {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please note that you do not have the authority to modify the slots of your fellow panelists.",
      });
    }
  };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    const start_date = moment(data.start_at)
      .utcOffset("+05:30")
      .format()
      .split("+");
    console.log(start_date);

    const end_date = moment(data.start_at)
      .add(1, "hours")
      .utcOffset("+05:30")
      .format()
      .split("+");
    console.log(end_date);

    if (isScheduleData) {
      console.log("patch schedule");
      var edit_free_slot = JSON.stringify({
        slot_start_time: start_date[0],
        slot_end_time: end_date[0],
        interviewer_id: userID,
        updated_by_id: userID,
      });

      console.log(edit_free_slot);
      const url =
        VARIABLES.url +
        `/api/interview-slots/${isScheduleData.interviewer_id}/`;
      console.log(url);

      var config = {
        method: "patch",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        data: edit_free_slot,
      };

      setSubmitLoading(true);
      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Free slot edited successfully",
          });
          reset();
          getInterviewSlots();
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 403) {
            setSubmitLoading(true);
            RefreshToken();
          } else {
            setSubmitLoading(false);
            setSubmitMessage({
              type: "danger",
              icon: "cross",
              message: "Failed to add a free slot",
            });
          }
        });
    } else {
      var free_slot = JSON.stringify({
        slot_start_time: start_date[0],
        slot_end_time: end_date[0],
        interviewer_id: userID,
        created_by_id: userID,
      });
      console.log(free_slot);
      const url = VARIABLES.url + `/api/interview-slots/`;
      console.log(url);

      var config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        data: free_slot,
      };

      setSubmitLoading(true);
      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Free slot added successfully",
          });
          reset();
          getInterviewSlots();
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 403) {
            setSubmitLoading(true);
            RefreshToken();
          } else {
            setSubmitLoading(false);
            setSubmitMessage({
              type: "danger",
              icon: "cross",
              message: "Failed to add a free slot",
            });
          }
        });
    }
  };

  const handleDelete = (id) => {
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url + `/api/interview-slots/${id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    axios(config)
      .then((response) => {
        getInterviewSlots();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the free slot",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        } else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to delete the free slot",
          });
        }
      });
  };

  const handleDeleteAlert = () => {
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(isScheduleData?.id);
      }
    });
  };

  const validateDate = (value) => {
    const currentDate = new Date().toISOString().split("T")[0];
    console.log(value);
    if (value >= currentDate) {
      return true;
    }
    return false;
  };

  const handleInterviewerName = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption) {
      setInterviewerName(selectedOption);
    } else {
      setInterviewerName(null);
    }
  };

  const [event, setEvent] = useState([]);
  console.log(event);
  console.log(rawInterviewSlots);

  // Predefined list of colors
  const colours = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F1C40F",
    "#8E44AD",
    "#1ABC9C",
    "#E74C3C",
    "#2ECC71",
    "#3498DB",
    "#9B59B6",
    "#E67E22",
    "#D35400",
    "#2980B9",
    "#16A085",
    "#27AE60",
    "#F39C12",
    "#BDC3C7",
    "#7F8C8D",
    "#C0392B",
    "#95A5A6",
  ];

  // Function to get a color based on the interviewer's ID
  const getColor = (interviewerId) => {
    // Use modulo to ensure the index is within the range of colors array
    return colours[interviewerId % colours.length];
  };

  useEffect(() => {
    const processedEvents = rawInterviewSlots.flatMap((interviewer) => {
      const color = getColor(interviewer.interviewer.id);
      return interviewer.interviewer.slots.map((slot) => ({
        title: "Free Slot",
        id: slot.id,
        interviewer_id: interviewer.interviewer.id,
        slot_start_time: slot.slot_start_time,
        slot_end_time: slot.slot_end_time,
        color,
      }));
    });

    setEvent(processedEvents);
  }, [rawInterviewSlots]);

  useEffect(() => {
    console.log(isScheduleData);
  }, [isScheduleData]);

  // Custom event style to apply color
  const eventStyleGetter = (event) => {
    const backgroundColor = event.color;
    const style = {
      backgroundColor,
      // borderRadius: "0px",
      // opacity: 0.8,
      // color: "white",
      border: "0",
      // display: "block",
    };
    return {
      style: style,
    };
  };

  useEffect(() => {
    if (selectedStartDate !== null) {
      console.log(selectedStartDate);
      var defaultValues = {};
      defaultValues.start_at =
        moment(selectedStartDate).format("YYYY-MM-DDThh:mm");
      reset(defaultValues);
    }
  }, [selectedStartDate]);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getInterviewSlots(access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getInterviewSlots(access);
    }
  }, [isAccess, interviewerName]);

  // useEffect(() => {
  //   const filterInterviewersDropdownData = usersDropdownData?.filter((info) =>
  //     info.role?.find((role) =>
  //       role.role_name?.includes("SME") || role.role_name?.includes("Departmental Manager") || role.role_name?.includes("RC")
  //     )
  //   );
  //   setInterviewerDropdownData(filterInterviewersDropdownData)
  // }, [usersDropdownData])

  useEffect(() => {
    const filterInterviewersDropdownData = usersDropdownData
      ?.filter((info) =>
        info.role?.some((role) =>
          ["SME", "Departmental Manager", "RC"].includes(role.role_name)
        )
      )
      .map((info) => info.user);

    setInterviewerDropdownData(filterInterviewersDropdownData);
  }, [usersDropdownData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      {/* <Calendar
        localizer={localizer}
        events={event}
        startAccessor={(event) => new Date(event.slot_start_time)}
        endAccessor={(event) => new Date(event.slot_end_time)}
        style={{ height: "100vh" }}
        selectable={true}
        defaultView="day"
        views={[Views.MONTH, Views.WORK_WEEK, Views.WEEK, Views.DAY, Views.AGENDA]}
        // components={{
        //   event: EventComponent, // Use custom event component
        // }}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectedEvent}
        eventPropGetter={eventStyleGetter}
      /> */}

      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          {privileges.includes("view_panelist_free_slot") ? (
            <div className="" style={{ width: "20rem" }}>
              <Select
                options={interviewerDropdownData?.map((info) => ({
                  value: info.id,
                  label: `${info.first_name} ${info.last_name}`,
                }))}
                isMulti
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 1000,
                  }),
                }}
                value={interviewerName}
                placeholder="Select Interviewers"
                id="select-form-users"
                onChange={handleInterviewerName}
                isLoading={isGenricParamsDropdownDataLoading}
              />
            </div>
          ) : (
            <div></div>
          )}
          <div className="nk-block-head-content d-flex">
            {privileges.includes("free_slot_create") ? (
              <div className="button-group">
                <button
                  className="btn btn-outline-primary btn-dim"
                  id="button-button-book_free_slot"
                  data-bs-toggle="modal"
                  data-bs-target="#free_slot"
                >
                  Free slot
                </button>
              </div>
            ) : (
              ""
            )}
            <div class="nk-block-head-content ms-3">
              <a
                id="back-button"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="back-button"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-inner">
          <div>
            <Calendar
              localizer={localizer}
              events={event}
              startAccessor={(event) => new Date(event.slot_start_time)}
              endAccessor={(event) => new Date(event.slot_end_time)}
              style={{ height: "100vh" }}
              selectable={true}
              defaultView="day"
              views={[
                Views.MONTH,
                Views.WORK_WEEK,
                Views.WEEK,
                Views.DAY,
                Views.AGENDA,
              ]}
              // components={{
              //   event: EventComponent, // Use custom event component
              // }}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleSelectedEvent}
              eventPropGetter={eventStyleGetter}
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div className="d-flex flex-wrap">
          {rawInterviewSlots?.map((interviewer) => (
            <div
              key={interviewer?.interviewer?.id}
              className="d-flex align-items-center me-3 mb-2"
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: getColor(interviewer?.interviewer?.id),
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
              ></div>
              <span>{`${interviewer?.interviewer?.first_name} ${interviewer?.interviewer?.last_name}`}</span>
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {isScheduleData === null ? "Edit Free Slots" : "Delete Free Slot"}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => handleClosed()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">

                  {isScheduleData === null ?
                    <div className="form-group">
                      <div className="d-flex mb-3">
                        <label className="form-label">
                          Event Start Date <span className="text-danger">*</span>
                        </label>
                      </div>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="text-forms-start_at"
                        placeholder="Describe your event title"
                        {...register("start_at", {
                          required: "true",
                          validate: validateDate,
                        })}
                      />
                      {errors.start_at && errors.start_at.type === "required" && (
                        <span className="fs-8 text-danger">
                          Event start date is required
                        </span>
                      )}
                      {errors.start_at && errors.start_at.type === "validate" && (
                        <span className="fs-8 text-danger">
                          Date must be today or later
                        </span>
                      )}
                    </div>
                    :
                    <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-ud-item flex-grow-1 p-0">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Event Start Date</span>
                            <span className="profile-ud-value">
                              {moment(isScheduleData?.slot_start_time).format('MMMM Do YYYY, h:mm:ss a')}
                            </span>
                          </div>
                        </div>
                        <>
                          {privileges.includes("free_slot_delete") ? (
                            <>
                              {isScheduleData !== null &&
                                <>
                                  <a
                                    className="toggle btn btn-sm btn-icon btn-primary d-md-none"
                                    id="button-button-smescheduledinterviews"
                                    onClick={handleDeleteAlert}
                                  >
                                    <em className="icon ni ni-trash"></em>
                                  </a>
                                  <a
                                    className="toggle btn btn-sm btn-primary d-none d-md-inline-flex"
                                    id="button-button-smescheduledinterviews"
                                    onClick={handleDeleteAlert}
                                  >
                                    <em className="icon ni ni-trash"></em>
                                  </a>
                                </>
                              }
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      </div>
                    </div>
                  }
                </div>
                {isScheduleData === null &&
                  <div className="modal-footer">
                    {submitLoading ? (
                      <button
                        id="loading-button"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : isValid === true ? (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-free_slot"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-free_slot"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      )}

      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <ScheduleFreeSlots requisitionData={location?.state?.editpost} />
    </>
  );
};

export default ScheduleCalendar;
