import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import { requisitionByUserData } from '../WorkflowStepperForm/StepperFormJson'

const HRD = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const privileges = secureLocalStorage.getItem("privileges");

  const {
    // requisitionByUserData,
    getRequisitionByUserData,
    requisitionByUserEntries,
    requisitionByUserPageCount,
    setRequisitionByUserEntries,
    requisitionByUserCount,
    handleRequisitionByUserPageClick,
    isRequisitionByUserData,
    isRequisitionByUserLoading,
  } = useContext(RequestedJobOpeningContext);

  console.log("requisitionByUserData: ", requisitionByUserData)

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const [Status, setStatus] = useState(false)

  const handleStatus = () => {
    setStatus(prevStatus => !prevStatus)
  }

  const handlePercentage = (received, processed, type) => {
    const total = received + processed;
    const receivedPercentage = (received / total) * 100;
    const processedPercentage = (processed / total) * 100;
    if (type === 'received') {
      return receivedPercentage
    } else {
      return processedPercentage
    }
  }

  const handleEntries = (event) => {
    setRequisitionByUserEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRequisitionByUserData(1, searchRequest);
    } else {
      getRequisitionByUserData(1);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getRequisitionByUserData(1, searchRequest, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getRequisitionByUserData(1, searchRequest, access);
    }
  }, [requisitionByUserEntries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">HR-D Approval</h3>
            {isRequisitionByUserLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search using Requisition ID (e.g., 126), job title, department, job type, vacancy, status, created at (e.g., 01-01-2024)"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th style={{ textWrap: "nowrap" }}>Requisition ID <em className="icon ni ni-info fs-4 text-info" style={{ cursor: "pointer" }} onClick={() => handleStatus()}></em></th>
                      {Status &&
                        <>
                          <th style={{ textWrap: "nowrap" }}>Age of job</th>
                          <th style={{ textWrap: "nowrap", textAlign: "center" }}>Job Title</th>
                          <th style={{ textWrap: "nowrap" }}>Department</th>
                          <th style={{ textWrap: "nowrap" }}>Priority</th>
                        </>
                      }
                      <th style={{ textWrap: "nowrap" }}>Vacancy</th>
                      <th className="text-center"> Candidate in Process </th>
                      <th className="text-center"> Shortlisted Candidate </th>
                      <th className="text-center"> Round 1 </th>
                      <th className="text-center"> Round 2-F2F </th>
                      <th className="text-center"> Round 3-Assignment </th>
                      <th className="text-center"> Test </th>
                      <th className="text-center"> SHF </th>
                      <th className="text-center"> BGV </th>
                      <th style={{ textWrap: "nowrap" }}>HRD Approval</th>
                      <th style={{ textWrap: "nowrap" }}>MD Approval</th>
                    </tr>

                    <tr className="border-bottom">
                      <td></td>
                      {Status &&
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      }
                      <td></td>

                      <td className="table-header">
                        <div className="table-content">
                          <table className="w-100">
                            <tr>
                              <td className="text-left">Received1</td>
                              <td className="text-right">Processed1</td>
                            </tr>
                          </table>
                        </div>
                      </td>

                      <td className="table-header">
                        <div className="table-content">
                          <table className="w-100">
                            <tr>
                              <td className="text-left">Received1</td>
                              <td className="text-right">Processed1</td>
                            </tr>
                          </table>
                        </div>
                      </td>

                      <td className="table-header">
                        <div className="table-content">
                          <table className="w-100">
                            <tr>
                              <td className="text-left">Received1</td>
                              <td className="text-right">Processed1</td>
                            </tr>
                          </table>
                        </div>
                      </td>

                      <td className="table-header">
                        <div className="table-content">
                          <table className="w-100">
                            <tr>
                              <td className="text-left">Received1</td>
                              <td className="text-right">Processed1</td>
                            </tr>
                          </table>
                        </div>
                      </td>

                      <td className="table-header">
                        <div className="table-content">
                          <table className="w-100">
                            <tr>
                              <td className="text-left">Received1</td>
                              <td className="text-right">Processed1</td>
                            </tr>
                          </table>
                        </div>
                      </td>

                      <td className="table-header">
                        <div className="table-content">
                          <table className="w-100">
                            <tr>
                              <td className="text-left">Received1</td>
                              <td className="text-right">Processed1</td>
                            </tr>
                          </table>
                        </div>
                      </td>

                      <td className="table-header">
                        <div className="table-content">
                          <table className="w-100">
                            <tr>
                              <td className="text-left">Received1</td>
                              <td className="text-right">Processed1</td>
                            </tr>
                          </table>
                        </div>
                      </td>

                      <td className="table-header">
                        <div className="table-content">
                          <table className="w-100">
                            <tr>
                              <td className="text-left">Received1</td>
                              <td className="text-right">Processed1</td>
                            </tr>
                          </table>
                        </div>
                      </td>

                      <td></td>
                      <td></td>
                    </tr>

                  </thead>
                  <tbody>
                    {requisitionByUserData.length !== 0 ? (
                      requisitionByUserData.map((post, index) => (
                        <tr key={index}>
                          <td>
                            {privileges.includes(
                              "candidate_details_retrieve"
                            ) ? (
                              <NavLink
                                to={"/hrdcandidatelist"}
                                id="button-button-hrdcandidatelist"
                                state={{ editpost: post }}
                              >
                                <span>
                                  {(requisitionID &&
                                    requisitionID[0]?.name + post?.id) ||
                                    "-"}
                                </span>
                              </NavLink>
                            ) : (
                              <>
                                {" "}
                                {(requisitionID &&
                                  requisitionID[0]?.name + post?.id) ||
                                  "-"}
                              </>
                            )}
                          </td>

                          {Status &&
                            <>
                              <td style={{ textAlign: "center" }}>{post?.created_since || "-"}</td>

                              <td style={{ textAlign: "center", textWrap: "nowrap" }}>
                                {privileges.includes("job_opening_retrieve") ? (
                                  <NavLink
                                    to={"/jobopeningdetails"}
                                    state={{ editpost: post }}
                                  >
                                    {post?.job_opening?.job_title || "-"}
                                  </NavLink>
                                ) : (
                                  <> {post?.job_opening?.job_title || "-"}</>
                                )}
                              </td>

                              <td style={{ textAlign: "center", textWrap: "nowrap" }}>{post?.job_opening?.department?.department_display_name || "-"}</td>

                              <td style={{ textAlign: "center" }}>{post?.priority || "-"}</td>
                            </>
                          }

                          <td style={{ textAlign: "center" }}>{post?.no_of_vacancy || "-"}</td>

                          <td>
                            <table className="w-100">
                              <tr>
                                <td className="text-left">{post?.candidate_in_process?.received}</td>
                                <td className="text-right">{post?.candidate_in_process?.processed}</td>
                              </tr>
                            </table>
                            <div className="progress-bar-container">
                              <div className="progress-bar received" style={{ width: `${handlePercentage(post?.candidate_in_process?.received, post?.candidate_in_process?.processed, 'received')}%`, backgroundColor: "#FFB800" }}></div>
                              <div className="progress-bar processed" style={{ width: `${handlePercentage(post?.candidate_in_process?.received, post?.candidate_in_process?.processed, 'processed')}%`, backgroundColor: "#C99100" }}></div>
                            </div>
                          </td>

                          <td>
                            <table className="w-100">
                              <tr>
                                <td className="text-left">{post?.shortlisted_candidate?.received}</td>
                                <td className="text-right">{post?.shortlisted_candidate?.processed}</td>
                              </tr>
                            </table>
                            <div className="progress-bar-container">
                              <div className="progress-bar received" style={{ width: `${handlePercentage(post?.shortlisted_candidate?.received, post?.shortlisted_candidate?.processed, 'received')}%`, backgroundColor: "#8B79DE" }}></div>
                              <div className="progress-bar processed" style={{ width: `${handlePercentage(post?.shortlisted_candidate?.received, post?.shortlisted_candidate?.processed, 'processed')}%`, backgroundColor: "#4B3A9B" }}></div>
                            </div>
                          </td>

                          <td>
                            <table className="w-100">
                              <tr>
                                <td className="text-left">{post?.sme_shortlisted_candidate?.received}</td>
                                <td className="text-right">{post?.sme_shortlisted_candidate?.processed}</td>
                              </tr>
                            </table>
                            <div className="progress-bar-container">
                              <div className="progress-bar received" style={{ width: `${handlePercentage(post?.sme_shortlisted_candidate?.received, post?.sme_shortlisted_candidate?.processed, 'received')}%`, backgroundColor: "#FF92BF" }}></div>
                              <div className="progress-bar processed" style={{ width: `${handlePercentage(post?.sme_shortlisted_candidate?.received, post?.sme_shortlisted_candidate?.processed, 'processed')}%`, backgroundColor: "#8E1949" }}></div>
                            </div>
                          </td>

                          <td>
                            <table className="w-100">
                              <tr>
                                <td className="text-left">{post?.telephonic_candidates?.received}</td>
                                <td className="text-right">{post?.telephonic_candidates?.processed}</td>
                              </tr>
                            </table>
                            <div className="progress-bar-container">
                              <div className="progress-bar received" style={{ width: `${handlePercentage(post?.telephonic_candidates?.received, post?.telephonic_candidates?.processed, 'received')}%`, backgroundColor: "#07D3FF" }}></div>
                              <div className="progress-bar processed" style={{ width: `${handlePercentage(post?.telephonic_candidates?.received, post?.telephonic_candidates?.processed, 'processed')}%`, backgroundColor: "#0E6B7F" }}></div>
                            </div>
                          </td>

                          <td>
                            <table className="w-100">
                              <tr>
                                <td className="text-left">{post?.technical_candidates?.received}</td>
                                <td className="text-right">{post?.technical_candidates?.processed}</td>
                              </tr>
                            </table>
                            <div className="progress-bar-container">
                              <div className="progress-bar received" style={{ width: `${handlePercentage(post?.technical_candidates?.received, post?.technical_candidates?.processed, 'received')}%`, backgroundColor: "#37E4AE" }}></div>
                              <div className="progress-bar processed" style={{ width: `${handlePercentage(post?.technical_candidates?.received, post?.technical_candidates?.processed, 'processed')}%`, backgroundColor: "#195844" }}></div>
                            </div>
                          </td>

                          <td>
                            <table className="w-100">
                              <tr>
                                <td className="text-left">{post?.hr_candidates?.received}</td>
                                <td className="text-right">{post?.hr_candidates?.processed}</td>
                              </tr>
                            </table>
                            <div className="progress-bar-container">
                              <div className="progress-bar received" style={{ width: `${handlePercentage(post?.hr_candidates?.received, post?.hr_candidates?.processed, 'received')}%`, backgroundColor: "#7CAB1F" }}></div>
                              <div className="progress-bar processed" style={{ width: `${handlePercentage(post?.hr_candidates?.received, post?.hr_candidates?.processed, 'processed')}%`, backgroundColor: "#416004" }}></div>
                            </div>
                          </td>

                          <td>
                            <table className="w-100">
                              <tr>
                                <td className="text-left">3</td>
                                <td className="text-right">4</td>
                              </tr>
                            </table>
                            <div className="progress-bar-container">
                              <div className="progress-bar received" style={{ width: `${handlePercentage(70, 130, 'received')}%`, backgroundColor: "#FF7800" }}></div>
                              <div className="progress-bar processed" style={{ width: `${handlePercentage(70, 130, 'processed')}%`, backgroundColor: "#8B4D15" }}></div>
                            </div>
                          </td>

                          <td>
                            <table className="w-100">
                              <tr>
                                <td className="text-left">3</td>
                                <td className="text-right">4</td>
                              </tr>
                            </table>
                            <div className="progress-bar-container">
                              <div className="progress-bar received" style={{ width: `${handlePercentage(70, 130, 'received')}%`, backgroundColor: "#058EFC" }}></div>
                              <div className="progress-bar processed" style={{ width: `${handlePercentage(70, 130, 'processed')}%`, backgroundColor: "#97D1FF" }}></div>
                            </div>
                          </td>

                          <td style={{ textAlign: "center" }}>{post?.hrd_approved || "-"}</td>

                          <td style={{ textAlign: "center" }}>{post?.md_approved || "-"}</td>

                        </tr>
                      ))
                    ) : isRequisitionByUserData ? (
                      <tr>
                        <td colSpan="7">Good job! You are all caught up.</td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="7">Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={requisitionByUserPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={requisitionByUserPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleRequisitionByUserPageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {requisitionByUserCount === null
                          ? 0
                          : requisitionByUserCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={requisitionByUserEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default HRD;
