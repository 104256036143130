import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateDetailsContext } from "../../../Context/CandidateDetailsContext";
import CandidateStatus from "../../RequisitionStatus/CandidateStatus";

const HRDApproval = ({ formId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;

  const { isAccess } = useContext(RefreshTokenContext);
  console.log(formId); //data of the tab in which this form is present

  //message after submitting data
  const { setSubmitMessage } = useContext(SnackbarContext);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Loacl Storage Data
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  // CandidateJob Get
  const {
    candidateAdditionalData,
    isCandidateAdditionalDataLoading,
    getCandidateAdditionalData,
  } = useContext(CandidateDetailsContext);

  console.log(candidateAdditionalData);

  // Stepper (NEXT/PREVIOUS)
  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);

  // "interviewActionDropdownData" stores the Accept/Reject/Draft Dropdown
  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);
  console.log(interviewActionDropdownData);

  const [isEditable, setIsEditable] = useState(
    candidateAdditionalData?.hrd_approval === null
  );

  // React Hook forms
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm();

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const isValidInput = (value) => {
    return value >= 0 && value <= 5;
  };

  const handleFormSubmit = (data) => {
    if (isDirty) {
      handleSubmit(onSubmit)(data);
    } else {
      nextLevel();
    }
  };

  //Submission of HRD form
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    var HRDApproval = {
      candidate_job_id: candidate_id,
      requisition_id: requisition_id,
      hrd_comments: data.comments,
      hrd_feedback: data.feedback,
      hrd_score: data.hrd_score,
      hrd_approval:
        data?.is_selected === "Draft"
          ? 0
          : data?.is_selected === "Accept"
            ? 1
            : data?.is_selected === "Reject"
              ? 2
              : null,
      updated_by_id: userID,
    };
    if (location.state?.workflowState === "HRD_tab") {
      HRDApproval.tab_name = "HRD Approval";
    } else {
      HRDApproval.workflow_session = {
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
      };
    }

    var config = {
      method: "patch",
      url:
        VARIABLES.url + `/api/candidatejob/${candidate_id}/?type=hrd_approval`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: JSON.stringify(HRDApproval),
    };
    console.log(config);

    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          getWorkflowSession(candidate_id, requisition_id);
          getCandidateAdditionalData(candidate_id, requisition_id);
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Approved successfully",
          });
          setTimeout(() => {
            if (location.state?.workflowState === "HRD_tab") {
              navigate(-1);
            } else {
              nextLevel();
            }
          }, 2000);
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed to approve",
        });
      });
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getCandidateAdditionalData(candidate_id, requisition_id, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getCandidateAdditionalData(candidate_id, requisition_id, access);
    }
  }, [isAccess]);

  //Pre-populting HRD data
  useEffect(() => {
    const editedData = candidateAdditionalData;
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      defaultValues.comments = editedData.hrd_comments;
      defaultValues.feedback = editedData.hrd_feedback;
      defaultValues.hrd_score = editedData.hrd_score;
      defaultValues.is_selected =
        editedData.hrd_approval === 0
          ? "Draft"
          : editedData.hrd_approval === 1
            ? "Accept"
            : editedData.hrd_approval === 2
              ? "Reject"
              : null;
      reset(defaultValues);
    }
  }, [candidateAdditionalData]);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> HRD Approval </h4>
            {isCandidateAdditionalDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {location.state?.workflowState === "HRD_tab" && (
            <div class="nk-block-head-content">
              <a
                id="button-back"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="button-back"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
          )}

          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <CandidateStatus
                state={{
                  editpost: {
                    id: candidate_id,
                    req_id: requisition_id,
                  },
                }}
              />

              <div className="row g-4">
                {/* {candidateAdditionalData?.hrd_approval !== null && (
                  <>
                    {privileges.includes("job_opening_retrieve") && (
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary "
                          onClick={toggleEdit}
                        >
                          {isEditable ? "Cancel" : "Edit"}
                        </button>
                      </div>
                    )}
                  </>
                )} */}
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Comments
                    <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="textarea-forms-comments"
                    placeholder="Enter Comments"
                    {...register("comments", { required: true })}
                    disabled={
                      isFormFilled ||
                      candidateAdditionalData?.hrd_approval !== null
                    }
                  // disabled={!isEditable}
                  />

                  {errors.comments && (
                    <span className="fs-8 text-danger">
                      Comments are required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Feedback
                    <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="textarea-forms-feedback"
                    placeholder="Enter feedback"
                    {...register("feedback", { required: true })}
                    disabled={
                      isFormFilled ||
                      candidateAdditionalData?.hrd_approval !== null
                    }
                  // disabled={!isEditable}
                  />
                  {errors.feedback && (
                    <span className="fs-8 text-danger">
                      Feedback is required{" "}
                    </span>
                  )}
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <div className="d-flex">
                      <label className="form-label">Score (out of 5):</label>
                      <div className="d-flex flex-column">
                        <input
                          type="number"
                          className="form-control ms-2"
                          placeholder={"Enter score"}
                          {...register(`hrd_score`, {
                            validate: { isValidInput },
                          })}
                          onWheel={(e) => e.target.blur()} // Prevents number input scrolling
                          disabled={
                            isFormFilled ||
                            candidateAdditionalData?.hrd_approval !== null
                          }
                        />
                        {errors[`hrd_score`]?.type === "isValidInput" && (
                          <span
                            className="fs-8 text-danger"
                          // style={{ fontSize: "10px" }}
                          >
                            Input range should be 0 - 5
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6 col-sm-12">
                  <ul className="row">
                    {interviewActionDropdownData.map((action, index) => (
                      <li className="col-lg-2 col-md-2 col-sm-2" key={index}>
                        <div className="custom-control custom-radio me-3">
                          <input
                            type="radio"
                            id={`checkbox-form-is-selected-HRD-${action.value.toLowerCase()}`}
                            name="resumeupload"
                            className="custom-control-input"
                            value={action.value}
                            {...register("is_selected", { required: true })}
                            disabled={
                              isFormFilled ||
                              candidateAdditionalData?.hrd_approval !== null
                            }
                          />
                          <label
                            className="custom-control-label"
                            for={`checkbox-form-is-selected-HRD-${action.value.toLowerCase()}`}
                          >
                            {action.name}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {errors.is_selected && (
                    <span className="fs-8 text-danger">
                      Please select any one
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          workflowId?.is_complete === 1 ? (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                              onClick={() => nextLevel()}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                            >
                              Next
                            </button>
                          )
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default HRDApproval;
