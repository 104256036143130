import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { MasterTableContext } from "../../Context/MasterTableContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";

const AgencyMaster = () => {
  const { isAccess } = useContext(RefreshTokenContext)
  const [searchAgency, setSearchAgency] = useState("");
  console.log(searchAgency);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawAgency,
    getAgencyData,
    agencyEntries,
    agencyPageCount,
    setAgencyEntries,
    agencyCount,
    handleAgencyClick,
    isAgencyData,
    isAgencyLoading,
  } = useContext(MasterTableContext);

  const handleEntries = (event) => {
    setAgencyEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchAgency(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getAgencyData(1, searchAgency);
    } else {
      getAgencyData(1);
      setSearchAgency("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access")
      console.log(access)
      getAgencyData(1, searchAgency, access);
    } else {
      let access = secureLocalStorage.getItem("access")
      console.log(access)
      getAgencyData(1, searchAgency, access);
    }
  }, [agencyEntries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Agency Master</h3>
            {isAgencyLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("agency_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/addagency"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-addagency"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/addagency"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-addagency"
              >
                <em className="icon ni ni-plus"></em>
                <span>Add Agency</span>
              </NavLink>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchAgency}
                      className="form-control"
                      placeholder="Search by agency name and agency type"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Agency Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Preference</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Agency Type</span>
                      </div>
                      {privileges.includes("agency_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawAgency.length > 0 ? (
                      rawAgency.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.agency_name || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post.preference || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post.agency_type || "-"}
                            </span>
                          </div>

                          {privileges.includes("agency_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/editagency"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-editagency"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isAgencyData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading.....</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={agencyPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={agencyPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleAgencyClick(data, searchAgency)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {agencyCount === null ? 0 : agencyCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={agencyEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyMaster;
