import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import { NotificationsContext } from "../../Context/NotificationsContextProvider";

const NotificationMapper = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchNotificationMapper, setSearchNotificationMapper] = useState("");

  const privileges = secureLocalStorage.getItem("privileges");

  const {
    rawNotificationMapper,
    getNotificationMapperData,
    notificationMapperEntries,
    notificationMapperPageCount,
    setNotificationMapperEntries,
    notificationMapperCount,
    handleNotificationMapperClick,
    isNotificationMapperData,
    isNotificationMapperLoading,
  } = useContext(NotificationsContext);

  const handleEntries = (event) => {
    setNotificationMapperEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchNotificationMapper(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getNotificationMapperData(1, searchNotificationMapper);
    } else {
      getNotificationMapperData(1);
      setSearchNotificationMapper("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getNotificationMapperData(1, searchNotificationMapper, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getNotificationMapperData(1, searchNotificationMapper, access);
    }
  }, [notificationMapperEntries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Notification Mapper</h3>
            {isNotificationMapperLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          {/* <div className="p-2">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#tabItem1">App</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#tabItem2">Email</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#tabItem3">WhatsApp</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tabItem1">
                <p>App table</p>
              </div>
              <div class="tab-pane" id="tabItem2">
                <p>Email table</p>
              </div>
              <div class="tab-pane" id="tabItem3">
                <p>WhatsApp table</p>
              </div>
            </div>
          </div> */}
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchNotificationMapper}
                      className="form-control"
                      placeholder="Search by stage, notification message, user"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Stage</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Notification Message</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Users</span>
                      </div>

                      {privileges.includes("notification_mapper_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawNotificationMapper.length > 0 ? (
                      rawNotificationMapper.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">{post.stage || "-"}</span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post.notification_message || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            {post?.user?.length > 1 ? (
                              <span className="tb-lead">
                                <span className="title">
                                  <span>
                                    {post?.user[0]?.first_name +
                                      post?.user[0]?.last_name}{" "}
                                    {", "}
                                    {post?.user[1]?.first_name +
                                      post?.user[1]?.last_name || "-"}{" "}
                                    {", "} {". . ."}
                                  </span>
                                </span>
                              </span>
                            ) : (
                              <span className="tb-lead">
                                <span className="title">
                                  {post?.user[0]?.first_name || "-"}{" "}
                                  {post?.user[0]?.last_name || "-"}
                                </span>
                              </span>
                            )}
                          </div>
                          {privileges.includes("notification_mapper_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/editnotificationmapper"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-editnotificationmapper"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isNotificationMapperData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading.....</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={notificationMapperPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={notificationMapperPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleNotificationMapperClick(
                        data,
                        searchNotificationMapper
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {notificationMapperCount === null
                          ? 0
                          : notificationMapperCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={notificationMapperEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationMapper;
