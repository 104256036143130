import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderList } from "primereact/orderlist";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { SettingContext } from "../../../Context/SettingsContext";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";

const TypeMasterEdit = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const { getTypeMaster } = useContext(SettingContext);
  const { isGenricDropdownDataLoading, typeMasterCategoryDropdownData } = useContext(GenericDropdownContext);
  const { getInterviewQuestionnair } = useContext(InterviewQuestionContext);

  //DROPDOWN DATA
  // const { isDropdownDataLoading, parentValueDropdownData, setParentCategory } =
  //   useContext(TypemasterDropdownContext);

  const userID = secureLocalStorage.getItem("userID");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  // const [items, setItems] = useState([]);
  const [valueField, setValueField] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // const itemTemplate = (item) => {
  //   return <div>{item}</div>;
  // };

  let location = useLocation();
  console.log(location);
  let navigate = useNavigate();

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    var type_master = JSON.stringify({
      name: data.name,
      value: data.value,
      category_id: data.category.value,
      description: data.description,
      parent_id: data.parent_id,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/typemaster/${location.state.editpost.id}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: type_master,
    };

    console.log(type_master);
    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully Updated the Typemaster Value.",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        getTypeMaster();
        getInterviewQuestionnair();
        reset();
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          RefreshToken();
        }
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to Update the Typemaster Value.",
        });
      });
  };

  // const onSequenceSubmit = (data) => {
  //   console.log(data);
  //   var type_master = JSON.stringify({
  //     sequence: items,
  //     updated_by_id: userID,
  //   });

  //   var config = {
  //     method: "patch",
  //     url: VARIABLES.url + `/api/typemaster/${location.state.editpost.id}/`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: type_master,
  //   };

  //   console.log(type_master);
  //   setSubmitLoading(true);
  //   axios(config)
  //     .then((response) => {
  //       setSubmitLoading(false);
  //       console.log(JSON.stringify(response.data));
  //       setSubmitMessage({
  //         type: "success",
  //         icon: "check",
  //         message: "Successfully edited type master",
  //       });
  //       getTypeMaster();
  //       getInterviewQuestionnair();
  //       reset();
  //       setTimeout(() => {
  //         navigate(-1);
  //       }, 2000);
  //     })
  //     .catch((error) => {
  //       setSubmitLoading(false);
  //       console.log(error);
  //       setSubmitMessage({
  //         type: "danger",
  //         icon: "cross",
  //         message: "Failed to edit type master",
  //       });
  //     });
  // };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedData = location.state.editpost;
    if (editedData) {
      var defaultValues = {};
      defaultValues.name = editedData.name;
      defaultValues.value = editedData.value;
      defaultValues.description = editedData.description;
      defaultValues.sequence = editedData.sequence;
      defaultValues.parent_id = editedData.parent_id;
      setValueField(editedData.value);

      const getCategory = {
        value: editedData.category?.id,
        label: editedData.category?.category_name,
      };
      defaultValues.category = getCategory;

      reset(defaultValues);
    }
  }, []);

  // const handleAddClick = () => {
  //   setItems([...items, valueField]);
  // };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Edit Type Master</h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Category <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={typeMasterCategoryDropdownData?.map(
                            (info) => ({
                              value: info.id,
                              label: info.category_name,
                            })
                          )}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-category" />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="category"
                      control={control} />
                    {errors.category && (
                      <span className="fs-8 text-danger">
                        Category is required
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-lg-4">
                  <div className="form-group">
                    <label className="form-label">Parent Category</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={typeMasterCategoryDropdownData?.map(
                            (info) => ({
                              value: info.id,
                              label: info.category_name,
                            })
                          )}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          onChange={(e) => {
                            setParentCategory(e.label);
                            field.onChange(e);
                          }}
                          id="select-forms-parentCategory"
                        />
                      )}
                      defaultValue=""
                      name="parentCategory"
                      control={control}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="form-label">Parent Value</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={parentValueDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isLoading={isDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-parentValue"
                        />
                      )}
                      defaultValue=""
                      name="parentValue"
                      control={control}
                    />
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-name"
                      placeholder="Enter name"
                      {...register("name", {
                        required: true,
                      })} />
                    {errors.name && (
                      <span className="fs-8 text-danger">Name is required</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Value <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-value"
                      placeholder="Enter value"
                      {...register("value", {
                        required: true,
                      })}
                      onChange={(e) => setValueField(e.target.value)} />
                    {errors.value && (
                      <span className="fs-8 text-danger">
                        Value is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-description"
                      placeholder="Enter description ......"
                      {...register("description")}
                    />
                  </div>
                </div>

                {/* <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary"
                    onClick={handleAddClick}
                  >
                    Add
                  </button>
                </div> */}

                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-lg btn-light me-3"
                    id="submit-button-backbutton"
                    onClick={() => navigate(-1)} >
                    Cancel
                  </button>
                  {submitLoading ? (
                    <button
                      id="button-loading"
                      className="btn text-dark"
                      disabled >
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary"
                      id="button-button-submit_typemaster"
                    // onClick={handleAddClick}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
            {/* <form onSubmit={handleSubmit(onSequenceSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Sequence</label>
                    <OrderList
                      className="d-flex flex-row-reverse"
                      dataKey="id"
                      value={items}
                      onChange={(e) => setItems(e.value)}
                      itemTemplate={itemTemplate}
                    ></OrderList>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button className="btn text-dark" disabled>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-typemaster"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form> */}
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div className={`alert alert-fill alert-${submitMessage.type} alert-icon`}>
                  <em className={`icon ni ni-${submitMessage.icon}-circle`}></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TypeMasterEdit;
