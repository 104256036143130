import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const CandidateDetailsContext = createContext(null);

function CandidateDetailsProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  //Additional Data
  const [
    isCandidateAdditionalDataLoading,
    setIsCandidateAdditionalDataLoading,
  ] = useState(true);
  const [candidateAdditionalData, setCandidateAdditionalData] = useState([]);

  //Additional Details of candidate, also used in HRD, MD, send offer.
  const getCandidateAdditionalData = async (
    candidate_job_id,
    requisition_id,
    access
  ) => {
    let url =
      VARIABLES.url +
      `/api/candidatejob/?candidate_job=${candidate_job_id}&requisition_id=${requisition_id}`;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setIsCandidateAdditionalDataLoading(true);
      const res = await axios(config);
      setCandidateAdditionalData(res.data.data);
      setIsCandidateAdditionalDataLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateAdditionalDataLoading(true);
      } else {
        setIsCandidateAdditionalDataLoading(false);
      }
    }
  };

  return (
    <div>
      <CandidateDetailsContext.Provider
        value={{
          candidateAdditionalData,
          isCandidateAdditionalDataLoading,
          getCandidateAdditionalData,
        }}
      >
        {children}
      </CandidateDetailsContext.Provider>
    </div>
  );
}

export default CandidateDetailsProvider;
