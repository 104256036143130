import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { MasterTableContext } from "../../../Context/MasterTableContext";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { VARIABLES } from "../../../Constants";
import axios from "axios";

const CreateSeniorityModal = () => {
  const userID = secureLocalStorage.getItem("userID");

  const { getSeniorityData } = useContext(MasterTableContext);

  const { getGenericDropdownData } = useContext(GenericDropdownContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let location = useLocation();
  let navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const seniorityName = watch("seniority_name");
  const seniorityDisplayName = watch("seniority_display_name");

  const onSubmit = (data) => {
    var user = JSON.stringify({
      seniority_name: data.seniority_name,
      seniority_display_name: data.seniority_display_name,
      created_by_id: userID,
      updated_by_id: userID,
    });

    const url = VARIABLES.url + "/api/seniority/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getSeniorityData();
        getGenericDropdownData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Seniority created successfully",
        });
        reset();
        document.getElementById("button-button-closemodal").click();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to create seniority",
        });
      });
  };

  const handleBlur = (e) => {
    console.log(e.target.value);
    if (seniorityName === "") {
      reset({
        seniority_name: e.target.value,
      });
    }
    if (seniorityDisplayName === "") {
      reset({
        seniority_display_name: e.target.value,
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);
  return (
    <>
      <div
        className="modal fade"
        id="createsenioritymodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Seniority
              </h5>
              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <div className="row g-4">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Seniority name
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter seniority name"
                        id="text-form-seniority_name"
                        {...register("seniority_name", {
                          required: true,
                          onBlur: handleBlur,
                        })}
                      />
                      {errors.seniority_name && (
                        <span className="fs-8 text-danger">
                          Seniority name is required
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Seniority display name
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter seniority display name"
                        id="text-form-seniority_display_name"
                        {...register("seniority_display_name", {
                          required: true,
                          onBlur: handleBlur,
                        })}
                      />
                      {errors.seniority_display_name && (
                        <span className="fs-8 text-danger">
                          Seniority display name is required
                        </span>
                      )}
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-lg btn-light me-3"
                          id="submit-button-backbutton"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        {submitLoading ? (
                          <button
                            id="button-loading"
                            className="btn text-dark"
                            disabled
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-createseniority"
                          >
                            Create
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSeniorityModal;
