import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { MasterTableContext } from "../../Context/MasterTableContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const JobLocationCreate = () => {
  const { getJobLocationTypeMaster } = useContext(MasterTableContext);
  const { getDropdownData } = useContext(TypemasterDropdownContext);

  const userID = secureLocalStorage.getItem("userID");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  let location = useLocation();
  console.log(location);
  let navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      name: data.name,
      value: data.value,
      category_id: location.state?.status === "bgv" ? 48 : 40,
      description: data.description,
      created_by_id: userID,
      updated_by_id: userID,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/typemaster/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };
    console.log(user);
    setSubmitLoading(true);

    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message:
            location.state?.status === "bgv"
              ? "BGV Agency Successfully Created."
              : "Job Location Successfully Created.",
        });
        getJobLocationTypeMaster();
        getDropdownData()
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message:
            location.state?.status === "bgv"
              ? "Failed to Create BGV Agency."
              : "Failed to Create Job Location.",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">
              {location.state?.status === "bgv"
                ? "Create BGV Agency"
                : "Create Job Location"}
            </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      {location.state?.status === "bgv"
                        ? "BGV Agency"
                        : "Job Location"}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-joblocation"
                      placeholder={
                        location.state?.status === "bgv"
                          ? "Enter BGV Agency"
                          : "Enter Job Location"
                      }
                      {...register("name", {
                        required: true,
                      })}
                    />
                    {errors.name && (
                      <span className="fs-8 text-danger">
                        {location.state?.status === "bgv"
                          ? "BGV Agency"
                          : "Job Location"}{" "}
                        is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      {location.state?.status === "bgv"
                        ? "BGV Agency"
                        : "Job Location"}{" "}
                      value <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-value"
                      placeholder="Enter value"
                      {...register("value", {
                        required: true,
                      })}
                    />
                    {errors.value && (
                      <span className="fs-8 text-danger">
                        Value is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-description"
                      placeholder="Enter description"
                      {...register("description")}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-lg btn-light me-3"
                    id="button-button-backbutton"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  {submitLoading ? (
                    <button
                      id="button-loading"
                      className="btn text-dark"
                      disabled
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary"
                      id="button-button-submit_job_location"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobLocationCreate;
