import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";

export const ResumeAdvanceFilterContext = createContext(null);

function ResumeAdvanceFilterProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  console.log(selectedPlatform);
  const [selectedStatus, setSelectedStatus] = useState([]);
  console.log(selectedStatus);
  const [selectedStage, setSelectedStage] = useState([]);
  console.log(selectedStage);

  // userdetails stored in "rawUserDetails"
  const [isLoading, setIsLoading] = useState(true);
  const [isData, setIsData] = useState(false);
  const [shortlistedResumeData, setShortlistedResumeData] = useState([]);
  const [resumeEntries, setResumeEntries] = useState(5);
  const [resumeCount, setResumeCount] = useState(null);
  const [resumePageCount, setResumePageCount] = useState(0);

  const [selectedDate, setSelectedDate] = useState(null);

  const platformLabels = selectedPlatform
    .map((option) => option.value)
    .join(",");
  const statusLabels = selectedStatus.map((option) => option.value).join(",");
  const stageLabels = selectedStage.map((option) => option.value).join(",");

  const getAdvanceResumeFilterData = async (page, searchRequest, access) => {
    const combinedLabels = [
      ...selectedPlatform,
      ...selectedStatus,
      ...selectedStage,
    ]
      .map((option) => option.value)
      .join(",");
    let url = VARIABLES.url + `/api/advanced-search/?entries=${resumeEntries}&page=${page}`;

    if (!searchRequest) {
      if (platformLabels) {
        url += `&platform=${platformLabels}`;
      }
      if (statusLabels) {
        url += `&status=${statusLabels}`;
      }
      if (stageLabels) {
        url += `&stage=${stageLabels}`;
      }
      if (selectedDate) {
        url += `&event=${selectedDate}`;
      }
    } else {
      url = VARIABLES.url + `/api/advanced-search/?entries=${resumeEntries}&page=${page}&event=${searchRequest}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    setIsLoading(true);
    try {
      const res = await axios(config);
      const response = res.data.data;
      setIsLoading(false);
      if (response.length !== 0) {
        setIsData(false);
        setShortlistedResumeData(res.data.data);
        console.log(res.data.data);
      } else {
        setIsData(true);
        setShortlistedResumeData(res.data.data);
      }
      const total = res.data.count;
      setResumeCount(total);
      setResumePageCount(Math.ceil(total / resumeEntries));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsData(false);
      } else {
        setIsData(true);
      }
    }
  };

  const handleResumeClick = async (data, searchRequest) => {
    const access = secureLocalStorage.getItem("access");
    console.log(access);
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAdvanceResumeFilterData(
      currentPage,
      searchRequest,
      access
    );
  };

  return (
    <div>
      <ResumeAdvanceFilterContext.Provider
        value={{
          shortlistedResumeData,
          getAdvanceResumeFilterData,
          resumeEntries,
          resumePageCount,
          setResumeEntries,
          resumeCount,
          handleResumeClick,
          isData,
          isLoading,
          setSelectedPlatform,
          setSelectedStatus,
          setSelectedStage,
          selectedStatus,
          selectedPlatform,
          selectedStage,

          setSelectedDate,
          selectedDate,
        }}
      >
        {children}
      </ResumeAdvanceFilterContext.Provider>
    </div>
  );
}

export default ResumeAdvanceFilterProvider;
