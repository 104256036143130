import React, { useContext, useEffect } from "react";
import { CandidateDetailsContext } from "../../../Context/CandidateDetailsContext";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation } from "react-router-dom";

const AdditionalDetails = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const location = useLocation();

  const {
    candidateAdditionalData,
    isCandidateAdditionalDataLoading,
    getCandidateAdditionalData,
  } = useContext(CandidateDetailsContext);
  console.log(candidateAdditionalData);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getCandidateAdditionalData(
        location?.state?.candidate_id,
        location?.state?.requisition_id,
        access
      );
    } else {
      let access = secureLocalStorage.getItem("access");
      getCandidateAdditionalData(
        location?.state?.candidate_id,
        location?.state?.requisition_id,
        access
      );
    }
  }, [isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Candidate's Additional Details</h4>
            {isCandidateAdditionalDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        {candidateAdditionalData == 0 && (
          <div>Additional details not found</div>
        )}
        {candidateAdditionalData.length > 0 && (
          <div className="row">
            {candidateAdditionalData?.map((info, index) => (
              <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
                <div className="card">
                  <div className="card-aside-wrap">
                    <div className="card-content">
                      <div className="card-inner">
                        <div className="nk-block">
                          <div className="profile-ud-list">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Notice Period
                                </span>
                                <span className="profile-ud-value">
                                  {info?.notice_period || "-"}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Current/Previous Company
                                </span>
                                <span className="profile-ud-value">
                                  {info?.current_previous_company || "-"}
                                </span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Experience
                                </span>
                                <span className="profile-ud-value">
                                  {info?.experience || "-"}
                                </span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Expected Salary
                                </span>
                                <span className="profile-ud-value">
                                  {info?.expected_salary || "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default AdditionalDetails;
