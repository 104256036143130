import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { SettingContext } from "../../../Context/SettingsContext";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";

const EditPrivilege = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  let location = useLocation();
  console.log(location);
  let navigate = useNavigate();

  const { getPrivilege } = useContext(SettingContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    var privileges = JSON.stringify({
      privilege_name: data.privilege_name,
      description: data.description,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/privileges/${location.state.editpost.id}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: privileges,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getPrivilege();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Privileges Updated Successfully.",
        });
        reset();
        setTimeout(() => {
          navigate("/privilege");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
        setSubmitLoading(false);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Fail to Update the Privileges",
        });
      });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedData = location.state.editpost;
    if (editedData) {
      var defaultValues = {};
      defaultValues.privilege_name = editedData.privilege_name;
      defaultValues.description = editedData.description;
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Edit Privilege</h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label class="form-label">
                      Privilege Name : <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-privilege_name"
                      placeholder="Enter privilege name"
                      {...register("privilege_name", {
                        required: true,
                      })} />
                    {errors.privilege_name && (
                      <span className="fs-8 text-danger">
                        Privilege name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Privilege Value </label>
                    <div className="form-control">
                      {location.state.editpost?.privilege_value}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="text-forms-description"
                      placeholder="Enter Description"
                      {...register("description", { required: true })} />
                    {errors.description &&
                      errors.description.type === "required" && (
                        <span className="fs-8 text-danger">
                          Description is required
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-secondary me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}>
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-privileges">
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}>
                  <em className={`icon ni ni-${submitMessage.icon}-circle`}></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditPrivilege;
