import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { UserDetailsContext } from "../../Context/UserDetailsContext";

const CreateOfficialEmail = (props) => {
  const { getUserDetailsData } = useContext(UserDetailsContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  console.log(props);

  const userID = secureLocalStorage.getItem("userID");
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const emailValidation = props?.emailData?.role?.find((rl) => rl.role_name === 'Candidate')
  console.log(emailValidation)


  const onSubmit = (data) => {
    let user = new FormData();
    user.append("official_email", data?.officialemail);
    user.append("updated_by_id", userID);

    const url = VARIABLES.url + `/api/userdetails/${props.emailData.user.id}/`;
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      // headers: {
      //   'Content-Type': 'application/json'
      // },
      data: user,
    };

    console.log(user);

    setIsLoading(true);
    axios(config)
      .then((response) => {
        setIsLoading(false);
        console.log(JSON.stringify(response.data));
        getUserDetailsData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Official email created successfully",
        });
        document.getElementById("button-button-closemodal").click();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to edit official email",
        });
      });
    reset();
  };

  useEffect(() => {
    if (props?.emailData) {
      var defaultValues = {};

      defaultValues.officialemail = props?.emailData?.official_email;

      reset(defaultValues);
    }
  }, [props]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="emailedit"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {props?.emailData?.user?.first_name}{" "}
                {props?.emailData?.user?.last_name}
              </h5>
              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-12">
                    {emailValidation ? (
                      <div className="form-group">
                        <label className="form-label">Official Email</label>
                        <span className="text-danger">*</span>
                        <input
                          type="email"
                          className="form-control"
                          id="email-forms-email"
                          placeholder="Enter official email"
                          {...register("officialemail", {
                            required: "Email is required",
                            validate: (value) =>
                              value === value.toLowerCase() ||
                              "Email should only contain lowercase letters",
                          })}
                        />
                        {errors.officialemail && (
                          <span className="fs-8 text-danger">
                            {errors?.officialemail?.message}
                          </span>
                        )}
                      </div>
                    ) : (
                      <h6 className="text-danger">Official email can only be assigned to users with the Candidate role.</h6>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {emailValidation ? (
                  <>
                    {isLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="submit-button-editemail"
                      >
                        Submit
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      reset();
                    }}
                  >Ok</button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateOfficialEmail;
