import axios from "axios";
import React, { useState, useContext } from "react";
import { createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const SettingContext = createContext(null);

function SettingContextProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  // To store Role Data.
  const [isRoleData, setIsRoleData] = useState(false);
  const [rawRole, setRawRole] = useState([]);
  const [roleEntries, setRoleEntries] = useState(5);
  const [roleCount, setRoleCount] = useState(null);
  const [rolePageCount, setRolePageCount] = useState(0);
  const [isRoleLoading, setIsRoleLoading] = useState(false);

  // To store Type Master Data.
  const [isTypeMasterData, setIsTypeMasterData] = useState(false);
  const [rawTypeMaster, setRawTypeMaster] = useState([]);
  const [typeMasterEntries, setTypeMasterEntries] = useState(5);
  const [typeMasterCount, setTypeMasterCount] = useState(null);
  const [typeMasterPageCount, setTypeMasterPageCount] = useState(0);
  const [isTypeMasterLoading, setIsTypeMasterLoading] = useState(false);

  // To store Privilege Data.
  const [isPrivilegeData, setIsPrivilegeData] = useState(false);
  const [rawPrivilege, setRawPrivilege] = useState([]);
  const [privilegeEntries, setPrivilegeEntries] = useState(5);
  const [privilegeCount, setPrivilegeCount] = useState(null);
  const [privilegePageCount, setPrivilegePageCount] = useState(0);
  const [isPrivilegeLoading, setIsPrivilegeLoading] = useState(false);

  // To store Version Tracker Data.
  const [isVersionTrackerData, setIsVersionTrackerData] = useState(false);
  const [rawVersionTracker, setRawVersionTracker] = useState([]);
  const [versionTrackerEntries, setVersionTrackerEntries] = useState(5);
  const [versionTrackerCount, setVersionTrackerCount] = useState(null);
  const [versionTrackerPageCount, setVersionTrackerPageCount] = useState(0);
  const [isVersionTrackerLoading, setIsVersionTrackerLoading] = useState(false);

  // "GetRole" API call to get role data
  const getRole = async (page, searchRole, access) => {
    setIsRoleLoading(true);
    console.log(searchRole);
    let url;
    if (searchRole) {
      url =
        VARIABLES.url +
        `/api/roles/?entries=${roleEntries}&page=${page}&event=${searchRole}`;
    } else {
      url = VARIABLES.url + `/api/roles/?entries=${roleEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsRoleLoading(false);
      if (response.length !== 0) {
        setIsRoleData(false);
        setRawRole(res.data.data);
      } else {
        setIsRoleData(true);
        setRawRole(res.data.data);
      }
      const total = res.data.count;
      setRoleCount(total);
      setRolePageCount(Math.ceil(total / roleEntries));
    } catch (error) {
      console.log(error);
      setIsRoleLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsRoleData(false);
      } else {
        setIsRoleData(true);
      }
    }
  };

  const handleRoleClick = async (data, searchRole) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRole(currentPage, searchRole, access);
  };

  // "GetTypeMaster" API call to get role data
  const getTypeMaster = async (page, searchMaster, access) => {
    setIsTypeMasterLoading(true);
    console.log(searchMaster);
    let url;
    if (searchMaster) {
      url =
        VARIABLES.url +
        `/api/typemaster/?entries=${typeMasterEntries}&page=${page}&event=${searchMaster}`;
    } else {
      url =
        VARIABLES.url +
        `/api/typemaster/?entries=${typeMasterEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsTypeMasterLoading(false);
      if (response.length !== 0) {
        setIsTypeMasterData(false);
        setRawTypeMaster(res.data.data);
      } else {
        setIsTypeMasterData(true);
        setRawTypeMaster(res.data.data);
      }
      const total = res.data.count;
      setTypeMasterCount(total);
      setTypeMasterPageCount(Math.ceil(total / typeMasterEntries));
    } catch (error) {
      console.log(error);
      setIsTypeMasterLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsTypeMasterData(false);
      } else {
        setIsTypeMasterData(true);
      }
    }
  };

  const handleTypeMasterClick = async (data, searchMaster) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getTypeMaster(
      currentPage,
      searchMaster,
      access
    );
  };

  // "GetPrivilege" API call to get privilege data
  const getPrivilege = async (page, searchPrivilege, access) => {
    setIsPrivilegeLoading(true);
    console.log(searchPrivilege);
    let url;
    if (searchPrivilege) {
      url =
        VARIABLES.url +
        `/api/privileges/?entries=${privilegeEntries}&page=${page}&event=${searchPrivilege}`;
    } else {
      url =
        VARIABLES.url +
        `/api/privileges/?entries=${privilegeEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsPrivilegeLoading(false);
      if (response.length !== 0) {
        setIsPrivilegeData(false);
        setRawPrivilege(res.data.data);
      } else {
        setIsPrivilegeData(true);
        setRawPrivilege(res.data.data);
      }
      const total = res.data.count;
      setPrivilegeCount(total);
      setPrivilegePageCount(Math.ceil(total / privilegeEntries));
    } catch (error) {
      console.log(error);
      setIsPrivilegeLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsPrivilegeData(false);
      } else {
        setIsPrivilegeData(true);
      }
    }
  };

  const handlePrivilegeClick = async (data, searchPrivilege) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getPrivilege(
      currentPage,
      searchPrivilege,
      access
    );
  };

  // "GetVersionTracker" API call to get Version Tracker data
  const getVersionTracker = async (page, searchVersionTracker, access) => {
    setIsVersionTrackerLoading(true);
    console.log(searchVersionTracker);
    let url;
    if (searchVersionTracker) {
      url =
        VARIABLES.url +
        `/api/versiontracker/?entries=${versionTrackerEntries}&page=${page}&event=${searchVersionTracker}`;
    } else {
      url =
        VARIABLES.url +
        `/api/versiontracker/?entries=${versionTrackerEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsVersionTrackerLoading(false);
      if (response.length !== 0) {
        setIsVersionTrackerData(false);
        setRawVersionTracker(res.data.data);
      } else {
        setIsVersionTrackerData(true);
        setRawVersionTracker(res.data.data);
      }
      const total = res.data.count;
      setVersionTrackerCount(total);
      setVersionTrackerPageCount(Math.ceil(total / versionTrackerEntries));
    } catch (error) {
      console.log(error);
      setIsVersionTrackerLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsVersionTrackerData(false);
      } else {
        setIsVersionTrackerData(true);
      }
    }
  };

  const handleVersionTrackerClick = async (data, searchVersionTracker) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getVersionTracker(
      currentPage,
      searchVersionTracker,
      access
    );
  };

  const value = {
    rawRole,
    getRole,
    roleEntries,
    rolePageCount,
    setRoleEntries,
    roleCount,
    handleRoleClick,
    isRoleData,
    isRoleLoading,

    rawTypeMaster,
    getTypeMaster,
    typeMasterEntries,
    typeMasterPageCount,
    setTypeMasterEntries,
    typeMasterCount,
    handleTypeMasterClick,
    isTypeMasterData,
    isTypeMasterLoading,

    rawPrivilege,
    getPrivilege,
    privilegeEntries,
    privilegePageCount,
    setPrivilegeEntries,
    privilegeCount,
    handlePrivilegeClick,
    isPrivilegeData,
    isPrivilegeLoading,

    rawVersionTracker,
    getVersionTracker,
    versionTrackerEntries,
    versionTrackerPageCount,
    setVersionTrackerEntries,
    versionTrackerCount,
    handleVersionTrackerClick,
    isVersionTrackerData,
    isVersionTrackerLoading,
  };
  return (
    <div>
      <SettingContext.Provider value={value}>
        {children}
      </SettingContext.Provider>
    </div>
  );
}

export default SettingContextProvider;
