// export const Formdata = {
//   "tabs": [
//     {
//       "id": 1,
//       "name": "Tab 1",
//       "parent_id": 0,
//       "sequence": 1,
//       "depends_on": [0],
//       "form": null,
//       "WF": 1,
//       "tabs": [
//         {
//           "id": 2,
//           "name": "Tab 1.1",
//           "parent_id": 1,
//           "sequence": 1,
//           "depends_on": [0],
//           "form": {
//             "id" : 1,
//             "form_path" : "Stepper/AssignAssignment/AssignAssignment",
//             "form_name" : "Assign Assignment"
//           },
//           "WF": 1,
//           "tabs": [],
//         },
//         {
//           "id": 3,
//           "name": "Tab 1.2",
//           "parent_id": 1,
//           "sequence": 2,
//           "depends_on": [2],
//           "form": {
//             "id" : 2,
//             "form_path" : "Stepper/AssignmentReview/AssignmentReview",
//             "form_name" : "Assignment Review"
//           },
//           "WF": 1,
//         "tabs": [],
//         },
//       ]
//     },
//     {
//       "id": 4,
//       "name": "Tab 2",
//       "parent_id": 0,
//       "sequence": 2,
//       "depends_on": [2,3],
//       "form": {
//         "id" : 3,
//         "form_path" : "Stepper/MettleTest/MettleTest",
//         "form_name" : "Test"
//       },
//       "WF": 1,
//         "tabs": [],
//     },
//     {
//       "id": 5,
//       "name": "Tab 3",
//       "parent_id": 0,
//       "sequence": 3,
//       "depends_on": [0],
//       "form": null,
//       "WF": 1,
//       "tabs": [
//         {
//           "id": 6,
//           "name": "Tab 3.1",
//           "parent_id": 5,
//           "sequence": 1,
//           "depends_on": [0],
//           "form": {
//             "id" : 4,
//             "form_path" : "Stepper/MettleTestReview/MettleTestReview",
//             "form_name" : "Test Review"
//           },
//           "WF": 1,
//           "tabs": [],
//         },
//         {
//           "id": 7,
//           "name": "Tab 3.2",
//           "parent_id": 5,
//           "sequence": 2,
//           "depends_on": [2,6],
//           "form": null,
//           "WF": 1,
//           "tabs": [
//             {
//               "id": 8,
//               "name": "Tab 3.2.1",
//               "parent_id": 7,
//               "sequence": 1,
//               "depends_on": [7],
//               "form": {
//                 "id" : 1,
//                 "form_path" : "Stepper/SendOffer/SendOffer",
//                 "form_name" : "Send Offer"
//               },
//               "WF": 1,
//                "tabs": [],
//             }
//           ]
//         }
//       ]
//     }
//   ],
//   "sequence": [[0, 0, 0], [0, 1], [1, 0], [2, 0], [2, 1, 0], [3]]
// };

export const candidateFormWorkflow = {
  "candidate_job_id": 1350,
  "total_progress": 29,
  "tabs": [
    {
      "id": 16535,
      "name": "Personal Detailsss",
      "parent_id": 0,
      "sequence": 1,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 17,
        "form_name": "Personal Details Retrieve",
        "form_path": "Stepper/CandidateDetails/PersonalDetails"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16358,
      "name": "Candidate Details",
      "parent_id": 0,
      "sequence": 1,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16359,
          "name": "Personal Details",
          "parent_id": 16358,
          "sequence": 2,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 17,
            "form_name": "Personal Details Retrieve",
            "form_path": "Stepper/CandidateDetails/PersonalDetails"
          },
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16360,
          "name": "Educational Details",
          "parent_id": 16358,
          "sequence": 3,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 18,
            "form_name": "Educational Details Retrieve",
            "form_path": "Stepper/CandidateDetails/EducationalDetails"
          },
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16361,
          "name": "Professional Details",
          "parent_id": 16358,
          "sequence": 4,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 19,
            "form_name": "Professional Details Retrieve",
            "form_path": "Stepper/CandidateDetails/ProfessionalDetails"
          },
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16362,
          "name": "Additional Details",
          "parent_id": 16358,
          "sequence": 5,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 20,
            "form_name": "Additional Details Retrieve",
            "form_path": "Stepper/CandidateDetails/AdditionalDetails"
          },
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16536,
      "name": "Educational Details",
      "parent_id": 0,
      "sequence": 2,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 18,
        "form_name": "Educational Details Retrieve",
        "form_path": "Stepper/CandidateDetails/EducationalDetails"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16537,
      "name": "Professional Details",
      "parent_id": 0,
      "sequence": 3,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 19,
        "form_name": "Professional Details Retrieve",
        "form_path": "Stepper/CandidateDetails/ProfessionalDetails"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16538,
      "name": "Additional Details",
      "parent_id": 0,
      "sequence": 4,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 20,
        "form_name": "Additional Details Retrieve",
        "form_path": "Stepper/CandidateDetails/AdditionalDetails"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16539,
      "name": "Telephonic Round",
      "parent_id": 0,
      "sequence": 5,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16540,
          "name": "Telephonic Interview",
          "parent_id": 16539,
          "sequence": 6,
          "depends_on": [
            0
          ],
          "stage": 1,
          "status": "Scheduled",
          "form": {
            "id": 13,
            "form_name": "Telephonic Interview",
            "form_path": "Stepper/TelephonicInterviewRound/TelephonicRound"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16541,
          "name": "Telephonic Interview Review",
          "parent_id": 16539,
          "sequence": 7,
          "depends_on": [
            16540
          ],
          "stage": 1,
          "status": "Scheduled",
          "form": {
            "id": 24,
            "form_name": "Telephonic Interview Review",
            "form_path": "Stepper/TelephonicInterviewRound/TelephonicInterviewReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16363,
      "name": "Interview Details",
      "parent_id": 0,
      "sequence": 6,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16364,
          "name": "Telephonic Interview",
          "parent_id": 16363,
          "sequence": 7,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16365,
              "name": "Interview",
              "parent_id": 16364,
              "sequence": 8,
              "depends_on": [
                0
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 13,
                "form_name": "Telephonic Interview",
                "form_path": "Stepper/TelephonicInterviewRound/TelephonicRound"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16366,
              "name": "Review",
              "parent_id": 16364,
              "sequence": 9,
              "depends_on": [
                16365
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 24,
                "form_name": "Telephonic Interview Review",
                "form_path": "Stepper/TelephonicInterviewRound/TelephonicInterviewReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16367,
          "name": "Technical Interview",
          "parent_id": 16363,
          "sequence": 10,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16368,
              "name": "Interview",
              "parent_id": 16367,
              "sequence": 11,
              "depends_on": [
                16366
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 11,
                "form_name": "Technical Interview",
                "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewRound"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16369,
              "name": "Review",
              "parent_id": 16367,
              "sequence": 12,
              "depends_on": [
                16368
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 22,
                "form_name": "Technical Interview Review",
                "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16542,
      "name": "HR Round",
      "parent_id": 0,
      "sequence": 8,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16543,
          "name": "HR Interview",
          "parent_id": 16542,
          "sequence": 9,
          "depends_on": [
            0
          ],
          "stage": 1,
          "status": "Scheduled",
          "form": {
            "id": 12,
            "form_name": "HR Interview",
            "form_path": "Stepper/HRInterviewRound/HRInterviewRound"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16544,
          "name": "HR Interview Review",
          "parent_id": 16542,
          "sequence": 10,
          "depends_on": [
            0
          ],
          "stage": 1,
          "status": "Scheduled",
          "form": {
            "id": 25,
            "form_name": "HR Interview Review",
            "form_path": "Stepper/HRInterviewRound/HRInterviewReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16545,
      "name": "Technical Round",
      "parent_id": 0,
      "sequence": 11,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16546,
          "name": "Technical Interview",
          "parent_id": 16545,
          "sequence": 12,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 11,
            "form_name": "Technical Interview",
            "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewRound"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16547,
          "name": "Technical Interview Review",
          "parent_id": 16545,
          "sequence": 13,
          "depends_on": [
            16546
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 22,
            "form_name": "Technical Interview Review",
            "form_path": "Stepper/TechnicalInterviewRound/TechnicalInterviewReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16370,
      "name": "Assessment",
      "parent_id": 0,
      "sequence": 13,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16371,
          "name": "Assignment",
          "parent_id": 16370,
          "sequence": 14,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16372,
              "name": "Assign",
              "parent_id": 16371,
              "sequence": 15,
              "depends_on": [
                16369
              ],
              "stage": 2,
              "status": "In progress",
              "form": {
                "id": 2,
                "form_name": "Assign Assignment",
                "form_path": "Stepper/AssignAssignment/AssignAssignment"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16373,
              "name": "Upload",
              "parent_id": 16371,
              "sequence": 16,
              "depends_on": [
                16372
              ],
              "stage": 2,
              "status": "In progress",
              "form": {
                "id": 32,
                "form_name": "Assignment Upload",
                "form_path": "Stepper/AssignAssignment/AssignmentUpload"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": null,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16374,
              "name": "Review",
              "parent_id": 16371,
              "sequence": 17,
              "depends_on": [
                16373
              ],
              "stage": 2,
              "status": "In progress",
              "form": {
                "id": 1,
                "form_name": "Assignment Review",
                "form_path": "Stepper/AssignmentReview/AssignmentReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16375,
          "name": "Psychometric Test",
          "parent_id": 16370,
          "sequence": 18,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16376,
              "name": "Assign",
              "parent_id": 16375,
              "sequence": 19,
              "depends_on": [
                16374
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 4,
                "form_name": "Test",
                "form_path": "Stepper/MettleTest/MettleTest"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16377,
              "name": "Review",
              "parent_id": 16375,
              "sequence": 20,
              "depends_on": [
                16376
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 5,
                "form_name": "Test Review",
                "form_path": "Stepper/MettleTestReview/MettleTestReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16378,
          "name": "Smart Hire Form",
          "parent_id": 16370,
          "sequence": 21,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16379,
              "name": "Assign",
              "parent_id": 16378,
              "sequence": 22,
              "depends_on": [
                16377
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 14,
                "form_name": "Smart Hire Form",
                "form_path": "Stepper/SmartHireForm/SmartHireForm"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16380,
              "name": "Review",
              "parent_id": 16378,
              "sequence": 23,
              "depends_on": [
                16379
              ],
              "stage": 3,
              "status": "Completed",
              "form": {
                "id": 23,
                "form_name": "Smart Hire Form Review",
                "form_path": "Stepper/SmartHireForm/SmartHireFormReview"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16548,
      "name": "Assignment Round",
      "parent_id": 0,
      "sequence": 14,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16549,
          "name": "Assign Assignment",
          "parent_id": 16548,
          "sequence": 15,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 2,
            "form_name": "Assign Assignment",
            "form_path": "Stepper/AssignAssignment/AssignAssignment"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16550,
          "name": "Assignment Review",
          "parent_id": 16548,
          "sequence": 16,
          "depends_on": [
            16549
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 1,
            "form_name": "Assignment Review",
            "form_path": "Stepper/AssignmentReview/AssignmentReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16551,
      "name": "Mettl Test Round",
      "parent_id": 0,
      "sequence": 17,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16552,
          "name": "Mettl Test",
          "parent_id": 16551,
          "sequence": 18,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 4,
            "form_name": "Test",
            "form_path": "Stepper/MettleTest/MettleTest"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16553,
          "name": "Mettl Test Review",
          "parent_id": 16551,
          "sequence": 19,
          "depends_on": [
            16552
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 5,
            "form_name": "Test Review",
            "form_path": "Stepper/MettleTestReview/MettleTestReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16554,
      "name": "Smart Hire Form Round",
      "parent_id": 0,
      "sequence": 20,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16555,
          "name": "Smart Hire Form",
          "parent_id": 16554,
          "sequence": 21,
          "depends_on": [
            0
          ],
          "stage": 1,
          "status": "Scheduled",
          "selection_status": 0,
          "form": {
            "id": 14,
            "form_name": "Smart Hire Form",
            "form_path": "Stepper/SmartHireForm/SmartHireForm"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16556,
          "name": "Smart Hire Form Review",
          "parent_id": 16554,
          "sequence": 22,
          "depends_on": [
            16555
          ],
          "stage": 1,
          "status": "Scheduled",
          "selection_status": 1,
          "form": {
            "id": 23,
            "form_name": "Smart Hire Form Review",
            "form_path": "Stepper/SmartHireForm/SmartHireFormReview"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16557,
      "name": "Know Your Candidate",
      "parent_id": 0,
      "sequence": 23,
      "depends_on": [
        0
      ],
      "stage": 3,
      "status": "Completed",
      "selection_status": 2,
      "form": {
        "id": 16,
        "form_name": "KYC",
        "form_path": "Stepper/KYC/KYC"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16381,
      "name": "HRD Approval",
      "parent_id": 0,
      "sequence": 24,
      "depends_on": [
        16380
      ],
      "stage": 3,
      "status": "Completed",
      "selection_status": 2,
      "form": {
        "id": 15,
        "form_name": "HRD Approval",
        "form_path": "Stepper/HRDApproval/HRDApproval"
      },
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16558,
      "name": "HRD Approval",
      "parent_id": 0,
      "sequence": 24,
      "depends_on": [
        0
      ],
      "stage": 3,
      "status": "Completed",
      "selection_status": 2,
      "form": {
        "id": 15,
        "form_name": "HRD Approval",
        "form_path": "Stepper/HRDApproval/HRDApproval"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16382,
      "name": "MD Approval",
      "parent_id": 0,
      "sequence": 25,
      "depends_on": [
        16381
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 3,
        "form_name": "MD Approval",
        "form_path": "Stepper/MDApproval/MDApproval"
      },
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16559,
      "name": "MD Approval",
      "parent_id": 0,
      "sequence": 25,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": {
        "id": 3,
        "form_name": "MD Approval",
        "form_path": "Stepper/MDApproval/MDApproval"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16383,
      "name": "Know Your Candidate",
      "parent_id": 0,
      "sequence": 26,
      "depends_on": [
        16382
      ],
      "stage": 3,
      "status": "Completed",
      "form": {
        "id": 16,
        "form_name": "KYC",
        "form_path": "Stepper/KYC/KYC"
      },
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16384,
      "name": "Offer",
      "parent_id": 0,
      "sequence": 27,
      "depends_on": [
        0
      ],
      "stage": 3,
      "status": "Completed",
      "form": {
        "id": 6,
        "form_name": "Send Offer",
        "form_path": "Stepper/SendOffer/SendOffer"
      },
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16560,
      "name": "Send Offer",
      "parent_id": 0,
      "sequence": 27,
      "depends_on": [
        0
      ],
      "stage": 3,
      "status": "Completed",
      "form": {
        "id": 6,
        "form_name": "Send Offer",
        "form_path": "Stepper/SendOffer/SendOffer"
      },
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [],
      "level": 0
    },
    {
      "id": 16385,
      "name": "Background Verification",
      "parent_id": 0,
      "sequence": 28,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 67,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16386,
          "name": "Background Verification 1",
          "parent_id": 16385,
          "sequence": 29,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16387,
              "name": "Upload",
              "parent_id": 16386,
              "sequence": 30,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 26,
                "form_name": "Background Verification one",
                "form_path": "Stepper/BackgroundVerification/BGV1"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16388,
              "name": "Review",
              "parent_id": 16386,
              "sequence": 31,
              "depends_on": [
                16387
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 29,
                "form_name": "Background Verification one Review",
                "form_path": "Stepper/BackgroundVerification/BGV1Review"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16389,
          "name": "Background Verification 2",
          "parent_id": 16385,
          "sequence": 32,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16390,
              "name": "Upload",
              "parent_id": 16389,
              "sequence": 33,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 27,
                "form_name": "Background Verification two",
                "form_path": "Stepper/BackgroundVerification/BGV2"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16391,
              "name": "Review",
              "parent_id": 16389,
              "sequence": 34,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 30,
                "form_name": "Background Verification two Review",
                "form_path": "Stepper/BackgroundVerification/BGV2Review"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        },
        {
          "id": 16392,
          "name": "Background Verification 3",
          "parent_id": 16385,
          "sequence": 35,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": null,
          "WF": 67,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [
            {
              "id": 16393,
              "name": "Upload",
              "parent_id": 16392,
              "sequence": 36,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 28,
                "form_name": "Background Verification three",
                "form_path": "Stepper/BackgroundVerification/BGV3"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            },
            {
              "id": 16394,
              "name": "Review",
              "parent_id": 16392,
              "sequence": 37,
              "depends_on": [
                0
              ],
              "stage": 0,
              "status": "Future Scheduled",
              "form": {
                "id": 31,
                "form_name": "Background Verification three Review",
                "form_path": "Stepper/BackgroundVerification/BGV3Review"
              },
              "WF": 67,
              "completion_id": 0,
              "is_iterable": false,
              "tabs": [],
              "level": 2
            }
          ],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16561,
      "name": "Background Verification Round 1",
      "parent_id": 0,
      "sequence": 28,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16562,
          "name": "Background Verification 1",
          "parent_id": 16561,
          "sequence": 29,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 26,
            "form_name": "Background Verification one",
            "form_path": "Stepper/BackgroundVerification/BGV1"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16563,
          "name": "Background Verification Review 1",
          "parent_id": 16561,
          "sequence": 30,
          "depends_on": [
            16562
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 29,
            "form_name": "Background Verification one Review",
            "form_path": "Stepper/BackgroundVerification/BGV1Review"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16564,
      "name": "Background Verification Round 2",
      "parent_id": 0,
      "sequence": 31,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16565,
          "name": "Background Verification 2",
          "parent_id": 16564,
          "sequence": 32,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 27,
            "form_name": "Background Verification two",
            "form_path": "Stepper/BackgroundVerification/BGV2"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16566,
          "name": "Background Verification Review 2",
          "parent_id": 16564,
          "sequence": 33,
          "depends_on": [
            16565
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 30,
            "form_name": "Background Verification two Review",
            "form_path": "Stepper/BackgroundVerification/BGV2Review"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    },
    {
      "id": 16567,
      "name": "Background Verification Round 3",
      "parent_id": 0,
      "sequence": 34,
      "depends_on": [
        0
      ],
      "stage": 0,
      "status": "Future Scheduled",
      "form": null,
      "WF": 51,
      "completion_id": 0,
      "is_iterable": false,
      "tabs": [
        {
          "id": 16568,
          "name": "Background Verification 3",
          "parent_id": 16567,
          "sequence": 35,
          "depends_on": [
            0
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 28,
            "form_name": "Background Verification three",
            "form_path": "Stepper/BackgroundVerification/BGV3"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        },
        {
          "id": 16569,
          "name": "Background Verification Review 3",
          "parent_id": 16567,
          "sequence": 36,
          "depends_on": [
            16568
          ],
          "stage": 0,
          "status": "Future Scheduled",
          "form": {
            "id": 31,
            "form_name": "Background Verification three Review",
            "form_path": "Stepper/BackgroundVerification/BGV3Review"
          },
          "WF": 51,
          "completion_id": 0,
          "is_iterable": false,
          "tabs": [],
          "level": 1
        }
      ],
      "level": 0
    }
  ],
  "sequence": [
    [
      0
    ],
    [
      1,
      0
    ],
    [
      1,
      1
    ],
    [
      1,
      2
    ],
    [
      1,
      3
    ],
    [
      2
    ],
    [
      3
    ],
    [
      4
    ],
    [
      5,
      0
    ],
    [
      5,
      1
    ],
    [
      6,
      0,
      0
    ],
    [
      6,
      0,
      1
    ],
    [
      6,
      1,
      0
    ],
    [
      6,
      1,
      1
    ],
    [
      7,
      0
    ],
    [
      7,
      1
    ],
    [
      8,
      0
    ],
    [
      8,
      1
    ],
    [
      9,
      0,
      0
    ],
    [
      9,
      0,
      1
    ],
    [
      9,
      0,
      2
    ],
    [
      9,
      1,
      0
    ],
    [
      9,
      1,
      1
    ],
    [
      9,
      2,
      0
    ],
    [
      9,
      2,
      1
    ],
    [
      10,
      0
    ],
    [
      10,
      1
    ],
    [
      11,
      0
    ],
    [
      11,
      1
    ],
    [
      12,
      0
    ],
    [
      12,
      1
    ],
    [
      13
    ],
    [
      14
    ],
    [
      15
    ],
    [
      16
    ],
    [
      17
    ],
    [
      18
    ],
    [
      19
    ],
    [
      20
    ],
    [
      21,
      0,
      0
    ],
    [
      21,
      0,
      1
    ],
    [
      21,
      1,
      0
    ],
    [
      21,
      1,
      1
    ],
    [
      21,
      2,
      0
    ],
    [
      21,
      2,
      1
    ],
    [
      22,
      0
    ],
    [
      22,
      1
    ],
    [
      23,
      0
    ],
    [
      23,
      1
    ],
    [
      24,
      0
    ],
    [
      24,
      1
    ]
  ]
};

export const session = {
  session: [
    {
      id: 1,
      session_id: "Null",
      tab_id: 0,
      wf: 1,
      user_id: 1,
    },
    {
      id: 2,
      session_id: "Null",
      tab_id: 140,
      wf: 1,
      user_id: 1,
    },
    {
      id: 3,
      session_id: "ABC",
      tab_id: 139,
      wf: 1,
      user_id: 1,
    },
  ],
};

export const configurablePrivilege = {
  session: [
    {
      id: 1,
      session_id: "Null",
      tab_id: 140,
      wf: 1,
      user_id: 1,
    },
    {
      id: 2,
      session_id: "ABC",
      tab_id: 139,
      wf: 1,
      user_id: 1,
    },
  ],
};

export const TamOptions = [
  {
    label: "Naukri",
    value: "naukri",
  },
  {
    label: "FSC Careers",
    value: "FSC_careers",
  },
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
];

export const RE2Options = [
  {
    label: "Naukri",
    value: "naukri",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Updaaz",
    value: "Updaaz",
  },
  {
    label: "Hirist",
    value: "Hirist",
  },
];

export const TypemasterData = {
  data: [
    {
      category: {
        category_name: "Department",
      },
      name: "Software S/W",
      value: "1",
    },
    {
      category: {
        category_name: "Department",
      },
      name: "Hardware",
      value: "2",
    },
  ],
};

export const Typemaster = {
  data: {
    Department: [
      {
        name: "Software S/W",
        value: "1",
      },
      {
        name: "Hardware",
        value: "2",
      },
    ],
    Job: [
      {
        name: "Engineer",
        value: "1",
      },
      {
        name: "QA",
        value: "2",
      },
    ],
  },
};

export const RCQuestions = [
  {
    label: "Overqualified",
  },
  {
    label: "Overexperienced",
  },
  {
    label: "High potential but for another position",
  },
  {
    label: "Insufficient experience",
  },
  {
    label: "Irrelevant experience",
  },
  {
    label: "Lack of required skills",
  },
  {
    label: "Banned",
  },
];

export const TI = [
  {
    interview_id: 1,
    questions_data: [
      { question: "TI 4", score: "1", comments: "C1" },
      { question: "TI 3", score: "2", comments: "C2" },
      { question: "TI 2", score: "3", comments: "C3" },
    ],
    categories: [
      { category: "Communication", manualMarks: 2 },
      { category: "Coding", manualMarks: 4 },
    ],
    total_score: 6,
    feedback: "Feedback",
    candidate_status: "Draft",
    created_by_id: 3,
    updated_by_id: 3,
    workflow_session: {
      candidate_job_id: null,
      user: 3,
      created_by_id: 3,
      updated_by_id: 3,
    },
  },
];

export const MDApproval = [
  {
    candidate_job_id: 96,
    md_comment: "Comments",
    md_approval: "Draft",
    created_by_id: 41,
    updated_by_id: 41,
    workflow_session: {
      candidate_job_id: 96,
      tab_id: 288,
      workflow_id: 20,
      user: 41,
      created_by_id: 41,
      updated_by_id: 41,
    },
  },
];

export const Telephonic = [
  {
    requisition_id: null,
    candidate_job_id: null,
    interviewer_id: 3,
    interview_details: [
      {
        id: 1,
        question: "Six Days Working",
        answer: "Okay",
        anwser_status: true,
      },
      {
        id: 2,
        question: "Saturday & Sunday Rotational",
        answer: "Bad",
        anwser_status: true,
      },
      {
        id: 3,
        question: "Location Suitability",
        answer: "Bad",
        anwser_status: true,
      },
      { id: 4, question: "Notice Period", answer: 45, anwser_status: true },
      {
        id: 5,
        question: "Flexiable Notice Period",
        answer: "Good",
        anwser_status: true,
      },
      {
        id: 6,
        question: "Relevant vs Required Experience",
        answer: "2",
        anwser_status: true,
      },
      {
        id: 7,
        question: "Relevant Exp. Score",
        answer: "Okay",
        anwser_status: true,
      },
      {
        id: 8,
        question: "Technical Profile vs Skillsets",
        answer: "Good",
        anwser_status: true,
      },
      {
        id: 9,
        question: "Relevant Acedemic Validation Score",
        answer: "Good",
        anwser_status: true,
      },
      {
        id: 10,
        question: "Skillset to Profile Appropriation score",
        answer: "Okay",
        anwser_status: true,
      },
      {
        id: 11,
        question: "Stability Score",
        answer: "Bad",
        anwser_status: true,
      },
      {
        id: 12,
        question: "Candidate CTS vs Budget CTC",
        answer: "500000",
        anwser_status: true,
      },
      {
        id: 13,
        question: "Reason to change the job",
        answer: "Over Workload",
        anwser_status: true,
      },
    ],
    created_by_id: 3,
    updated_by_id: 3,
    workflow_session: {
      candidate_job_id: null,
      user: 3,
      created_by_id: 3,
      updated_by_id: 3,
    },
  },
];

export const KYCDATA = [
  {
    id: 1,
    label: "AADHAR CARD",
    document: "done",
  },
  {
    id: 2,
    label: "PAN CARD",
    document: null,
  },
  {
    id: 3,
    label: "Appointment Letter/Offer Letter",
    document: null,
  },
  {
    id: 4,
    label: "Appraisal/Increment Letter",
    document: "done",
  },
  {
    id: 5,
    label: "Last three months salary slip",
    document: null,
  },
  {
    id: 6,
    label: "Last six months salary account bank statement",
    document: "done",
  },
  {
    id: 7,
    label: "Recent passport size photograph",
    document: "done",
  },
];


export const AssignmentData = {
  "id": 108,
  "assignment_conducted_user": [
    {
      "id": 3,
      "first_name": "test",
      "last_name": "user"
    },
    {
      "id": 55,
      "first_name": "Kiran",
      "last_name": "K Kamble"
    }
  ],
  "choose_assignment": {
    "id": 79,
    "job_opening": 800,
    "assignment_title": "Aptitude-1",
    "assignment_document": null
  },
  "upload_assignment": "https://fsc-rms.s3.ap-south-1.amazonaws.com/media/Assignment_upload__assignment/CriminalJusticeResumedocx_f90MTGC.pdf",
  "feedback": "",
  "manager_feedback": "",
  "comments": "",
  "candidate_feedback": "",
  "selection_status": 1,
  "total_score": 5,
  "start_date": null,
  "end_date": null,
  "assignment_review_by": [
    {
      "user": {
        "id": 4,
        "first_name": "test",
        "last_name": "user"
      },
      "comments": "comments",
      "feedback": "Feedback",
      "score": 3.0,
      "selection_status": 1
    },
    {
      "user": {
        "id": 55,
        "first_name": "Kiran",
        "last_name": "K Kamble"
      },
      "comments": "comments",
      "feedback": "Feedback",
      "score": 3.0,
      "selection_status": 0
    },
    {
      "user": {
        "id": 55,
        "first_name": "Kiran",
        "last_name": "K Kamble"
      },
      "comments": "comments",
      "feedback": "Feedback",
      "score": 3.0,
      "selection_status": 2
    }
  ]
}

export const TechnicalData = {
  "interview_details": {
    "id": 753,
    "interview_mode": "Online",
    "interviewer_name": [
      {
        "id": 3,
        "first_name": "test",
        "last_name": "user"
      }
    ],
    "interview_type": "Technical_1",
    "comments": "Good",
    "total_score": 5.0,
    "candidate_accept_reject": 1,
    "interview_action": null,
    "requisition": {
      "id": 332,
      "required_experience": 3.0,
      "min_salary": 2,
      "max_salary": 5
    },
    "interview_datetime": "2024-07-25T01:21:00+05:30",
    "google_meet_link": "https://meet.google.com/oxh-zkbx-sxh",
    "interview_feedback": [
      {
        "interviewer": {
          "id": 3,
          "first_name": "Aaradhya",
          "last_name": "Jaiswal"
        },
        "total_score": 4.0,
        "comments": "Comment",
        "candidate_accept_reject": 1,
        "categories_data": [
          {
            "id": 255,
            "category": "Coding",
            "score": 3.0,
            "comments": null
          },
          {
            "id": 256,
            "category": "Aptitude",
            "score": 0.0,
            "comments": null
          }
        ],
        "questions_data": [
          {
            "id": 1065,
            "question": "How do you keep up with the latest developments and trends in software development?",
            "answer": null,
            "question_status": null,
            "score": 5.0,
            "comments": "Good"
          },
          {
            "id": 1065,
            "question": "How do you keep up with the latest developments and trends in software development?",
            "answer": null,
            "question_status": null,
            "score": 5.0,
            "comments": "Good"
          }
        ],
      },
      {
        "interviewer": {
          "id": 697,
          "first_name": "Aaradhya",
          "last_name": "Jaiswal"
        },
        "total_score": 4.0,
        "comments": "Comments",
        "candidate_accept_reject": 2,
        "categories_data": [
          {
            "id": 255,
            "category": "Coding",
            "score": 5.0,
            "comments": null
          },
          {
            "id": 256,
            "category": "Aptitude",
            "score": 0.0,
            "comments": null
          }
        ],
        "questions_data": [
          {
            "id": 1065,
            "question": "How do you keep up with the latest developments and trends in software development?",
            "answer": null,
            "question_status": null,
            "score": 5.0,
            "comments": "Good"
          },
          {
            "id": 1065,
            "question": "How do you keep up with the latest developments and trends in software development?",
            "answer": null,
            "question_status": null,
            "score": 5.0,
            "comments": "Good"
          }
        ],
      },
    ]
  }
}

export const requisitionByUserData = [
  {
    "id": 435,
    "job_opening": {
      "id": 328,
      "job_title": "Exec. Paid Media Specialist",
      "department": {
        "id": 6,
        "department_name": "Digital Marketing Dept.",
        "department_display_name": "Digital Marketing Dept.",
        "department_incharge": null
      },
      "team": {
        "id": 9,
        "team_name": "Social Media Team_DM",
        "team_display_name": "Social Media Team_DM",
        "team_incharge": null
      },
      "position": {
        "id": 236,
        "position_name": "Paid Media Specialist",
        "position_display_name": "Paid Media Specialist"
      },
      "seniority": {
        "id": 3,
        "seniority_name": "Exec.",
        "seniority_display_name": "Exec.",
        "sequence": 2
      },
      "job_description": "<p><span style=\"color: rgb(4, 12, 40);\">Defining, measuring, and evaluating relevant paid media KPIs</span><span style=\"color: rgb(32, 33, 36);\">. Building out media buys for various ad platforms and overseeing the day-to-day execution of paid media. Overseeing and managing the overall budget for paid media. Conducting in-depth keyword and website research, ad grouping and audience targeting.</span></p>",
      "job_requirement": "<p><span style=\"color: rgb(4, 12, 40);\">Defining, measuring, and evaluating relevant paid media KPIs</span><span style=\"color: rgb(32, 33, 36);\">. Building out media buys for various ad platforms and overseeing the day-to-day execution of paid media. Overseeing and managing the overall budget for paid media. Conducting in-depth keyword and website research, ad grouping and audience targeting.</span></p>",
      "workflow": {
        "id": 69,
        "workflow_name": "Workflow - 3",
        "workflow_category": "Candidate"
      },
      "experience": 5.0,
      "location": "Kolkata",
      "vacancy": null,
      "seo_title": "Exec. Paid Media Specialist",
      "seo_keywords": "Exec. Paid Media Specialist",
      "seo_meta_key": "Exec. Paid Media Specialist",
      "url_required": "Exec. Paid Media Specialist",
      "activity_status": 0
    },
    "created_at": "2024-08-10T16:28:08.659540+05:30",
    "no_of_vacancy": 1,
    "job_type": "Part-time",
    "job_location": "Pune",
    "required_experience": 1.0,
    "min_salary": 2,
    "max_salary": 3,
    "deadline": null,
    "status": 0,
    "priority": "High",
    "comments": "Comments",
    "created_since": 1,
    "hrd_approved": 1,
    "md_approved": 2,
    "total_resume": 4,
    "candidate_in_process": { "received": 1, "processed": 3 },
    "shortlisted_candidate": { "received": 2, "processed": 3 },
    "sme_shortlisted_candidate": { "received": 3, "processed": 2 },
    "telephonic_candidates": { "received": 3, "processed": 1 },
    "technical_candidates": { "received": 4, "processed": 1 },
    "hr_candidates": { "received": 2, "processed": 0 },
    "assignment_candidates": { "received": 5, "processed": 0 },
    "test_candidates": { "received": 6, "processed": 0 },
    "shf_candidates": { "received": 7, "processed": 1 },
    "bgv_candidates": { "received": 8, "processed": 0 }
  }
]

export const tamInterviewSlots = [
  {
    "interviewer": {
      "id": 1,
      "first_name": "Test",
      "last_name": "User",
      "slots": [
        {
          "id": 21,
          "requisition": {
            "id": 47,
            "job_opening": {
              "id": 586,
              "job_title": "Sr. Operations Manager_CMD"
            }
          },
          "slot_duration": 1.0,
          "slot_start_time": "2024-08-27T01:00:00+05:30",
          "slot_end_time": "2024-08-27T02:00:00+05:30",
          "is_booked": false,
          "slot_accepted_by": {
            "id": 697,
            "first_name": "Aaradhya",
            "last_name": "Jaiswal"
          }
        },
        {
          "id": 22,
          "requisition": {
            "id": 47,
            "job_opening": {
              "id": 586,
              "job_title": "Sr. Operations Manager_CMD"
            }
          },
          "slot_duration": 1.0,
          "slot_start_time": "2024-08-07T01:00:00+05:30",
          "slot_end_time": "2024-08-07T02:00:00+05:30",
          "is_booked": false,
          "slot_accepted_by": null
        }
      ],
    }
  }
]