import axios from "axios";
import React, { useState,useContext } from "react";
import { createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const ResumeDownloadContext = createContext(null);

function ResumeDownloadProvider({ children }) {
  const {RefreshToken} = useContext(RefreshTokenContext)

  const [allResume, setAllResume] = useState([]);
  const [isResumeData, setIsResumeData] = useState(false);
  const [resumeEntries, setResumeEntries] = useState(5)
  const [resumeCount, setResumeCount] = useState(null)
  const [resumePageCount, setResumePageCount] = useState(0)
  const [isResumeLoading, setIsResumeLoading] = useState(false);

  const getResumes = async (access) => {
    setIsResumeLoading(true);
    const url = VARIABLES.url + "/api/resumezip/"

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Authorization" : `Bearer ${access}`
       }
    };
    
    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsResumeLoading(false);
      if (response.length !== 0) {
        setIsResumeData(false);
        setAllResume(res.data.data);
      } else {
        setIsResumeData(true);
        setAllResume(res.data.data);
      }
      const total = res.data.count;
      setResumeCount(total);
      setResumePageCount(Math.ceil(total / resumeEntries));
    } catch (error) {
      console.log(error);
      setIsResumeLoading(false);
      if(error?.response?.status === 403) {
        RefreshToken()
        setIsResumeData(false);
      }else {
        setIsResumeData(true);
      }
    }
  };

  const handleResumeClick = async (data) => {
    const access = secureLocalStorage("access")
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getResumes(
      access,
      currentPage
    );
  };

  return (
    <div>
      <ResumeDownloadContext.Provider
        value={{
          allResume,
          getResumes,
          resumeEntries,
          resumePageCount,
          setResumeEntries,
          resumeCount,
          handleResumeClick,
          isResumeData,
          isResumeLoading,
        }}
      >
        {children}
      </ResumeDownloadContext.Provider>
    </div>
  );
}

export default ResumeDownloadProvider;
