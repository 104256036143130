import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { WorkflowContext } from "../../Context/WorkflowContext";
import JobWorkflowEdit from "./JobWorkflowEdit";

const JobWorkflowTable = (props) => {
  const { isAccess } = useContext(RefreshTokenContext);
  const [searchWorkflow, setSearchWorkflow] = useState("");
  const privileges = secureLocalStorage.getItem("privileges");
  const [editedData, setEditedData] = useState(null);
  console.log(editedData);

  const {
    rawWorkflowData,
    getWorkflowData,
    workflowEntries,
    workflowPageCount,
    setWorkflowEntries,
    workflowCount,
    handleWorkflowClick,
    isWorkflowData,
  } = useContext(WorkflowContext);

  const handleEntries = (event) => {
    setWorkflowEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchWorkflow(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getWorkflowData(1, searchWorkflow, props.categoryName);
    } else {
      getWorkflowData(1, "", props.categoryName);
      setSearchWorkflow("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getWorkflowData(1, searchWorkflow, props.categoryName, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getWorkflowData(1, searchWorkflow, props.categoryName, access);
    }
  }, [workflowEntries, props.categoryName, isAccess]);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchWorkflow}
                      className="form-control"
                      placeholder="Search by workflow name"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Workflow name</span>
                      </div>

                      {privileges.includes(
                        "workflow_tabs_configurable_retrieve"
                      ) ? (
                        <div className="nk-tb-col">
                          <span>Configure Workflow</span>
                        </div>
                      ) : (
                        " "
                      )}
                      {privileges.includes("workflowmanagement_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit Workflow</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawWorkflowData.length !== 0 ? (
                      rawWorkflowData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.workflow_name || "-"}
                              </span>
                            </span>
                          </div>

                          {privileges.includes(
                            "workflow_tabs_configurable_retrieve"
                          ) ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/workflow"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-configure_workflow"
                              >
                                <em className="icon ni ni-plus"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {privileges.includes("workflowmanagement_update") ? (
                            <div className="nk-tb-col">
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#edit_workflow"
                                className="btn btn-sm btn-primary"
                                id="button-button-edit_configure_Workflow"
                                onClick={() => setEditedData(post)}
                              >
                                <em className="icon ni ni-edit"></em>
                              </button>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isWorkflowData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={workflowPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={workflowPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleWorkflowClick(
                        data,
                        searchWorkflow,
                        props.categoryName
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {workflowCount === null ? 0 : workflowCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={workflowEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JobWorkflowEdit
        editedData={editedData}
        categoryName={props.categoryName}
      />
    </>
  );
};

export default JobWorkflowTable;
